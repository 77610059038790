import React from 'react'
import "../../../styles/tabs/activation/activation.css"
import "../../../styles/tabs/activation/by_optional.css"
import PropTypes from "prop-types";
import ActivationSceneByOptional from "../../panels/activation/by_optional";
import AddButton from "../../buttons/add";
import NotAvailablePanel from "../../panels/not_available";

const Availability = false;

export default class ActivationByOptionalTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            add: false,
        }
    }

    scenesList = () => {
        return (
            <div className="vl_activation_container">
                {
                    [
                        {id: 1, name: "Scene 1", trigger: "Trigger manually"},
                        {id: 2, name: "Scene 2", trigger: "Trigger manually"},
                    ].map(scene => <ActivationSceneByOptional key={scene.id} Scene={scene} />)
                }
            </div>
        )
    }

    render() {
        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_activation_scenes_tab_by_optional"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                {
                    Availability ?
                        <>
                            <div style={{position: "relative", width: "100%", height: "6.5vh"}}>
                                <AddButton Text={"Add New"}
                                           VlStyle={{width: "150px", position: "absolute", right: "2.8vw", top: "1vh"}}
                                           Callback={() => {
                                               this.setState({...this.state, add: true})
                                           }}/>
                            </div>
                            {this.scenesList()}
                        </> : <NotAvailablePanel />
                }
            </div>
        )
    }
}

ActivationByOptionalTab.propTypes = {
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
