import React from 'react'
import PropTypes from "prop-types";
import CanvasJSReact from "../../../lib/canvasjs.react";
let CanvasJSChart = CanvasJSReact.CanvasJSChart;


export default class Histogram extends React.Component {

    colorFromNumber = num => {

        if (num < 10) return "#abbcea";
        if (num < 20) return "#7ba4dd";
        return "#4c74c9";
    }

    canvasOptions = () => {

        return {
            theme: "light2",
            colorSet: "greenShades",
            title: {
                text: this.props.Title,
            },
            animationEnabled: true,
            axisY: {
                title: "Hour",
                suffix: " h"
            },
            data: [
                {
                    type: "column",
                    dataPoints: [
                        { label: "0%", y: this.props.Data.h_0, color: this.colorFromNumber(this.props.Data.h_0) },
                        { label: "0.1-1%", y: this.props.Data.h_0110, color: this.colorFromNumber(this.props.Data.h_0110) },
                        { label: "1-10%", y: this.props.Data.h_1010, color: this.colorFromNumber(this.props.Data.h_1010) },
                        { label: "10-20%", y: this.props.Data.h_1020, color: this.colorFromNumber(this.props.Data.h_1020) },
                        { label: "20-30%", y: this.props.Data.h_2030, color: this.colorFromNumber(this.props.Data.h_2030) },
                        { label: "30-40%", y: this.props.Data.h_3040, color: this.colorFromNumber(this.props.Data.h_3040) },
                        { label: "40-50%", y: this.props.Data.h_4050, color: this.colorFromNumber(this.props.Data.h_4050) },
                        { label: "50-60%", y: this.props.Data.h_5060, color: this.colorFromNumber(this.props.Data.h_5060) },
                        { label: "60-70%", y: this.props.Data.h_6070, color: this.colorFromNumber(this.props.Data.h_6070) },
                        { label: "70-80%", y: this.props.Data.h_7080, color: this.colorFromNumber(this.props.Data.h_7080) },
                        { label: "80-90%", y: this.props.Data.h_8090, color: this.colorFromNumber(this.props.Data.h_8090) },
                        { label: "90-100%", y: this.props.Data.h_90100, color: this.colorFromNumber(this.props.Data.h_90100) },
                    ]
                }
            ]
        }
    }

    render() {
        return (
            <CanvasJSChart options = {this.canvasOptions()}
                           onRef={ref => this.chart = ref}
                           containerProps={{
                               width: this.props.VlWidth ? this.props.VlWidth : "100%",
                               height: this.props.VlWidth ? this.props.VlHeight : "90%",
                           }}
            />
        );
    }
}

Histogram.propTypes = {
    Data: PropTypes.object.isRequired,
    Title: PropTypes.string,
    VlWidth: PropTypes.string,
    VlHeight: PropTypes.string,
}
