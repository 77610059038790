import {dayMonthYearFormatted, formatHoursAndMinutes} from "./time";
import React from "react";

export const Weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const WeekdaysMap = {"Sun": 0, "Mon": 1, "Tue": 2, "Wed": 3, "Thu": 4, "Fri": 5, "Sat": 6};

export function timeFromActivation(activation) {

    return activation.timing.day_times.length > 0 ?
        formatHoursAndMinutes(
            activation.timing.day_times[0].hour,
            activation.timing.day_times[0].minute
        ) : "";
}

export function localTimeFromActivation(activation) {

    let date = new Date();
    return activation.timing.day_times.length > 0 ?
        formatHoursAndMinutes(
            (activation.timing.day_times[0].hour - date.getTimezoneOffset() / 60) % 24,
            activation.timing.day_times[0].minute
        ) : "";
}

export function timesFromActivation(activation, count) {

    let result = activation.timing.day_times.map(time => {
        return formatHoursAndMinutes(time.hour, time.minute);
    })
    return (count > 0 && result.length < count) ? result.slice(0, count) : result;
}

export function activationWeekdaysToArray(weekdays) {

    return weekdays.map(weekday => Weekdays[weekday]);
}

export function activationContainsWeekday(activation, weekday) {

    const found = activation.timing.weekdays.find(w => w === WeekdaysMap[weekday]);
    return found !== null && found !== undefined
}

export function activationWithSortedData(activation) {

    activation.timing.weekdays.sort();
    return activation;
}

export function triggerWeekdayInActivation(activation, weekday) {

    if (activationContainsWeekday(activation, weekday)) {
        activation.timing.weekdays = activation.timing.weekdays.filter(w => w !== WeekdaysMap[weekday]);
    } else {
        activation.timing.weekdays.push(WeekdaysMap[weekday]);
    }
    return activation;
}

export function setSingleShotsOfActivation(activation, single_shots) {

    activation.timing.single_shots = single_shots.map(shot => {
        return {year: shot.year, month: shot.month.number, day: shot.day, day_times: activation.timing.day_times};
    });
    return activation;
}

export function changeDayTimeInActivation(activation, index, hour, minute) {

    if (activation.timing.day_times.length <= index) return activation;
    activation.timing.day_times[index].hour = parseInt(hour);
    activation.timing.day_times[index].minute = parseInt(minute);
    return activation;
}

export function singleShotsListFromActivation(activation) {

    return (
        <>
            {
                activation.timing.single_shots.map((shot, index) => {
                    return <p key={index}>{dayMonthYearFormatted(shot.day, shot.month, shot.year)}</p>
                })
            }
            {
                activation.timing.single_shots.length === 0 ? <h5>List of dates</h5> : ""
            }
        </>
    )
}

export function singleShotsAsDates(activation) {

    return activation.timing.single_shots.map((shot) => {
        return new Date(shot.year, shot.month - 1, shot.day);
    });
}