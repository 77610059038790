import './styles/app.css';
import Main from "./ui/pages/main"
import Login from "./ui/pages/login"
import TwoFA from "./ui/pages/twoFA"
import Users from "./ui/pages/users"
import Monitoring from "./ui/pages/monitoring"
import Reports from "./ui/pages/reports"
import Profile from "./ui/pages/profile"
import { BrowserRouter as Router, Route} from "react-router-dom";
import PrivateRoute from "./private_route";
import 'bootstrap/dist/css/bootstrap.min.css'
import React from "react";
import {connect} from "react-redux";
import {getDevices} from "./actions/device"
import {getHardwareGroups} from "./actions/hardware";
import {getScenes} from "./actions/scenes";
import {getHistogramMeta, getReports} from "./actions/reports";
import {getPlaces} from "./actions/places";
import {setIntensityProgress, setDeviceIntensityProgress, setSceneProgress} from "./actions/progress";
import {getUnreadNotifications} from "./actions/notifications";
import {addLog} from "./actions/logs";
import {createWebsocketConnection} from "./utils/live";
import {statusChanged} from "./actions/status";

class App extends React.Component {

    componentDidMount() {

        this.setupLiveConnection();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        this.setupLiveConnection();
    }

    setupLiveConnection = () => {

        createWebsocketConnection(
            this.props.authenticated,
            this.props.setSceneProgress,
            this.props.setIntensityProgress,
            this.props.setDeviceIntensityProgress,
            this.props.addLog,
            this.props.getUnreadNotifications,
            this.props.getGroups,
            this.props.getDevices,
            this.props.getPlaces,
            this.props.getScenes,
            this.props.getReports,
            this.props.getHistogramMeta,
            this.props.statusChanged
        );
    }

    render() {

        return (
            <Router>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/login/2fa" component={TwoFA}/>
                <PrivateRoute exact path="/" title="Map" component={Main}/>
                <PrivateRoute exact path="/users" title="Users" component={Users}/>
                <PrivateRoute exact path="/monitoring" title="Monitoring" component={Monitoring}/>
                <PrivateRoute exact path="/reports" title="Reports" component={Reports}/>
                <PrivateRoute exact path="/profile" title="Profile" component={Profile}/>
            </Router>
        );
    }
}

const mapStateToOverviewProps = state => ({
    authenticated: state.auth.authenticated,
})

export default connect(mapStateToOverviewProps, {
    getDevices,
    getGroups: getHardwareGroups,
    getScenes,
    getReports,
    getHistogramMeta,
    getPlaces,
    setIntensityProgress,
    setDeviceIntensityProgress,
    setSceneProgress,
    getUnreadNotifications,
    addLog,
    statusChanged
})(App);
