import React from "react";
import Sensor from "../panels/devices/sensor";
import "../../styles/lists/sensors_list.css"
import PropTypes from "prop-types";

export default class SensorsList extends React.Component {

    componentFromSensor = sensor => {

        switch (this.props.Type) {

            case "Select":
                return (
                    <Sensor key={sensor.id}
                            CheckCallback={() => this.props.Select(sensor.id)}
                            Checked={sensor.selected}
                            Sensor={sensor} />
                )
            case "Info":
                return (
                    <Sensor key={sensor.id}
                            Sensor={sensor}
                            DeleteCallback={this.props.DeleteCallback} />
                )
            default:
                return (
                    <Sensor key={sensor.id}
                            DeleteCallback={this.props.DeleteCallback}
                            UpdateSensor={this.props.UpdateSensor}
                            EditCallback={this.props.EditSensor}
                            Sensor={sensor} />
                )

        }
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_sensors_list"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}
                 disabled={this.props.Disabled}
            >
                {
                    this.props.Sensors.map(sensor => this.componentFromSensor(sensor))
                }
            </div>
        )
    }
}

SensorsList.propTypes = {
    Sensors: PropTypes.array.isRequired,
    Disabled: PropTypes.bool,
    Type: PropTypes.string,

    DeleteCallback: PropTypes.func,
    UpdateSensor: PropTypes.func,
    EditSensor: PropTypes.func,
    Select: PropTypes.func,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
