import {
    GET_ACTIVATION_BY_DATE,
    CREATE_ACTIVATION_BY_DATE,
    UPDATE_ACTIVATION_BY_DATE,
    REMOVE_ACTIVATION_BY_DATE,
    SIGN_OUT, SET_ACTIVE_ACTIVATION_BY_DATE,
} from "./types";

export const getActivationsByDate = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/activation/by_date/data",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                response.json().then(activations => {

                    dispatch({
                        type: GET_ACTIVATION_BY_DATE,
                        activations: activations
                    })
                })
            } else if (response.status === 401) {
                dispatch({type: SIGN_OUT})
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const createActivationByDate = (activation) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/activation/by_date/create",
        {method: 'POST', credentials: 'include', mode: 'cors', body: JSON.stringify(activation)})
        .then(response => {

            if (response.status === 200) {
                response.json().then(receivedActivation => {

                    dispatch({
                        type: CREATE_ACTIVATION_BY_DATE,
                        activation: receivedActivation,
                    })
                })
            } else if (response.status === 401) {
                dispatch({type: SIGN_OUT})
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
};


export const updateActivationByDate = (activation) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/activation/by_date/update",
        {method: 'PUT', credentials: 'include', mode: 'cors', body: JSON.stringify(activation)})
        .then(response => {

            if (response.status === 200) {
                dispatch({
                    type: UPDATE_ACTIVATION_BY_DATE,
                    activation: activation,
                })
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const setActiveActivationByDate = (id, active) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/activation/by_date/set_activated?id=" + id + "&active=" + (0 + active),
        {method: 'PUT', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                dispatch({
                    type: SET_ACTIVE_ACTIVATION_BY_DATE,
                    id: id,
                    active: active
                })
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const removeActivationByDate = (activation_id) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/activation/by_date/remove?id=" + activation_id,
        {method: 'DELETE', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                dispatch({
                    type: REMOVE_ACTIVATION_BY_DATE,
                    activation_id: activation_id,
                })
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
};