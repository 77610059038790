import React from 'react'
import "../../styles/general/tabs.css"
import PropTypes from "prop-types";

export default class Tabs extends React.Component {

    buttonStyle = () => {

        switch (this.props.Style) {
            case "set":
                return "vl_tab_set_button"
            default:
                return "vl_tab_button"
        }
    }

    tabFromTitle = (title, index) => {

        if (this.props.Selected === title) {
            return <button key={index}
                           className={this.props.VlSelectedButtonClass ? this.props.VlSelectedButtonClass : this.buttonStyle() + "_selected"}
                           style={this.props.VlSelectedButtonStyle ? this.props.VlSelectedButtonStyle : {}}
                           onClick={() => this.props.Callback(title)}>
                               {title}
            </button>
        }
        return <button key={index}
                       className={this.props.VlButtonClass ? this.props.VlButtonClass : this.buttonStyle()}
                       style={this.props.VlButtonStyle ? this.props.VlButtonStyle : {}}
                       onClick={() => this.props.Callback(title)}>
            {title}
        </button>
    }

    render() {
        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_tabs"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                {
                    this.props.Titles.map((title, index) => this.tabFromTitle(title, index))
                }
                {
                    !this.props.Style || (this.props.Style === "underline") ?
                    <div className="vl_tabs_underline"/> : ""
                }
            </div>
        )}
}

Tabs.propTypes = {
    Titles: PropTypes.array.isRequired,
    Callback: PropTypes.func.isRequired,
    Selected: PropTypes.string,
    Style: PropTypes.string,

    VlButtonClass: PropTypes.string,
    VlButtonStyle: PropTypes.object,
    VlSelectedButtonClass: PropTypes.string,
    VlSelectedButtonStyle: PropTypes.object,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
