import {GET_LAST_NOTIFICATIONS, GET_UNREAD_NOTIFICATIONS, MARK_AS_SEEN, SIGN_OUT} from "./types";

export const getUnreadNotifications = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/notifications/unread",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                response.json().then(notifications => {

                    dispatch({
                        type: GET_UNREAD_NOTIFICATIONS,
                        notifications: notifications
                    })
                })
            } else if (response.status === 401) {
                dispatch({type: SIGN_OUT})
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const getLastNotifications = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/notifications/last",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                response.json().then(notifications => {

                    dispatch({
                        type: GET_LAST_NOTIFICATIONS,
                        notifications: notifications
                    })
                })
            } else if (response.status === 401) {
                dispatch({type: SIGN_OUT})
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const markAsSeen = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/notifications/mark_as_seen",
        {method: 'POST', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {

                dispatch({
                    type: MARK_AS_SEEN,
                })
            } else if (response.status === 401) {

                dispatch({type: SIGN_OUT})
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
}
