import React from 'react'
import "../../styles/dialogs/dialog.css"
import "../../styles/dialogs/user.css"
import PropTypes from "prop-types";
import VlButton from "../buttons/vl_button";

export default class UserDialog extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            user: this.props.User || {
                Name: "",
                Password: "",
                Status: "user"
            },
            isEdit: !!this.props.User,
            error: null
        }
    }

    text = () => {

        if (this.props.User)
            return <p className="vl_user_text">Editing user</p>
        return <p className="vl_user_text">Adding new user</p>
    }

    changeName = name => {

        this.setState({
            ...this.state,
            user: {...this.state.user, Name: name},
            error: null
        })
    }

    changePassword = password => {

        this.setState({
            ...this.state,
            user: {...this.state.user, Password: password},
            error: null
        })
    }

    changeStatus = status => {

        this.setState({
            ...this.state,
            user: {...this.state.user, Status: status},
            error: null
        })
    }

    changeError = err => {

        this.setState({...this.state, error: err})
    }

    submitUser = user => {

        if (user !== null) {
            if (this.state.isEdit && (!user.Name || user.Name.length === 0)) {
                this.changeError("Username can't be empty")
            } else if (!this.state.isEdit && (!user.Name || user.Name.length === 0 || !user.Password || user.Password.length === 0)) {
                this.changeError("All fields should be filled")
            } else {
                this.props.Callback(user);
            }

        } else {
            this.props.Callback(null);
        }
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_dialog"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_user_dialog">
                    { this.text() }
                    <input className="vl_user_dialog_nickname"
                           type="text"
                           placeholder="username"
                           defaultValue={this.state.user.Name}
                           onChange={e => this.changeName(e.target.value)}
                    />
                    <input className="vl_user_dialog_password"
                           type="password"
                           placeholder="password"
                           defaultValue={this.state.user.Password}
                           onChange={e => this.changePassword(e.target.value)}
                    />
                    <div className="vl_user_dialog_status">
                        <input className="vl_user_dialog_status_user"
                               type="radio"
                               checked={this.state.user.Status !== "admin"}
                               onChange={() => {this.changeStatus("user")}}
                               name="gender"
                        />
                        <span className="vl_user_dialog_status_user_text">User</span>
                        <input className="vl_user_dialog_status_admin"
                               type="radio"
                               checked={this.state.user.Status === "admin"}
                               onChange={() => {this.changeStatus("admin")}}
                               name="gender"
                        />
                        <span className="vl_user_dialog_status_admin_text">Admin</span>
                    </div>
                    <VlButton Text="Save"
                              VlClass="vl_user_dialog_save"
                              Callback={() => this.submitUser(this.state.user)}
                    />
                    <VlButton Text="Cancel"
                              Style="negative"
                              VlClass="vl_user_dialog_cancel"
                              Callback={() => this.submitUser(null)}
                    />
                    {this.state.error ? <p className="vl_user_dialog_error">{this.state.error}</p> : ""}
                </div>
            </div>
        )
    }
}

UserDialog.propTypes = {
    Callback: PropTypes.func.isRequired,
    User: PropTypes.object,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
