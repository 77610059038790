import {
    SIGN_IN,
    SIGN_OUT,
    SEND_EMAIL, VERIFY_CODE, SIGNING_IN, CLEAN_ERROR
} from '../actions/types'

const initialState = {
    authenticating: false,
    authenticated: localStorage.getItem('authenticated') === 'true',
    verified: localStorage.getItem('verified') === 'true',
    username: '',
    password: '',
    error: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {

    let error = null
    switch(action.type) {

        case SIGNING_IN:
            return {...state, authenticating: true}
        case SIGN_IN:
            let success = action.status === 200
            if (success)
                localStorage.setItem('authenticated', 'true')
            if (action.verified)
                localStorage.setItem('verified', 'true')

            if (action.status === 401)
                error = "Wrong username or password"
            else if (action.status !== 200)
                error = "Failed to login"

            return {
                ...state,
                authenticating: false,
                authenticated: success,
                username: action.username,
                password: action.password,
                verified: action.verified,
                error: error }

        case SIGN_OUT:
            localStorage.setItem('authenticated', 'false')
            localStorage.setItem('verified', 'false')
            return { ...state, authenticated: false, verified: false, error: null }

        case CLEAN_ERROR:
            return { ...state, error: null }

        case VERIFY_CODE:
            if (action.status === 200) {
                localStorage.setItem('verified', 'true')
                return { ...state, verified: true, authenticating: false }
            }
            else if(action.status === 409)
                return { ...state, error: "Wrong code" }
            else
                return { ...state, error: "Failed to verify" }

        case SEND_EMAIL:
            let sendEmailError = null

            if (action.status !== 200)
                error = "Failed to send email"

            return {...state, error: sendEmailError}

        default:
            return state;
    }
}
