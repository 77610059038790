import React from 'react'
import "../../../styles/panels/devices/area.css"
import ArrowButton from "../../buttons/arrow"
import PropTypes from "prop-types";
import CheckBox from "../../boxes/checkbox";
import {isArray} from "../../../../utils/arrays";
import GroupsList from "../../lists/groups_list";
import AddButton from "../../buttons/add";
import DevicesList from "../../lists/devices_list";
import NameEditBox from "../../boxes/nameeditbox";
import SensorsList from "../../lists/sensors_list";

export default class Area extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            opened : false,
            name_edit: false,
            name_edit_error: "",
        }
    }

    isSelection = () => {

        return this.props.Check !== undefined && this.props.Checked !== undefined;
    }

    checkMark = () => {

        if (!this.isSelection()) return "";
        return <CheckBox VlStyle={{marginTop: "20px", marginLeft: "30px"}}
                         Callback={this.props.Check}
                         Checked={this.props.Checked} />
    }

    arrowButton = () => {

        return (
            <ArrowButton VlStyle={{position: "absolute", top: "1vh", right: "180px"}}
                         Status={this.state.opened}
                         Callback={() => {
                this.setState({...this.state, opened : !this.state.opened})
            }}/>
        )
    }

    content = () => {

        if ((isArray(this.props.UnusedDevices) || isArray(this.props.UnusedSensors)) && this.state.opened) {
            return <>
                {this.unusedDevices(this.props.UnusedDevices)}
                {this.unusedSensors(this.props.UnusedSensors)}
            </>
        }
        return this.groups();
    }

    unusedDevices = (devices) => {

        if (!isArray(this.props.UnusedDevices)) return "";
        return <DevicesList Type="Info"
                            Devices={this.props.UnusedDevices} 
                            DeleteCallback={this.props.DeleteCallback}/>
    }

    unusedSensors = (sensors) => {

        if (!isArray(this.props.UnusedSensors)) return "";
        return <SensorsList Type="Info"
                            Sensors={this.props.UnusedSensors}
                            DeleteCallback={this.props.DeleteCallback}/>
    }

    groups = () => {

        if (!isArray(this.props.Area.groups) || !this.state.opened) return "";
        if (this.isSelection()) {
            return <GroupsList Select={this.props.CheckGroup}
                               Groups={this.props.Area.groups}
                               ChangeIntensity={(intensity, group_id, device_id) => {
                                   if (this.props.ChangeIntensity)
                                       this.props.ChangeIntensity(intensity, this.props.Area.id, group_id, device_id)
                               }}
                               Disabled={!this.props.Checked}/>;
        }
        return (
            <div>
                <div className="vl_area_add_group_container" style={{display: this.props.AddGroup ? "box" : "none"}}>
                    <AddButton Text="New Group"
                               VlClass="vl_area_add_group_button"
                               Callback={() => this.props.AddGroup(this.props.Area)}
                    />
                </div>
                <GroupsList Groups={this.props.Area.groups}
                            AreaId={this.props.Area.id}
                            UnusedSensors={this.props.UnusedSensors}
                            UnusedDevices={this.props.Area.devices.filter(device => device.group_id === -1)}
                            SelectedDevice={this.props.SelectedDevice}
                            GroupsIntensityProgress={this.props.GroupsIntensityProgress}
                            DevicesIntensityProgress={this.props.DevicesIntensityProgress}
                            ChangeIntensity={(intensity, group_id, device_id) => {
                                if (this.props.ChangeIntensity)
                                    this.props.ChangeIntensity(intensity, this.props.Area.id, group_id, device_id)
                            }}
                            DeleteCallback={(entry, isDevice) => {
                                this.props.DeleteCallback(entry, isDevice, isDevice ? undefined : this.props.Area.id);
                            }}
                            EditCallback={group => this.props.EditGroup(this.props.Area, group)}
                            EditDeviceCallback={this.props.EditDevice}
                            UpdateCallback={this.props.UpdateGroup}
                            UpdateDevice={this.props.UpdateDevice}
                />
            </div>
        )
    }

    anyOfGroupsHaveActiveDevice = () => {

        return this.props.Area.groups.filter(group => {
            return group.devices.filter(device => device.id === this.props.SelectedDevice.id).length > 0;
        }).length > 0;
    }

    hasActiveDevice = () => {

        return this.props.SelectedDevice &&
            this.anyOfGroupsHaveActiveDevice();
    }

    changeName = newName => {

        let error = this.props.UpdateAreaName(this.props.Area, newName);
        this.setState({
            ...this.state,
            name_edit_error: error,
            name_edit: error && error.length > 0
        });
    }

    areaName = () => {

        if (this.state.name_edit)
            return <NameEditBox VlClass="vl_area_name_edit"
                                VlEditClass="vl_area_name_edit_input"
                                Error={this.state.name_edit_error}
                                Change={this.changeName}
                                Cancel={() => {this.setState({...this.state, name_edit: false, name_edit_error: ""})}}
                                Default={this.props.Area.name}/>
        return <h5 className="vl_area_name" onDoubleClick={() => {this.setState({...this.state, name_edit: true})}}>{this.props.Area.name}</h5>
    }

    areaPlace = () => {

        if (!this.props.Area.place) return <></>
        return <h5 className="vl_area_place">{this.props.Area.place}</h5>
    }

    render() {

        let areaClass = "vl_area";
        if (this.hasActiveDevice() && !this.state.opened) { areaClass += " vl_area_active"; }
        return (
            <div className={this.props.VlClass ? this.props.VlClass : areaClass}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_area_container">
                    {this.checkMark()}
                    {this.areaName()}
                    {this.arrowButton()}
                    {this.areaPlace()}
                </div>
                {this.content()}
            </div>
        )
    }
}

Area.propTypes = {
    Area: PropTypes.object.isRequired,
    UnusedDevices: PropTypes.array,
    UnusedSensors: PropTypes.array,
    SelectedDevice: PropTypes.object,
    GroupsIntensityProgress: PropTypes.array,
    DevicesIntensityProgress: PropTypes.array,

    ChangeIntensity: PropTypes.func,
    DeleteCallback: PropTypes.func,
    Check: PropTypes.func,
    UpdateAreaName: PropTypes.func,
    AddGroup: PropTypes.func,
    EditGroup: PropTypes.func,
    EditDevice: PropTypes.func,
    UpdateGroup: PropTypes.func,
    UpdateDevice: PropTypes.func,
    CheckGroup: PropTypes.func,
    Checked: PropTypes.bool,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}