import {
    DEVICE_INTENSITY_CHANGING,
    GROUP_INTENSITY_CHANGING,
    SCENE_CHANGING
} from "../actions/types";

const initialState = {
    groupsIntensityChanging: [],
    devicesIntensityChanging: [],
    sceneChanging: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {

    switch(action.type) {

        case GROUP_INTENSITY_CHANGING:
            let group = state.groupsIntensityChanging.find(g =>
                action.Group.area_id === g.area_id &&
                action.Group.group_id === g.group_id);
            if (group) {
                if (group.count === 1 && action.End) {
                    return {
                        ...state,
                        groupsIntensityChanging: state.groupsIntensityChanging.filter(g =>
                            action.Group.area_id !== g.area_id &&
                            action.Group.group_id !== g.group_id)
                    }
                }
                return {
                    ...state,
                    groupsIntensityChanging: state.groupsIntensityChanging.map(g => {
                        if (action.Group.area_id === g.area_id && action.Group.group_id === g.group_id) {
                            return {...g, count: g.count + (action.End ? -1 : 1)};
                        }
                        return g;
                    })
                }
            }
            return { ...state, groupsIntensityChanging: [...state.groupsIntensityChanging, {...action.Group, count: 1}] }

        case DEVICE_INTENSITY_CHANGING:
            let device = state.devicesIntensityChanging.find(id => action.DeviceId === id);
            if (device) {
                return {
                    ...state,
                    devicesIntensityChanging: state.devicesIntensityChanging.filter(id => action.DeviceId !== id)
                }
            }
            return { ...state, devicesIntensityChanging: [...state.devicesIntensityChanging, action.DeviceId] }

        case SCENE_CHANGING:
            return { ...state, sceneChanging: action.Status }

        default:
            return state;
    }
}
