import React from "react";
import VlButton from "../../buttons/vl_button";
import PropTypes from "prop-types";
import "../../../styles/panels/devices/deleted_devices.css"
import DevicesList from "../../lists/devices_list";
import SensorsList from "../../lists/sensors_list";

export default class DeletedDevices extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            devices: this.props.DeletedDevices.map(device => {
                return {...device, selected: false}
            }),
            sensors: this.props.DeletedSensors.map(sensor => {
                return {...sensor, selected: false}
            }),
            error: null,
        }
    }

    title = () => {

        return "Restore Deleted";
    }

    error = () => {

        return (
            <p className="vl_deleted_devices_select_error">
                {this.state.error?.Text || ""}
            </p>
        );
    }

    triggerDevice = id => {

        this.setState({
            ...this.state,
            devices: this.state.devices.map(device => {
                if (device.id === id) {
                    return {...device, selected: !device.selected};
                }
                return device;
            }),
            error: null,
        });
    }

    triggerSensor = id => {

        this.setState({
            ...this.state,
            sensors: this.state.sensors.map(sensor => {
                if (sensor.id === id) {
                    return {...sensor, selected: !sensor.selected};
                }
                return sensor;
            }),
            error: null,
        });
    }

    save = () => {

        let selectedDevices = this.state.devices.filter(device => {
            return device.selected;
        })
        let selectedSensors = this.state.sensors.filter(sensor => {
            return sensor.selected;
        })
        if (selectedDevices.length === 0 && selectedSensors.length === 0) {
            this.setState({...this.state, error: {Type: "select", Text: "No devices selected"}})
            return;
        }
        this.props.SaveCallback(selectedDevices, selectedSensors);
    }

    render() {

        return (
            <div className={this.props.VlClass || "vl_deleted_devices"}
                 style={this.props.VlStyle || {}}>
                <div className="vl_deleted_devices_header">
                    <h5 className="vl_deleted_devices_header_text">{this.title()}</h5>
                </div>
                <div className="vl_deleted_devices_main">
                    {this.state.error ? this.error() : ""}
                    <div className="vl_deleted_devices_list_container">
                        <DevicesList Type="Select"
                                     Devices={this.state.devices}
                                     Select={this.triggerDevice}
                        />
                        <SensorsList Type="Select"
                                     Sensors={this.state.sensors}
                                     Select={this.triggerSensor}
                        />
                    </div>
                    <VlButton Text="Cancel"
                              Style="negative"
                              VlStyle={{position: "absolute", bottom: "2.5vh", right: "12vw", width: "8vw"}}
                              Callback={this.props.CancelCallback}/>
                    <VlButton Text="Restore"
                              VlStyle={{position: "absolute", bottom: "2.5vh", right: "3vw", width: "8vw"}}
                              Callback={this.save}/>
                </div>
            </div>
        )
    }
}

DeletedDevices.propTypes = {
    DeletedDevices: PropTypes.array.isRequired,
    DeletedSensors: PropTypes.array.isRequired,

    SaveCallback: PropTypes.func.isRequired,
    CancelCallback: PropTypes.func.isRequired,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}