import React from 'react'
import "../../styles/boxes/intensitybox.css"
import PropTypes from "prop-types";

const s_top = 3;
const s_height = 11;
const s_left = 3;
const s_width = 8;

export default class IntensityBox extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            isMoving: false,
        }
    }

    componentDidMount() {

        window.addEventListener("mouseup", this.setNotMoving);
    }

    componentWillUnmount() {

        window.removeEventListener("mouseup", this.setNotMoving);
    }

    setIsMoving = () => { this.setState({...this.state, isMoving: true}) }

    setNotMoving = () => {

        if (!this.state.isMoving) return;
        this.stopMovingCallback();
        this.setState({...this.state, isMoving: false});
    }

    stopMovingCallback = () => {

        if (typeof this.props.StopCallback === 'function') this.props.StopCallback();
    }

    horizontalClass = () => { return this.props.Horizontal ? "_horizontal" : "" }

    passedTrackStyle = () => {

        if (this.props.Horizontal) {
            let width = s_width * this.props.Intensity / 100;
            return {left: String(s_left) + "vw", width: String(width) + "vw"};
        }
        else {
            let height = s_height * this.props.Intensity / 100;
            let top = s_height + s_top - height;
            return {top: String(top) + "vh", height: String(height) + "vh"};
        }
    }

    thumbStyle = () => {

        if (this.props.Horizontal) {
            let value = s_left - 0.55 + (s_width * this.props.Intensity / 100);
            return {left: String(value) + "vw"};
        } else {
            let value = s_height + s_top - 1 - (s_height * this.props.Intensity / 100);
            return {top: String(value) + "vh"};
        }
    }

    intensityFromDimensions = (offset, height, keep_offset) => {

        let top_px = s_top * height / 100;
        let size_px = s_height * height / 100;
        if (!keep_offset) offset -= top_px;
        let result = (size_px - offset) / size_px * 100;
        if (result > 100) return 100;
        if (result < 0) return 0;
        return result | 0;
    }

    intensityFromDimensionsHorizontal = (offset, width, keep_offset) => {

        let left_px = s_left * width / 100;
        let size_px = s_width * width / 100;
        if (!keep_offset) offset -= left_px;
        let result = offset / size_px * 100;
        if (result > 100) return 100;
        if (result < 0) return 0;
        return result | 0;
    }

    changeIntensity = (e, keep_offset) => {

        let currentTargetRect = e.currentTarget.getBoundingClientRect();
        if (this.props.Horizontal) {
            const offset = e.pageX - currentTargetRect.left;
            this.props.Callback(this.intensityFromDimensionsHorizontal(offset, window.innerWidth, keep_offset));
        } else {
            const offset = e.pageY - currentTargetRect.top;
            this.props.Callback(this.intensityFromDimensions(offset, window.innerHeight, keep_offset));
        }
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_intensity_box_container" + this.horizontalClass()}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}
                 onMouseMove={(e) => {
                     if (this.state.isMoving) {
                         this.changeIntensity(e);
                         e.preventDefault();
                     }
                 }}
            >
                <div className={"vl_intensity_box_slider_track" + this.horizontalClass()} onClick={(e) => {
                    this.changeIntensity(e, true);
                    setTimeout(this.stopMovingCallback, 200);
                    e.preventDefault();
                }}/>
                <div className={"vl_intensity_box_slider_passed_track" + this.horizontalClass()}
                     style={this.passedTrackStyle()}
                     onClick={(e) => {
                         this.changeIntensity(e, true);
                         setTimeout(this.stopMovingCallback, 200);
                         e.preventDefault();
                     }}
                />
                <div className={"vl_intensity_box_slider_thumb" + this.horizontalClass()}
                     style={this.thumbStyle()}
                     onMouseDown={(e) => {
                         this.setIsMoving();
                         e.preventDefault();
                     }}
                />
                <p className={"vl_intensity_box_label" + this.horizontalClass()}>{this.props.Intensity}%</p>
            </div>
        )
    }
}

IntensityBox.propTypes = {
    Callback: PropTypes.func.isRequired,
    StopCallback: PropTypes.func,

    Intensity: PropTypes.number.isRequired,
    Horizontal: PropTypes.bool,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
