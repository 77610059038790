import React from 'react'
import "../../styles/tabs/devices_tab.css"
import PropTypes from "prop-types";
import AreasList from "../lists/areas_list";
import Filter from "../general/filter";
import ConfirmationDialog from "../dialogs/confirmation";
import AddGroup from "../panels/devices/add_group";
import {deviceIsUsed, removeMarker} from "../../../utils/map/devices";
import DeviceParameters from "../panels/devices/device_parameters";
import DeletedDevices from "../panels/devices/deleted_devices";
import UndeleteButton from "../buttons/undelete";


export default class DevicesTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            edit_area: null,
            edit_group: null,
            edit_device: null,
            deleted_devices: false,
            sort_field: 1,
            reverse_sort_activated: false,
            search_text: "",
            entryToDelete: null,
            groupToDelete: null,
            entryToDeleteIsDevice: false,
        }
    }

    componentDidMount() {

        this.props.Modify(1, false, "");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state !== prevState || this.props.OriginalAreas !== prevProps.OriginalAreas) {
            this.props.Modify(this.state.sort_field, this.state.reverse_sort_activated, this.state.search_text);
        }
    }

    containerClasses = () => {

        if (this.state.edit_area) return "vl_devices_tab_add_group";
        return "vl_devices_tab " + (this.state.entryToDelete ? "vl_devices_tab_without_scroll " : "");
    }

    openAddGroup = area => {

        this.setState({...this.state, edit_area: area, edit_group: null});
    }

    openEditGroup = (area, group) => {

        this.setState({...this.state, edit_area: area, edit_group: group});
    }

    openEditDevice = (device) => {

        this.setState({...this.state, edit_device: device});
    }

    openDeletedDevices = () => {

        this.setState({...this.state, deleted_devices: true});
    }

    closeAddGroup = () => {

        this.setState({...this.state, edit_area: null, edit_group: null});
    }

    closeEditDevice = () => {

        this.setState({...this.state, edit_device: null});
    }

    closeDeletedDevices = () => {

        this.setState({...this.state, deleted_devices: false});
    }

    saveGroup = (group_name, devices, sensors, editing) => {

        if (editing) {
            this.props.UpdateGroup(
                this.state.edit_area.id,
                this.state.edit_group,
                devices,
                sensors
            );
        } else {
            this.props.CreateGroup(
                this.state.edit_area.id,
                {
                    id: -1,
                    name: group_name,
                    intensity: 0
                },
                devices,
                sensors
            );
        }
        this.setState({...this.state, edit_area: null, edit_group: null});
    }

    saveDeviceParameters = (parameters) => {

        let device = this.state.edit_device;
        device.parameters = parameters;
        this.props.UpdateDevice(device);

        this.setState({...this.state, edit_device: null});
    }

    restoreDevices = (devices, sensors) => {

        devices.forEach(device => {
            device.deleted = false;
            this.props.UpdateDevice(device);
        });
        sensors.forEach(sensor => {
            sensor.deleted = false;
            this.props.UpdateSensor(sensor);
        });
        this.setState({...this.state, deleted_devices: false});
    }

    deleteRequest = (entry, isDevice, area_id) => {

        this.setState({...this.state, entryToDelete: entry, entryToDeleteAreaId: area_id, entryToDeleteIsDevice: isDevice});
    }

    delete = confirmed => {

        if (confirmed) {
            if (this.state.entryToDeleteIsDevice) {
                if (this.state.entryToDelete.id.startsWith("sensor")) {
                    this.props.RemoveSensor(this.state.entryToDelete.id, !deviceIsUsed(this.state.entryToDelete));
                } else {
                    this.props.Remove(this.state.entryToDelete.id, !deviceIsUsed(this.state.entryToDelete));
                }
                removeMarker(this.state.entryToDelete);
            } else {
                this.props.RemoveGroup(this.state.entryToDeleteAreaId, this.state.entryToDelete.id);
            }
        }
        this.setState({...this.state, entryToDelete: null});
    }

    updateDevice = device => {

        if (device.id.startsWith("sensor")) {
            this.props.UpdateSensor(device);
        } else {
            this.props.UpdateDevice(device);
        }
    }

    changeIntensity = (intensity, area_id, group, device) => {

        if (device) {
            if (this.props.ChangeDeviceIntensity)
                this.props.ChangeDeviceIntensity(device, intensity);
        } else {
            if (this.props.ChangeGroupIntensity)
                this.props.ChangeGroupIntensity(group, area_id, intensity);
        }
    }

    areas = () => {

        return (
            <>
                <Filter SortChanged={field => { this.setState({...this.state, sort_field: field}) }}
                        SortActivate={() => { this.setState({...this.state, reverse_sort_activated: !this.state.reverse_sort_activated}) }}
                        SearchChanged={text => { this.setState({...this.state, search_text: text}) }}
                        SortActivated={this.state.reverse_sort_activated}
                        Filters={[{Name: "Name", Value: 1}, {Name: "Intensity", Value: 2}, {Name: "Place", Value: 3}]}
                />
                <UndeleteButton VlStyle={{position: "absolute", right: "5px"}}
                                Callback={this.openDeletedDevices}/>
                <AreasList
                    VlStyle={{marginTop: "3vh"}}
                    VlAreaStyle={{backgroundColor: "white", marginTop: "1vh", borderRadius: "26px"}}
                    Areas={this.props.Areas}
                    UnusedDevices={this.props.UnusedDevices}
                    SelectedDevice={this.props.SelectedDevice}
                    Sensors={this.props.Sensors}
                    GroupsIntensityProgress={this.props.GroupsIntensityProgress}
                    DevicesIntensityProgress={this.props.DevicesIntensityProgress}
                    UpdateArea={this.props.UpdateArea}
                    UpdateGroup={this.props.UpdateGroup}
                    UpdateDevice={this.updateDevice}
                    DeleteCallback={this.deleteRequest}
                    AddGroup={this.openAddGroup}
                    EditGroup={this.openEditGroup}
                    EditDevice={this.openEditDevice}
                    ChangeIntensity={this.changeIntensity}
                />
                {
                    this.state.entryToDelete ?
                        <ConfirmationDialog Callback={this.delete}
                                            Text={"Are you sure you want to delete '" + this.state.entryToDelete.name + "' ?"}/> :
                        ""
                }
            </>
        );
    }

    addGroup = () => {

        return (
            <AddGroup Area={this.state.edit_area}
                      Group={this.state.edit_group}
                      CancelCallback={this.closeAddGroup}
                      SaveCallback={this.saveGroup}
            />
        );
    }

    editDevice = () => {

        return (
            <DeviceParameters Device={this.state.edit_device}
                              CancelCallback={this.closeEditDevice}
                              SaveCallback={this.saveDeviceParameters}
            />
        );
    }

    deletedDevices = () => {

        return (
            <DeletedDevices DeletedDevices={this.props.UnusedDevices.filter(device => device.deleted)}
                            DeletedSensors={this.props.Sensors.filter(sensor => sensor.deleted)}
                            CancelCallback={this.closeDeletedDevices}
                            SaveCallback={this.restoreDevices}/>
        );
    }

    content = () => {

        if (this.state.edit_device) return this.editDevice();
        if (this.state.edit_area) return this.addGroup();
        if (this.state.deleted_devices) return this.deletedDevices();
        return this.areas();
    }

    render() {

        return (
            <div className={this.containerClasses() + (this.props.VlClass || "") }
                 style={this.props.VlStyle || {}}>
                { this.content() }
            </div>
        )
    }
}

DevicesTab.propTypes = {
    Areas: PropTypes.array,
    OriginalAreas: PropTypes.array,
    UnusedDevices: PropTypes.array,
    SelectedDevice: PropTypes.object,
    Sensors: PropTypes.array,
    GroupsIntensityProgress: PropTypes.array,
    DevicesIntensityProgress: PropTypes.array,

    Remove: PropTypes.func,
    RemoveSensor: PropTypes.func,
    Modify: PropTypes.func,
    UpdateArea: PropTypes.func,
    CreateGroup: PropTypes.func,
    UpdateGroup: PropTypes.func,
    RemoveGroup: PropTypes.func,
    ChangeDeviceIntensity: PropTypes.func,
    ChangeGroupIntensity: PropTypes.func,
    UpdateDevice: PropTypes.func,
    UpdateSensor: PropTypes.func,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}