import {GET_INFO, SAVE_INFO, CHANGE_PASSWORD, ACTIVATE_2FA, RESET_2FA, SIGN_OUT, SAVING_INFO} from "./types";

export const getInfo = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/account/info",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                response.json().then(user => {

                    dispatch({
                        type: GET_INFO,
                        success: response.status === 200,
                        user: user
                    })
                })
            } else if (response.status === 401) {
                dispatch({type: SIGN_OUT})
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const saveInfo = (user) => dispatch => {

    dispatch({ type: SAVING_INFO });
    fetch("http://" + window.location.hostname + ":8080/account/info",
        {method: 'POST', credentials: 'include', mode: 'cors', body: JSON.stringify(user)})
        .then(response => {

            setTimeout( () => {
                dispatch({
                    type: SAVE_INFO,
                    success: (response.status === 200),
                    user: user
                })
            }, 1000);
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const changePassword = (user) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/account/password",
        {method: 'POST', credentials: 'include', mode: 'cors', body: JSON.stringify(user)})
        .then(response => {

            dispatch({
                type: CHANGE_PASSWORD,
                success: (response.status === 200),
                user: user
            })
        })
        .catch(function(error){

            alert("error: " + error)
        })
}

export const activate2FA = (code) => dispatch => {

    let data = new URLSearchParams()
    data.append('code', code)

    fetch("http://" + window.location.hostname + ":8080/account/activate_2fa",
        {method: 'POST', credentials: 'include', mode: 'cors', body: data})
        .then(response => {

            dispatch({
                type: ACTIVATE_2FA,
                success: (response.status === 200)
            })
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const reset2FA = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/account/reset_2fa",
        {method: 'POST', credentials: 'include', mode: 'cors'})
        .then(response => {

            dispatch({
                type: RESET_2FA,
                success: (response.status === 200)
            })
        })
        .catch(function(error){

            alert("error: " + error)
        })
}

export const deleteAccount = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/account/user",
        {method: 'DELETE', credentials: 'include', mode: 'cors'})
        .then(response => {

            dispatch({
                type: SIGN_OUT,
                success: (response.status === 200)
            })
        })
        .catch(function(error){

            alert("error: " + error)
        })
};