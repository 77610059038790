import React from 'react'
import "../../../styles/tabs/activation/activation.css"
import "../../../styles/tabs/activation/by_date.css"
import PropTypes from "prop-types";
import ActivationSceneByDate from "../../panels/activation/by_date";
import AddButton from "../../buttons/add";
import AddByDateActivation from "../../panels/activation/add_by_date";

export default class ActivationByDateTab extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            add: false,
            activationToEdit: null,
        }
    }

    addRequest = () => {

        this.setState({...this.state, add: true, activationToEdit: null})
    }

    editRequest = activation => {

        this.setState({...this.state, add: true, activationToEdit: activation})
    }

    saveRequest = activation => {

        if(this.state.activationToEdit) {
            this.props.UpdateActivationByDate(activation);
        } else {
            this.props.CreateActivationByDate(activation);
        }
        this.setState({...this.state, add: false, activationToEdit: null})
    }

    activationsList = () => {

        return (
            <>
                <div style={{position: "relative", width: "100%", height: "6.5vh"}}>
                    <AddButton Text={"Add New"}
                               VlStyle={{width: "150px", position: "absolute", right: "2.8vw", top: "1vh"}}
                               Callback={this.addRequest}/>
                </div>
                <div className="vl_activation_container">
                    {
                        this.props.Activations.map(activation => <ActivationSceneByDate key={activation.id}
                                                                                        Activation={activation}
                                                                                        DeleteCallback={this.props.RemoveActivationByDate}
                                                                                        EditCallback={this.editRequest}
                                                                                        SetActiveActivationByDate={this.props.SetActiveActivationByDate}
                        />)
                    }
                </div>
            </>
        )
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_activation_scenes_tab_by_date"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                {
                    this.state.add ?
                        <AddByDateActivation SaveCallback={this.saveRequest}
                                             CancelCallback={() => { this.setState({...this.state, add: false}) }}
                                             Scenes={this.props.Scenes}
                                             Activation={this.state.activationToEdit}
                        /> : this.activationsList()
                }
            </div>
        )
    }
}

ActivationByDateTab.propTypes = {
    Activations: PropTypes.array,
    Scenes: PropTypes.array,

    CreateActivationByDate: PropTypes.func,
    UpdateActivationByDate: PropTypes.func,
    RemoveActivationByDate: PropTypes.func,
    SetActiveActivationByDate: PropTypes.func,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
