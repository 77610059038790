import React from 'react'
import "../../styles/boxes/filebox.css"
import PropTypes from "prop-types";
import VlButton from "../buttons/vl_button";

export default class FileBox extends React.Component {

    constructor(props) {

        super(props);
        this.inputRef = React.createRef();
        this.dropRef = React.createRef();
        this.dragCounter = 0;
        this.state = {
            drag: false
        };
    }

    handleDrag = (e) => {

        e.preventDefault()
        e.stopPropagation()
    }

    handleDragIn = (e) => {

        e.preventDefault()
        e.stopPropagation()
        this.dragCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({drag: true})
        }
    }

    handleDragOut = (e) => {

        e.preventDefault()
        e.stopPropagation()
        this.dragCounter--
        if (this.dragCounter === 0) {
            this.setState({drag: false})
        }
    }

    handleDrop = (e) => {

        e.preventDefault()
        e.stopPropagation()
        this.setState({drag: false})
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            this.props.Callback(e.dataTransfer.files[0]);
        }
    }

    componentDidMount() {

        let div = this.dropRef.current
        div.addEventListener('dragenter', this.handleDragIn)
        div.addEventListener('dragleave', this.handleDragOut)
        div.addEventListener('dragover', this.handleDrag)
        div.addEventListener('drop', this.handleDrop)
    }

    componentWillUnmount() {

        let div = this.dropRef.current
        div.removeEventListener('dragenter', this.handleDragIn)
        div.removeEventListener('dragleave', this.handleDragOut)
        div.removeEventListener('dragover', this.handleDrag)
        div.removeEventListener('drop', this.handleDrop)
    }

    fileSelected = e => {

        if (e.target.files && e.target.files[0]) {
            this.props.Callback(e.target.files[0]);
        }
    }

    addOnClasses = () => {

        if (this.state.drag) return " vl_file_box_dragging"
        return "";
    }

    render() {

        return (
            <div className={this.props.VlClass || ("vl_file_box" + this.addOnClasses())}
                 style={this.props.VlStyle || {}}
                 ref={this.dropRef}>
                <div className="vl_file_box_cloud"/>
                <p className="vl_file_box_text">Drag and drop your map here</p>
                <p className="vl_file_box_text">Or</p>
                <input type="file"
                       accept=".png"
                       id="vl_file_box_input"
                       ref={this.inputRef}
                       onChange={this.fileSelected}/>
                <VlButton Text="Browse" Callback={() => {this.inputRef.current.click()}}/>
            </div>
        )
    }
}

FileBox.propTypes = {
    Callback: PropTypes.func.isRequired,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
