import React from "react";
import "../../styles/decorations/banner.css"
import PropTypes from "prop-types";

export default class Banner extends React.Component {

    constructor(props) {

        super(props);
        this.bannerRef = React.createRef();
        this.state = {
            icon_classes: "",
            title_classes: "",
        }
    }

    componentDidMount() {
        if (this.state.icon_classes.length === 0) {

            window.addEventListener('resize', this.resized);
            this.resized();
        }
    }

    componentWillUnmount() {

        window.removeEventListener('resize', this.resized);
    }

    resized = () => {

        this.setState({
            ...this.state,
            icon_classes: "vl_banner_icon" + this.iconSizeClass(),
            title_classes: "vl_banner_title" + this.titleSizeClass(),
        })
    }

    style = () => {

        let result = {};
        if (this.props.VlWidth)
            result.width = this.props.VlWidth;
        if (this.props.VlHeight)
            result.height = this.props.VlHeight;
        return result;
    }

    iconSizeClass = () => {

        if (this.bannerRef.current &&
            this.bannerRef.current.clientWidth > 600 &&
            this.bannerRef.current.clientHeight > 80) {
            return " vl_banner_icon_size_big";
        }
        return " vl_banner_icon_size_small";
    }

    titleSizeClass = () => {

        if (this.bannerRef.current &&
            this.bannerRef.current.clientWidth > 600 &&
            this.bannerRef.current.clientHeight > 80) {
            return " vl_banner_title_size_big";
        }
        return " vl_banner_title_size_small";
    }

    render() {

        return (
            <div ref={this.bannerRef}
                 style={this.style()}
                 className="vl_banner">
                <div className={this.state.icon_classes}/>
                <div className={this.state.title_classes}/>
            </div>
        )
    }
}

Banner.propTypes = {
    VlWidth: PropTypes.string,
    VlHeight: PropTypes.string,
}
