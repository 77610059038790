import React from 'react'
import "../../styles/lists/devices_list.css"
import Device from "../panels/devices/device"
import PropTypes from "prop-types";

export default class DevicesList extends React.Component {

    componentFromDevice = device => {

        switch (this.props.Type) {

            case "Select":
                return (
                    <Device key={device.id}
                            CheckCallback={() => this.props.Select(device.id)}
                            Checked={device.selected}
                            Device={device} />
                )
            case "Info":
                return (
                    <Device key={device.id}
                            Device={device}
                            DeleteCallback={this.props.DeleteCallback} />
                )
            default:
                return (
                    <Device key={device.id}
                            IntensityProgress={!!this.props.DevicesIntensityProgress.find(id => device.id === id)}
                            ChangeIntensity={this.props.ChangeIntensity}
                            DeleteCallback={this.props.DeleteCallback}
                            UpdateDevice={this.props.UpdateDevice}
                            EditCallback={this.props.EditDevice}
                            Device={device} />
                )

        }
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_devices_list"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}
                 disabled={this.props.Disabled}
            >
                {
                    this.props.Devices.map(device => this.componentFromDevice(device))
                }
            </div>
        )
    }
}

DevicesList.propTypes = {
    Devices: PropTypes.array.isRequired,
    Disabled: PropTypes.bool,
    Type: PropTypes.string,
    DevicesIntensityProgress: PropTypes.array,

    ChangeIntensity: PropTypes.func,
    DeleteCallback: PropTypes.func,
    UpdateDevice: PropTypes.func,
    EditDevice: PropTypes.func,
    Select: PropTypes.func,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
