import React from 'react'
import PropTypes from "prop-types";
import "../../../styles/tabs/reports/power_consumption.css"
import {timestampToYear, timestampToYearAndMonth} from "../../../../utils/time";
import {monthsFromReports, reportsDiffer, yearsFromReports} from "../../../../utils/reports";
import PowerChart from "../../charts/power_chart";
import SelectBox from "../../boxes/selectbox";

const MonthAndYear = [{Name: "Monthly", Value: 0}, {Name: "Yearly", Value: 1}];
const DefaultGroups = [{Name: "All Groups", Value: {area_id: -1, group_id: -1}}];

export default class PowerConsumption extends React.Component {

    constructor(props) {

        super(props);
        let months = monthsFromReports(this.props.Reports, -1, -1);
        this.state = {
            useYear: false,
            period: (months.length > 0 ? months[0].Value : -1),
            area: -1,
            group: -1,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.Reports !== prevProps.Reports && reportsDiffer(this.props.Reports, prevProps.Reports)) {
            let months = monthsFromReports(this.props.Reports, this.state.area, this.state.group);
            this.setState({
                ...this.state,
                periodName: (months.length > 0 ? months[0].Name : -1),
                period: (months.length > 0 ? months[0].Value : -1)
            })
        }
    }

    onUseYearSelected = year => {

        this.setState({...this.state, useYear: String(year) === "1"});
    }

    onPeriodSelected = (value, data) => {

        this.setState({...this.state, periodName: value, period: data});
    }

    onGroupSelected = value => {

        this.setState({...this.state, area: parseInt(value.area_id), group: parseInt(value.group_id)});
    }

    selectedGroup = () => {

        return this.props.Groups.find(group => group.Value.area_id === this.state.area && group.Value.group_id === this.state.group);
    }

    periodCases = () => {

        if (this.state.useYear) {
            return yearsFromReports(this.props.Reports, this.state.area, this.state.group).map(option => {
                return {Name: option.Name, Value: option.Name, Data: option.Value}
            });
        } else {
            return monthsFromReports(this.props.Reports, this.state.area, this.state.group).map(option => {
                return {Name: option.Name, Value: option.Name, Data: option.Value}
            });
        }
    }

    groupCases = () => {

        return DefaultGroups.concat(this.props.Groups);
    }

    title = () => {

        if (this.state.period < 0) {
            return "Time not selected";
        }
        let result = "";
        if (this.state.useYear) result += "Yearly for ";
        else result += "Monthly for ";

        let group = this.props.Groups.filter(g => g.Value === this.state.group);
        result += group.length > 0 ? "'" + group[0].Name + "'" : "all groups" ;
        return result + (
            this.state.period ? " (" +
                (this.state.useYear ? timestampToYear(this.state.period) : timestampToYearAndMonth(this.state.period)) +
                ")" : ""
        );
    }

    chartFromSelections = () => {

        return <PowerChart Data={this.state.useYear ? this.props.Reports.years_by_month : this.props.Reports.months_by_day}
                           SelectedDate={this.state.period}
                           SelectedArea={this.state.area}
                           SelectedGroup={this.state.group}
                           UseYear={!!this.state.useYear}/>
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_power_consumption_tab"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_power_consumption_info_bar">
                    <p className="vl_power_consumption_title">{this.title()}</p>
                    <SelectBox Callback={this.onGroupSelected}
                               Cases={this.groupCases()}
                               Selected={this.selectedGroup()}
                               VlStyle={{marginLeft: "auto"}}
                    />
                    <SelectBox Callback={this.onUseYearSelected}
                               Cases={MonthAndYear}
                               VlStyle={{marginLeft: "2%"}}/>
                    <SelectBox Callback={this.onPeriodSelected}
                               Cases={this.periodCases()}
                               Selected={{Name: this.state.periodName, Value: this.state.periodName}}
                               VlStyle={{marginLeft: "2%", marginRight: "7.5%"}}/>
                </div>
                <div className="vl_power_consumption_chart_container">
                    { this.chartFromSelections() }
                </div>
            </div>
        )
    }
}

PowerConsumption.propTypes = {
    Reports: PropTypes.object.isRequired,
    Groups: PropTypes.array.isRequired,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}