import L from "leaflet";
import "../../ui/styles/map/map.css"
import {setupDrawToolBarOnMap} from "./draw_toolbar";
import {
    areaCreated,
    areaEditStop, areaLayerFromAreaId,
    areaRemoved, getAreaGeometries,
    getAreaIdFromLayer,
    getAreasAsLayers,
    getAreasFeatureGroup, setAreas
} from "./areas";
import {
    createMarker,
    deviceIsUsed,
    devicesContainId, devicesInsideLayer,
    setDevices,
    setupDevicesCallbacks,
    unselectAllDevices
} from "./devices";
import {layerOfPoint, selectLayer} from "./helpers";
import {defaultAreaStyle, imageBounds, selectedAreaStyle} from "./consts";
import {devicesFromAreas} from "../devices";
import {sensorsFromAreas} from "../sensors";

const mapOptions = {
    center: [0, 0],
    zoom: 2,
    zoomControl: true
};
let map = null;
let currentPlan;

let createCallback = () => {};
let removeCallback = () => {};
let editCallback = () => {};
let changeDeviceCallback = () => {};


export function l_map() {

    return map;
}

export function createMap(_createCallback, _removeCallback, _editCallback, _selectDeviceCallback, _changeDeviceCallback) {

    if (typeof _createCallback === 'function') createCallback = _createCallback;
    if (typeof _removeCallback === 'function') removeCallback = _removeCallback;
    if (typeof _editCallback === 'function') editCallback = _editCallback;
    if (typeof _changeDeviceCallback === 'function') changeDeviceCallback = _changeDeviceCallback;
    setupDevicesCallbacks(_selectDeviceCallback, createDevice, moveDevice, putDevice, removeDevice);

    map = L.map('map', mapOptions);
    setupDrawToolBarOnMap(map, getAreasFeatureGroup());
    setupMapEvents();
}

export function showMapItems(areas, mapItems, place) {

    setAreas(areas, place);
    setDevices(mapItems, place);
}

export function showPlan(plan, bounds) {

    unselectAllDevices();
    if (map.hasLayer(currentPlan)) {
        map.removeLayer(currentPlan);
    }
    let imgBounds = imageBoundsFromPlanBounds(bounds);
    currentPlan = L.imageOverlay(plan, imgBounds).addTo(map);
    map.fitBounds(maxOfBounds(imageBounds, imgBounds));
    map.setMaxBounds(maxOfBounds(imageBounds, imgBounds));
}

function setupMapEvents() {

    map.on('draw:created', e => {
        createCallback(areaCreated(e), devicesInsideLayer(e.layer));
    });
    map.on('draw:deleted', e => {
        let removedIds = areaRemoved(e);
        removeCallback(removedIds)
    });
    map.on('draw:editstop', e => {
        if (areaEditStop(e)) {
            editCallback(getAreaGeometries());
        } else {
            editCallback(null);
        }
    });
}

export function draggingDevice(pos) {

    let layersOfAreas = getAreasAsLayers();
    let l = layerOfPoint(latLngFromDragPos(pos), layersOfAreas);
    selectLayer(layersOfAreas, l, defaultAreaStyle, selectedAreaStyle);
}

export function dropDevice (device, pos) {

    unselectAllLayers();
    if (!pos || deviceIsUsed(device) || devicesContainId(device.id)) return false;
    let marker = createDevice(device, latLngFromDragPos(pos));
    putDevice(device.id, marker);
    return true;
}

function moveDevice(id, marker) {

    let layersOfAreas = getAreasAsLayers();
    let l = layerOfPoint(marker.getLatLng(), layersOfAreas);
    selectLayer(layersOfAreas, l, defaultAreaStyle, selectedAreaStyle);
}

function putDevice(id, marker) {

    unselectAllLayers();
    let layersOfAreas = getAreasAsLayers();
    let l = layerOfPoint(marker.getLatLng(), layersOfAreas);
    changeDeviceCallback(id, getAreaIdFromLayer(l), marker.getLatLng());
}

function createDevice(device, coordinates) {

    let marker = createMarker(device, coordinates);
    marker.addTo(map);
    return marker;
}

function removeDevice(marker) {

    map.removeLayer(marker);
}

function unselectAllLayers() {

    let layersOfAreas = getAreasAsLayers();
    selectLayer(layersOfAreas, null, defaultAreaStyle, selectedAreaStyle);
}

export function latLngFromDragPos(position) {

    const elem = document.getElementById("map");
    const rect = elem.getBoundingClientRect();
    const X = position.clientX - rect.x | 0;
    const Y = position.clientY - rect.y | 0;

    return map.containerPointToLatLng(L.point(X, Y));
}

export function goToBoundsOfArea(areaId) {

    let layer = areaLayerFromAreaId(areaId);
    if (layer) {
        map.fitBounds(layer.getBounds(), {animate: true, duration: 1})
    }
}

function imageBoundsFromPlanBounds(planBounds) {

    if (!planBounds ||
        (planBounds.top_left_point.lat === 0 && planBounds.bottom_right_point.lat === 0) ||
        (planBounds.top_left_point.lng === 0 && planBounds.bottom_right_point.lng === 0)) {
        return imageBounds;
    }
    return [
        [planBounds.top_left_point.lat, planBounds.top_left_point.lng],
        [planBounds.bottom_right_point.lat, planBounds.bottom_right_point.lng]
    ]
}

function maxOfBounds(first, second) {

    return [
        [Math.min(first[0][0], second[0][0]), Math.min(first[0][1], second[0][1])],
        [Math.max(first[1][0], second[1][0]), Math.max(first[1][1], second[1][1])],
    ]
}