import React from 'react'
import "../../styles/buttons/edit.css"
import PropTypes from "prop-types";

export default class EditButton extends React.Component {

    render() {
        return (
            <button
                className={this.props.VlClass ? this.props.VlClass : "vl_edit_button"}
                style={this.props.VlStyle ? this.props.VlStyle : {}}
                onClick={this.props.Callback}>
                {this.props.HideText ? "" : <p className="vl_edit_button_text">Edit</p>}
                <p className="vl_edit_button_icon" />
            </button>
        )}
}

EditButton.propTypes = {
    Callback: PropTypes.func,
    HideText: PropTypes.bool,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
