import React from 'react'
import "../../styles/buttons/add_map.css"
import PropTypes from "prop-types";

export default class AddButton extends React.Component {

    render() {
        return (
            <button
                className={(this.props.VlClass || "") + " vl_add_map_button"}
                style={this.props.VlStyle || {}}
                onClick={this.props.Callback}>
                <span className="vl_add_map_button_text">{this.props.Text ? this.props.Text : "Add"}</span>
                <p className="vl_add_map_button_icon_container" />
                <p className="vl_add_map_button_icon"/>
            </button>
        )}
}

AddButton.propTypes = {
    Callback: PropTypes.func,
    Text: PropTypes.string,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
