import React from "react";
import "../styles/pages/profile.css"
import "../styles/general.css"
import {connect} from "react-redux";
import {activate2FA, changePassword, deleteAccount, getInfo, reset2FA, saveInfo} from "../../actions/account";
import ProfileEdit from "../components/panels/profile/profile_edit";
import ProfileInfo from "../components/panels/profile/profile_info";
import Profile2FA from "../components/panels/profile/profile_2fa";
import Profile2FAApproval from "../components/panels/profile/profile_2fa_approval";

class Profile extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            panel: "info",
        }
    }

    componentDidMount() {

        if (!this.props.user)
            this.props.getInfo()
    }

    navigate = panel => {

        this.setState({...this.state, panel: panel})
    }

    content = () => {

        switch (this.state.panel) {

            case "info":
                return <ProfileInfo User={this.props.user}
                                    DeleteAccount={this.props.deleteAccount}
                                    Navigate={this.navigate}
                />;
            case "edit":
                return <ProfileEdit User={this.props.user}
                                    Saving={this.props.saving}
                                    Deactivate={this.props.reset2FA}
                                    Save={this.props.saveInfo}
                                    ChangePassword={this.props.changePassword}
                                    Navigate={this.navigate}
                />;
            case "2fa":
                return <Profile2FA User={this.props.user}
                                   Activate={this.props.activate2FA}
                                   Navigate={this.navigate}
                />;
            case "2fa_approval":
                return <Profile2FAApproval Navigate={this.navigate}/>;
            default:
                return "";
        }
    }

    render() {

        return (
            <div className="vl_profile_panel">
                {this.content()}
            </div>
        )
    }
}

const mapStateToProfileProps = state => ({
    user: state.account.currentUser,
    saving: state.account.saving,
})

export default connect(mapStateToProfileProps, {
    getInfo,
    saveInfo,
    changePassword,
    activate2FA,
    reset2FA,
    deleteAccount
})(Profile);