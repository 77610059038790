import { combineReducers } from 'redux'
import AuthReducer from "./auth_reducer"
import AccountReducer from "./account_reducer"
import AdminReducer from "./admin_reducer"
import HardwareReducer from "./hardware_reducer"
import AreasReducer from "./areas_reducer"
import ProgressReducer from "./progress_reducer"
import ReportsReducer from "./reports_reducer"
import ScenesReducer from "./scenes_reducer"
import StatusReducer from "./status_reducer"
import ActivationsReducer from "./activation_reducer"
import PlacesReducer from "./places_reducer"
import NotificationsReducer from "./notifications_reducer"
import LogsReducer from "./logs_reducer"
import ErrorsReducer from "./errors_reducer"

export default combineReducers ({
    account: AccountReducer,
    admin: AdminReducer,
    areas: AreasReducer,
    auth: AuthReducer,
    errors: ErrorsReducer,
    hardware: HardwareReducer,
    logs: LogsReducer,
    notifications: NotificationsReducer,
    places: PlacesReducer,
    progress: ProgressReducer,
    reports: ReportsReducer,
    scenes: ScenesReducer,
    status: StatusReducer,
    activations: ActivationsReducer,
});
