import React from 'react'
import "../../styles/buttons/vl_button.css"
import PropTypes from "prop-types";

export default class VlButton extends React.Component {

    addOnClass = () => {

        return this.props.VlClass ? this.props.VlClass : ""
    }

    classFromStyleAndIcon = () => {

        let result = ""
        switch (this.props.Style) {
            case "empty":
                result = "vl_empty_button "
                break
            case "negative":
                result = "vl_negative_button "
                break
            default:
                result = "vl_regular_button "
                break
        }

        switch (this.props.Icon) {
            case "delete":
                result += "vl_button_delete_icon "
                break
            case "close":
                result += "vl_button_close_icon "
                break
            case "back":
                result += "vl_button_back_icon "
                break
            default:
                break
        }
        return result + this.addOnClass()
    }

    render() {
        return (
            <button
                className={this.classFromStyleAndIcon()}
                style={this.props.VlStyle ? this.props.VlStyle : {}}
                onClick={this.props.Callback}>
                {this.props.Text ? this.props.Text : this.props.children}
            </button>
        )}
}

VlButton.propTypes = {
    Callback: PropTypes.func,
    Text: PropTypes.string,
    Style: PropTypes.string,
    Icon: PropTypes.string,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
