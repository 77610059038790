import {
    ADD_PLACE,
    GET_PLACES,
    GET_PLAN,
    PLAN_LOADING,
    REMOVE_PLACE,
    SET_DRAGGING_DEVICE,
    SIGN_OUT,
    UPLOAD_PLAN
} from "./types";

export const getPlaces = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/places/all",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {

                response.json().then(places => {

                    dispatch({
                        type: GET_PLACES,
                        places: places
                    })
                })
            } else if (response.status === 401) {
                dispatch({type: SIGN_OUT})
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const uploadPlace = (label, bounds, plan) => dispatch => {

    let place = {
        name: label,
        plan: plan || "",
        bounds: {
            top_left_point: { lat: bounds[0][0], lng: bounds[0][1] },
            bottom_right_point: { lat: bounds[1][0], lng: bounds[1][1] },
        }
    }
    fetch("http://" + window.location.hostname + ":8080/places/upload",
        {method: 'POST', credentials: 'include', mode: 'cors', body: JSON.stringify(place)})
        .then(response => {

            if (response.status === 200) {
                dispatch({
                    type: ADD_PLACE,
                    place: place,
                })
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
}

export const removePlace = name => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/places/remove?name=" + name,
        {method: 'DELETE', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                dispatch({
                    type: REMOVE_PLACE,
                    name: name,
                })
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
}

export const getPlan = (place) => dispatch => {

    dispatch({
        type: PLAN_LOADING,
        name: place,
    })
    fetch("http://" + window.location.hostname + ":8080/places/plan?place=" + place,
        {method: "GET", credentials: 'include', mode: 'cors'})
        .then(response => {
            if (response.status === 200) {
                response.blob().then(blob => {
                    let img = URL.createObjectURL(blob);
                    dispatch({
                        type: GET_PLAN,
                        name: place,
                        url: img,
                    });
                })
            } else {
                dispatch({
                    type: GET_PLAN,
                    name: place,
                    url: 'http://' + window.location.hostname + ':3000/plan1.png',
                })
            }
        }).catch(function(error){

        alert("error: " + error)
    });
}

export const uploadPlan = (file, name, bounds) => dispatch => {

    let formData = new FormData();
    let boundsParams = "";
    if (bounds) boundsParams = "&v1=" + bounds[0][0] + "&h1=" + bounds[0][1] + "&v2=" + bounds[1][0] + "&h2=" + bounds[1][1];

    formData.append("plan", file);
    fetch("http://" + window.location.hostname + ":8080/places/plan?place=" + name + boundsParams,
        {method: "POST", credentials: 'include', mode: 'cors', body: formData}).then(response => {

        if (response.status === 200) {
            dispatch({
                type: UPLOAD_PLAN,
                name: name,
                bounds: bounds,
                url: URL.createObjectURL(file),
            })
        }
    }).catch(function(error){

        alert("error: " + error)
    });
}

export const dragDevice = (device) => dispatch => {

    dispatch({
        type: SET_DRAGGING_DEVICE,
        device: device
    })
}