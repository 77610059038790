import React from 'react'
import "../../styles/dialogs/dialog.css"
import "../../styles/dialogs/sendToHelp.css"
import PropTypes from "prop-types";
import VlButton from "../buttons/vl_button";

export default class SendToHelpDialog extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            email: this.props.DefaultEmail || "",
            notes: "",
        }
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_dialog"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_send_to_help_dialog">
                    <p className="vl_send_to_help_dialog_text">{this.props.Text}</p>
                    <input className="vl_send_to_help_dialog_input"
                           defaultValue={this.props.DefaultEmail || ""}
                           type="email"
                           placeholder="Email"
                           onChange={e => { this.setState({...this.state, email: e.target.value}); }}
                    />
                    <textarea className="vl_send_to_help_dialog_input_notes"
                           placeholder="Notes"
                           onChange={e => { this.setState({...this.state, notes: e.target.value}); }}
                    />
                    <VlButton Text="Send"
                              Style={this.state.email.length > 0 ? "" : "negative"}
                              VlClass="vl_send_to_help_dialog_yes"
                              Callback={() => {
                                  if (this.state.email.length > 0)
                                      this.props.Callback(this.state.email, this.state.notes);
                              }}
                    />
                    <VlButton Text="Cancel"
                              Style="negative"
                              VlClass="vl_send_to_help_dialog_no"
                              Callback={() => this.props.Callback("")}
                    />
                </div>
            </div>
        )
    }
}

SendToHelpDialog.propTypes = {
    Callback: PropTypes.func.isRequired,
    DefaultEmail: PropTypes.string,
    Text: PropTypes.string,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
