import React from 'react'
import "../../styles/dialogs/dialog.css"
import "../../styles/dialogs/add_map.css"
import PropTypes from "prop-types";
import VlButton from "../buttons/vl_button";
import EditBox from "../boxes/editbox";
import FileBox from "../boxes/filebox";
import {MapView} from "../map/map_view";


export default class AddMapDialog extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            img: null,
            displayShown: this.isEdit(),
            name: "",
            bounds: this.bounds(),
        }
    }

    isEdit = () => {

        return this.props.Name.length > 0;
    }

    bounds = () => {

        if (!this.isEdit() || this.props.Bounds?.top_left_point.lat === 0) return [[-50, -90], [50, 90]];
        return [
            [this.props.Bounds?.top_left_point.lat, this.props.Bounds?.top_left_point.lng],
            [this.props.Bounds?.bottom_right_point.lat, this.props.Bounds?.bottom_right_point.lng]
        ];
    }

    changeBounds = newBounds => {

        this.setState({...this.state, bounds: newBounds});
    }

    image = () => {

        return this.state.img || this.props.Image;
    }

    backToSelect = () => {

        this.setState({...this.state, displayShown: false});
    }

    displayMapSection = () => {

        return (
            <>
                <div className="vl_add_map_dialog_file_display_container">
                    <MapView Image={this.image()}
                             Vertical={this.state.bounds[1][0]}
                             Horizontal={this.state.bounds[1][1]}
                             BoundsChanged={this.changeBounds}/>
                    <EditBox Callback={name => {this.setState({...this.state, name: name})}}
                             Placeholder="Name your map"
                             Default={this.props.Name}
                             Disabled={this.isEdit()}
                             VlEditClass="vl_add_map_dialog_naming_input"/>
                    <p className="vl_add_map_dialog_error">{this.props.Error}</p>
                    <VlButton Text="Save" Callback={() => {
                        if (this.state.img || this.isEdit()) {
                            this.props.Callback(
                                this.state.img,
                                this.isEdit() ? this.props.Name : this.state.name,
                                this.state.bounds
                            );
                        }
                    }
                    }/>
                </div>
            </>
        )
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_dialog"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className={"vl_add_map_dialog" + ((this.state.img || this.isEdit()) ? " vl_add_map_dialog_extended" : "")}>
                    <VlButton Style="empty"
                              Icon="close"
                              Callback={this.props.CloseCallback}
                              VlClass="vl_add_map_dialog_close_button"
                    />
                    {
                        this.state.displayShown ?
                            <>
                                <VlButton Style="empty"
                                          VlClass="vl_add_map_back_button"
                                          Icon="back"
                                          Callback={this.backToSelect}
                                />
                                {this.displayMapSection()}
                            </>:
                            <FileBox  Callback={img => this.setState({...this.state, img: img, displayShown: true})}
                                      VlStyle={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",

                                          width: "554px",
                                          height: "355px",
                                      }}
                            />
                    }
                </div>
            </div>
        )
    }
}

AddMapDialog.propTypes = {
    Callback: PropTypes.func.isRequired,
    CloseCallback: PropTypes.func.isRequired,

    Plan: PropTypes.string,
    Name: PropTypes.string,
    Image: PropTypes.string,
    Bounds: PropTypes.object,
    Error: PropTypes.string,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
