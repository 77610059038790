import React from 'react'
import "../styles/pages/users.css"
import "../styles/general.css"
import UsersTable from "../components/tables/users_table"
import VlButton from "../components/buttons/vl_button";
import ConfirmationDialog from "../components/dialogs/confirmation";
import UserDialog from "../components/dialogs/user";
import {connect} from "react-redux";
import {getUsers, createUser, editUser, deleteUser} from "../../actions/admin";

class Users extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userToDelete: null,
            userDialogOpened: false,
            editUser: null,
        }
    }

    componentDidMount() {

        this.props.getUsers();
    }

    usersList = () => {

        return this.props.users.map((user, index) => {
            return {ID: index, Name: user.username, Status: user.is_admin ? "admin" : "user"}
        })
    }

    deleteRequest = user => {

        this.setState({...this.state, userToDelete: user});
    }

    delete = confirmed => {

        if (confirmed && this.state.userToDelete) { this.props.deleteUser(this.state.userToDelete.Name) }
        this.setState({...this.state, userToDelete: null});
    }

    userDialogCallback = user => {

        if (user !== null) {
            let resultUser = {username: user.Name, password: user.Password, is_admin: user.Status === "admin"};
            if (this.state.editUser) {
                this.props.editUser(resultUser);
            } else {
                this.props.createUser(resultUser);
            }
        }
        this.setState({...this.state, userDialogOpened: false, editUser: null});
    }

    render() {

        return (
            <div className="vl_users_panel">
                <UsersTable Users={this.usersList()}
                            DeleteCallback={this.deleteRequest}
                            EditCallback={user => this.setState({...this.state, userDialogOpened: true, editUser: user})}
                />
                <VlButton Style="empty"
                          Text="Add user"
                          VlStyle={{position: "absolute", right: "40px", top: "40px"}}
                          Callback={() => { this.setState({...this.state, userDialogOpened: true}) }}/>
                {
                    this.state.userToDelete ?
                        <ConfirmationDialog Callback={this.delete}
                                            Text={"Are you sure you want to delete user '" +
                                                this.state.userToDelete.Name + "' (" +
                                                this.state.userToDelete.Status + ") ?"}/> :
                        ""
                }
                {
                    this.state.userDialogOpened ?
                        <UserDialog Callback={this.userDialogCallback}
                                    User={this.state.editUser}
                        /> : ""
                }
            </div>
        )
    }
}

const mapStateToProfileProps = state => ({
    users: state.admin.users,
})

export default connect(mapStateToProfileProps, {
    getUsers,
    createUser,
    editUser,
    deleteUser
})(Users);