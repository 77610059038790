export function addGroupToArea(areas, group, area_id, devices, sensors) {

    let area = areas.find(area => area.id === area_id);
    if (area) {
        if (!area.groups) area.groups = [];
        if (!group.id || group.id < 0) group.id = area.groups.length > 0 ? area.groups[area.groups.length - 1].id + 1 : 1;
        moveAreaDevicesToGroupDevices(area, group, devices);
        moveAreaSensorsToGroupSensors(area, group, sensors);
        area.groups.push(group);
    }
    return areas;
}

export function updateGroupInArea(areas, group, area_id, devices, sensors, intensity_changed, onlyGroup) {

    let area = areas.find(area => area.id === area_id);
    if (area) {
        area.groups = area.groups.map(g => {
            if (g.id === group.id) {
                if (!onlyGroup) {
                    moveAreaDevicesToGroupDevices(area, group, devices);
                    moveAreaSensorsToGroupSensors(area, group, sensors);
                    if (intensity_changed) {
                        group.devices = group.devices.map(d => {
                            return {...d, intensity: group.intensity}
                        });
                    }
                }
                return group
            }
            return g;
        });
    }
    return areas;
}

export function removeGroupInArea(areas, group_id, area_id) {

    let area = areas.find(area => area.id === area_id);
    if (area) {
        let groupToRemove = area.groups.find(g => g.id === group_id);
        area.groups = area.groups.filter(g => g.id !== group_id);
        if (!area.devices) area.devices = [];
        if (groupToRemove.devices) area.devices.push(...groupToRemove.devices);
    }
    return areas;
}

function moveAreaDevicesToGroupDevices(area, group, devices) {

    if (area.devices && devices) {
        if (!group.devices) group.devices = [];
        let removedDevices = group.devices.filter(d => !devices.find(a => a.id === d.id))
        group.devices = group.devices.filter(d => devices.find(a => a.id === d.id));
        group.devices.push(...area.devices.filter(d => devices.find(a => a.id === d.id)).map(d => {
            return {...d, group_id: group.id}
        }));
        area.devices = area.devices.filter(d => !devices.find(a => a.id === d.id));
        area.devices.push(...removedDevices.map(d => { return {...d, group_id: -1}}));
    }
}

function moveAreaSensorsToGroupSensors(area, group, sensors) {

    if (area.sensors && sensors) {
        if (!group.sensors) group.sensors = [];
        let removedSensors = group.sensors.filter(d => !sensors.find(a => a.id === d.id))
        group.sensors = group.sensors.filter(d => sensors.find(a => a.id === d.id));
        group.sensors.push(...area.sensors.filter(d => sensors.find(a => a.id === d.id)).map(d => {
            return {...d, group_id: group.id}
        }));
        area.sensors = area.sensors.filter(d => !sensors.find(a => a.id === d.id));
        area.sensors.push(...removedSensors.map(d => { return {...d, group_id: -1}}));
    }
}