import {GET_USERS, CREATE_USER, DELETE_USER, EDIT_USER} from "./types";

export const getUsers = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/admin/users",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => response.json())
        .then(users => {

            dispatch({
                type: GET_USERS,
                users: users
            })
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const createUser = (user) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/admin/user",
        {method: 'POST', credentials: 'include', mode: 'cors', body: JSON.stringify(user)})
        .then(response => {

            dispatch({
                type: CREATE_USER,
                user: user,
                success: (response.status === 200)
            })
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const editUser = (user) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/admin/user",
        {method: 'PUT', credentials: 'include', mode: 'cors', body: JSON.stringify(user)})
        .then(response => {

            dispatch({
                type: EDIT_USER,
                user: user,
                success: (response.status === 200)
            })
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const deleteUser = (username) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/admin/user?username=" + username,
        {method: 'DELETE', credentials: 'include', mode: 'cors'})
        .then(response => {

            dispatch({
                type: DELETE_USER,
                username: username,
                success: (response.status === 200)
            })
        })
        .catch(function(error){

            alert("error: " + error)
        })
};
