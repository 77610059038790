import React from 'react'
import PropTypes from "prop-types";
import CanvasJSReact from "../../../lib/canvasjs.react";
let CanvasJSChart = CanvasJSReact.CanvasJSChart;


export default class ErrorsChart extends React.Component {

    canvasOptions = () => {

        return {
            animationEnabled: true,
            title: {
                text: "Errors"
            },
            subtitles: [{
                text: String(this.props.Errors?.total_errors || 0) + " total",
                verticalAlign: "center",
                fontSize: 24,
                dockInsidePlotArea: true
            }],
            data: [{
                type: "doughnut",
                showInLegend: true,
                indexLabel: "{name}: {y}",
                yValueFormatString: "#",
                dataPoints: [
                    { name: "Availability", y: this.props.Errors?.total_availability_errors || 0 },
                    { name: "Led Load", y: this.props.Errors?.total_led_load_errors  || 0},
                    { name: "Input Voltage", y: this.props.Errors?.total_input_voltage_errors  || 0},
                    { name: "Temperature", y: this.props.Errors?.total_temperature_errors  || 0},
                    { name: "Sync Intensity", y: this.props.Errors?.total_sync_intensity_errors || 0}
                ]
            }]
        }
    }

    render() {

        return (
            <div style={{
                width: this.props.VlWidth ? this.props.VlWidth : "100%",
                height: this.props.VlHeight ? this.props.VlHeight : "90%",
                backgroundColor: "white"
            }}>
                <CanvasJSChart options = {this.canvasOptions()}
                               onRef={ref => this.chart = ref}
                               containerProps={{width: "100%", height: "100%"}}
                />
            </div>
        );
    }
}

ErrorsChart.propTypes = {
    Errors: PropTypes.object,
    VlWidth: PropTypes.string,
    VlHeight: PropTypes.string,
}
