import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import "./styles/sidebar.css"
import "./styles/container.css"
import "./styles/component.css"
import Titlebar from "./ui/components/general/titlebar";
import {hideLastError} from "./actions/errors";
import ErrorDialog from "./ui/components/dialogs/error";

class PrivateRoute extends React.Component {

    error = () => {
        if (this.props.errors.length === 0) return <></>
        return <ErrorDialog Callback={this.props.hideLastError} Text={this.props.errors[0]}/>
    }

    render() {

        let { component: Component, authenticated: Auth, verified: Verified, ...rest } = this.props
        return (
            <Route
                {...rest}
                render={props =>
                    Auth === true && Verified === true ? (
                        <div className="vl_background">
                            <Titlebar Title={rest.title}/>
                            <Component {...props}/>
                            {this.error()}
                        </div>
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
                }
            />
        );
    }
}

const mapStateToRouteProps = state => ({

    authenticated: state.auth.authenticated,
    verified: state.auth.verified,
    errors: state.errors.errors
})

export default connect(mapStateToRouteProps, {hideLastError})(PrivateRoute);