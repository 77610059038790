import React from 'react'
import "../../../styles/panels/devices/device.css"
import InfoButton from "../../buttons/info"
import IntensityButton from "../../buttons/intensity"
import RemoveButton from "../../buttons/remove"
import Shifter from "../../layout/shifter"
import PropTypes from "prop-types";
import IntensityBox from "../../boxes/intensitybox";
import {connect} from "react-redux";
import {dragDevice} from "../../../../actions/places";
import CheckBox from "../../boxes/checkbox";
import {deviceIsUsed, selectDevice} from "../../../../utils/map/devices";
import Lottie from "react-lottie-player";
import loadingJson from "../../../animations/IntensityLoading.json";
import NameEditBox from "../../boxes/nameeditbox";
import EditButton from "../../buttons/edit";
import {nameFromDevice} from "../../../../utils/devices";

class Device extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            intensityBoxOpened: false,
            intensity: this.props.Device.intensity,
            opened: false,
            name_edit: false,
            name_edit_error: "",
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.Device.intensity !== prevProps.Device.intensity) {
            this.setState({...this.state, intensity: this.props.Device.intensity});
        }
    }

    deviceData = () => {

        return [
            { title: "ID", value: this.props.Device.id, general: true },
            { title: "Intensity", value: this.props.Device.intensity, general: true },
            { title: "On hours", value: this.props.Device.details.on_hours, general: true },
            { title: "Power", value: this.props.Device.details.power, general: true },
        ];
    }

    deviceDetailsData = () => {

        if (!this.props.Device.details?.detail_list) return [];
        return Object.keys(this.props.Device.details.detail_list).map(detail => {
            return { title: detail, value: this.props.Device.details.detail_list[detail] }
        })
    }

    triggerIntensityBox = () => {

        this.setState({...this.state, intensityBoxOpened: !this.state.intensityBoxOpened});
    }

    changeIntensity = intensity => {

        this.setState({...this.state, intensity: intensity});
    }

    editCallback = () => {

        this.props.EditCallback(this.props.Device);
    }

    startDrag = e => {

        this.props.dragDevice(this.props.Device);
    }

    stopDrag = e => {

        this.props.dragDevice(null);
        e.preventDefault();
    }

    addOnClasses = () => {

        let result = "";
        if (this.props.selectedDevice && this.props.selectedDevice.id === this.props.Device.id) {
            result += " vl_device_active";
        } else if(this.props.Device.status !== "OK") {
            result += " vl_device_error";
        }
        return result
    }

    checkBox = () => {

        if (!this.props.CheckCallback) return "";
        return (
            <div className="vl_device_check_box_container">
                <CheckBox Callback={() => {this.props.CheckCallback(this.props.Device.id)}}
                          Checked={this.props.Checked}
                />
            </div>
        )
    }

    changeName = newName => {

        let error = null;
        if (newName.length < 3) {
            error = "Name is too short";
        } else {
            this.props.UpdateDevice({...this.props.Device, mnemonic: newName});
        }
        this.setState({
            ...this.state,
            name_edit_error: error,
            name_edit: error && error.length > 0
        });
    }

    name = () => {

        if (this.state.name_edit)
            return <NameEditBox VlClass="vl_device_name_edit"
                                VlEditClass="vl_device_name_edit_input"
                                VlErrorClass="vl_device_name_edit_error"
                                Error={this.state.name_edit_error}
                                Change={this.changeName}
                                Cancel={() => {this.setState({...this.state, name_edit: false, name_edit_error: ""})}}
                                Default={nameFromDevice(this.props.Device)}/>

        return (
            <h5 className="vl_device_name"
                onClick={() => {selectDevice(this.props.Device.id)}}
                onDoubleClick={() => {this.setState({...this.state, name_edit: true})}}>
                {nameFromDevice(this.props.Device)}
            </h5>
        )
    }

    generalInfo = () => {

        return (
            <>
                <p className={this.props.Device.status !== "OK" ? "vl_device_status_error" : "vl_device_status_ok"}>
                    {this.props.Device.status !== "OK" ? this.props.Device.status : ""}
                </p>
                <div className="vl_device_info_container">
                    {this.name()}
                    <span className="vl_device_info">{this.props.Device.info1 ? this.props.Device.info1 : ""}</span>
                    <span className="vl_device_info">{this.props.Device.info2 ? this.props.Device.info2 : ""}</span>
                </div>
            </>
        )
    }

    editButton = () => {

        if (!this.props.ChangeIntensity) return <></>;
        let editDisplay = (this.state.opened || this.state.intensityBoxOpened) ? "none" : ""
        return (
            <>
                <EditButton HideText
                            VlStyle={{display: editDisplay, position: "relative", top: "13px"}}
                            Callback={this.editCallback}/>
            </>
        )
    }

    intensityButton = () => {

        if (!this.props.ChangeIntensity) return <></>;
        let intensityDisplay = this.state.opened ? "none" : ""
        return (
            <>
                <Shifter VlStyle={{display: intensityDisplay}}
                         Size="15px"/>
                <IntensityButton VlStyle={{display: intensityDisplay}}
                                 Callback={this.triggerIntensityBox}/>
            </>
        )
    }

    removeButton = () => {

        if (!this.props.DeleteCallback) return <></>;
        let removeDisplay = (this.state.opened || this.state.intensityBoxOpened) ? "none" : ""
        return (
            <>
                <Shifter VlStyle={{display: removeDisplay}}
                         Size="15px"/>
                <RemoveButton VlStyle={{display: removeDisplay}}
                              Permanent={!deviceIsUsed(this.props.Device)}
                              Callback={() => {
                                  this.props.DeleteCallback(
                                      this.props.Device,
                                      true // isDevice
                                  )
                              }}
                />
            </>
        )
    }

    buttons = () => {

        let infoDisplay = this.state.intensityBoxOpened ? "none" : ""
        return (
            <div className="vl_device_buttons">
                <InfoButton VlStyle={{display: infoDisplay}} Callback={() => {
                    this.setState({...this.state, opened: !this.state.opened})
                }} />
                {
                    this.props.CheckCallback ? "" :
                    <>
                        {this.editButton()}
                        {this.intensityButton()}
                        {this.removeButton()}
                    </>
                }
            </div>
        )
    }

    intensityBox = () => {

        if (!this.state.intensityBoxOpened || this.props.IntensityProgress) return "";
        return <IntensityBox Callback={intensity => this.changeIntensity(intensity)}
                             StopCallback={() => {
                                 if (this.props.ChangeIntensity)
                                     this.props.ChangeIntensity(this.state.intensity, this.props.Device);
                             }}
                             VlStyle={{position: "absolute", right: "3vw", top: "5%", boxShadow: "none", backgroundColor: "transparent"}}
                             Intensity={this.state.intensity}
                             Horizontal/>
    }

    infoEntry = (item, index) => {

        return (
            <tr key={index} className="vl_device_info_table_row">
                <td className={"vl_device_info_table_item_title" + (item.general ? " vl_device_info_table_general_entry" : "")}>
                    {item.title}
                </td>
                <td className={"vl_device_info_table_item_value" + (item.general ? " vl_device_info_table_general_entry" : "")}>
                    {item.value}
                </td>
            </tr>
        )
    }

    info = () => {

        if (!this.state.opened) return "";
        return (
            <div className="vl_device_info_table_container">
                <table className="vl_device_info_table">
                    <tbody>
                        {this.deviceData().map((item, index) => this.infoEntry(item, index))}
                        {this.deviceDetailsData().map((item, index) => this.infoEntry(item, index))}
                    </tbody>
                </table>
            </div>
        )
    }

    loading = () => {

        if (!this.props.IntensityProgress) return <></>
        return (
            <div className="vl_device_loading_container">
                <Lottie
                    loop
                    goTo={0}
                    animationData={loadingJson}
                    speed={2}
                    play={true}
                    style={{
                        width: "400px",
                        height: "200px",
                        position: "absolute",
                        top: "-83px",
                        left: "-125px",

                    }}
                />
            </div>
        )
    }

    render() {

        let deviceClass = (this.state.opened ? "vl_device_opened" : "vl_device") + this.addOnClasses();
        return (
            <div className="vl_device_container" disabled={this.props.IntensityProgress}>
                {this.checkBox()}
                <div className={this.props.VlClass || deviceClass}
                     style={this.props.VlStyle || {}}
                     onDragStart={this.startDrag}
                     onDragEnd={this.stopDrag}
                     draggable>
                    {this.generalInfo()}
                    {this.buttons()}
                    {this.intensityBox()}
                    {this.info()}
                    {this.loading()}
                </div>
            </div>
        )
    }
}

Device.propTypes = {
    DeleteCallback: PropTypes.func,
    ChangeIntensity: PropTypes.func,
    UpdateDevice: PropTypes.func,
    CheckCallback: PropTypes.func,
    EditCallback: PropTypes.func,

    Checked: PropTypes.bool,
    IntensityProgress: PropTypes.bool,
    Device: PropTypes.object,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}

const mapStateToOverviewProps = state => ({
    selectedDevice: state.areas.selectedDevice,
})

export default connect(mapStateToOverviewProps, {
    dragDevice,
})(Device);