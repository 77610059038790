import React from 'react'
import "../../styles/panels/notification.css"
import {NotificationTypes} from "../../../utils/notifications";
import PropTypes from "prop-types";
import {timestampToTimePassed} from "../../../utils/time";

export default class NotificationPanel extends React.Component {

    iconFromType = () => {

        switch (this.props.Type) {

            case NotificationTypes.Info:
                return <p className="vl_notification_panel_info_icon" />
            case NotificationTypes.Warning:
                return <p className="vl_notification_panel_warning_icon" />
            case NotificationTypes.Error:
                return <p className="vl_notification_panel_error_icon" />
            default:
                return <p />
        }
    }

    body = () => {

        if (this.props.Opened) {
            return (
                <div className="vl_notification_panel_body" onClick={this.props.Select}>
                    <p className="vl_notification_panel_header_opened">{this.props.Header}</p>
                    <p className="vl_notification_panel_text">{this.props.Text}</p>
                    <p className="vl_notification_panel_time">{timestampToTimePassed(this.props.Time) + " ago"}</p>
                </div>
            )
        }

        return (
            <div className="vl_notification_panel_body" onClick={this.props.Select}>
                <p className="vl_notification_panel_header">{this.props.Header}</p>
                <p className="vl_notification_panel_time">{timestampToTimePassed(this.props.Time) + " ago"}</p>
            </div>
        )
    }

    containerClass = () => {

        if (!this.props.Opened) return "vl_notification_panel_container";
        return this.props.Text.length > 110 ? "vl_notification_panel_container_extended_big" : "vl_notification_panel_container_extended";
    }

    render() {

        return (
            <div className={this.containerClass()}>
                <div className="vl_notification_panel_icon_container">{this.iconFromType()}</div>
                {this.body()}
                <div className="vl_notification_panel_underline"/>
            </div>
        )
    }
}

NotificationPanel.propTypes = {
    Select: PropTypes.func,
    Header: PropTypes.string.isRequired,
    Text: PropTypes.string.isRequired,
    Type: PropTypes.number,
    Time: PropTypes.number,
    Opened: PropTypes.bool,
}
