import React from 'react'
import "../../styles/map/controls.css"
import SelectBox from "../boxes/selectbox";
import AddButton from "../buttons/add";
import PropTypes from "prop-types";
import Shifter from "../layout/shifter";
import AddMapDialog from "../dialogs/add_map"
import VlButton from "../buttons/vl_button";

export default class MapControls extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addMapDialogOpened: false,
            error: "",
        }
    }

    triggerMapDialog = edit => {

        this.setState({
            ...this.state,
            addMapDialogOpened: !this.state.addMapDialogOpened,
            addMapDialogEditMode: edit || false,
            error: "",
        });
    }

    mapSelected = value => {

        this.props.MapSelected(this.props.Maps.filter(m => String(m.Value) === String(value))[0].Name);
    }

    areaChanged = value => {

        this.props.AreaSelected(this.props.Areas.find(a => String(a.Value) === String(value)));
    }

    mapAdded = (file, name, bounds) => {

        if (name.length === 0) {
            this.setState({...this.state, error: "Name must be non empty"});
        } else if (this.props.Maps.filter(map => map.Name === name).length > 0) {
            this.setState({...this.state, error: "Place named " + name + " already exists"});
        } else {
            this.props.MapCreated(file, name, bounds);
            this.triggerMapDialog();
        }
    }

    mapChanged = (file, name, bounds) => {

        if (name.length === 0) {
            this.setState({...this.state, error: "Name must be non empty"});
        } else if (name !== this.props.SelectedMap.Name && this.props.Maps.filter(map => map.Name === name).length > 0) {
            this.setState({...this.state, error: "Place named " + name + " exists"});
        } else {
            this.props.MapEdited(file, name, bounds);
            this.triggerMapDialog();
        }
    }

    selectedMap = () => {

        if (this.props.SelectedMap) {
            return this.props.SelectedMap;
        }
        if (this.props.Maps && this.props.Maps.length > 0) {
            return this.props.Maps[0];
        }
        return null;
    }

    selectedArea = () => {

        if (this.props.SelectedArea) {
            return this.props.SelectedArea;
        }
        if (this.props.Areas && this.props.Areas.length > 0) {
            return this.props.Areas[0];
        }
        return null;
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_controls_container"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_controls_places">
                    <SelectBox Callback={this.mapSelected}
                               Cases={this.props.Maps}
                               Selected={this.selectedMap()}
                    />
                    <Shifter Size="2.5rem"/>
                    <SelectBox Callback={this.areaChanged}
                               Cases={this.props.Areas}
                               Selected={this.selectedArea()}
                    />
                </div>
                <div className="vl_controls_maps">
                    <AddButton Text={"Add Map"} Callback={() => this.triggerMapDialog()}/>
                    <VlButton VlClass="vl_controls_maps_edit_button"
                              Style="empty"
                              Text="Edit Map"
                              Callback={() => this.triggerMapDialog(true)}/>
                </div>
                {
                    this.state.addMapDialogOpened ?
                    <AddMapDialog Callback={this.state.addMapDialogEditMode ? this.mapChanged : this.mapAdded}
                                  CloseCallback={() => this.triggerMapDialog()}
                                  Plan={this.state.addMapDialogEditMode ? this.props.Plans[this.props.SelectedMap.Name] : ""}
                                  Name={this.state.addMapDialogEditMode ? this.props.SelectedMap.Name : ""}
                                  Image={this.state.addMapDialogEditMode ? this.props.Plans[this.props.SelectedMap.Name] : ""}
                                  Bounds={this.state.addMapDialogEditMode ? this.props.Places.find(p => p.name === this.props.SelectedMap.Name)?.bounds : {}}
                                  Error={this.state.error}
                    /> : ""
                }
            </div>
        )}
}

MapControls.propTypes = {
    MapCreated: PropTypes.func.isRequired,
    MapEdited: PropTypes.func.isRequired,
    MapSelected: PropTypes.func.isRequired,
    AreaSelected: PropTypes.func.isRequired,

    Maps: PropTypes.array.isRequired,
    Areas: PropTypes.array.isRequired,
    Plans: PropTypes.object.isRequired,
    Places: PropTypes.array.isRequired,
    SelectedMap: PropTypes.object,
    SelectedArea: PropTypes.object,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
