import React from 'react'
import "../../styles/cards/log_card.css"
import PropTypes from "prop-types";
import {timestampToFullDateAndTime} from "../../../utils/time";

export default class LogCard extends React.Component {

    colorFromLog = () => {

        switch(this.props.Log.service_name) {
            case "web":
                return " vl_log_card_blue";
            case "server":
                return " vl_log_card_orange";
            case "db":
                return " vl_log_card_red";
            default:
                return " vl_log_card_grey";
        }
    }

    selected = () => {

        return this.props.Log.Selected ? " vl_log_card_selected" : "";
    }

    render() {
        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_log_card" + this.colorFromLog() + this.selected()}
                 style={this.props.VlStyle ? this.props.VlStyle : {}} onClick={this.props.Callback}>
                <p className="vl_log_number_and_service">{this.props.Log.service_name}</p>
                <p className="vl_log_time">{timestampToFullDateAndTime(this.props.Log.time)}</p>
                <p className="vl_log_text">{this.props.Log.text}</p>
            </div>
        )
    }
}

LogCard.propTypes = {
    Log: PropTypes.object.isRequired,
    Callback: PropTypes.func,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
