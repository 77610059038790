let client = null;

let setSceneProgress = () => {};
let setIntensityProgress = () => {};
let setDeviceIntensityProgress = () => {};
let addLog = () => {};
let getUnreadNotifications = () => {};
let getGroups = () => {};
let getDevices = () => {};
let getPlaces = () => {};
let getScenes = () => {};
let getReports = () => {};
let getHistogramMeta = () => {};
let statusChanged = () => {};

export function createWebsocketConnection(
    _authenticated,
    _setSceneProgress, _setIntensityProgress, _setDeviceIntensityProgress, _addLog, _getUnreadNotifications,
    _getGroups, _getDevices, _getPlaces, _getScenes, _getReports, _getHistogramMeta,
    _statusChanged
) {

    if (!_authenticated) return;
    if (client === null || client.readyState !== WebSocket.OPEN) {
        client = new WebSocket('ws://' + window.location.hostname + ':8080/live');

        client.onopen = onOpen;
        client.onclose = onClose;
        client.onmessage = onMessage;

        if (typeof _setSceneProgress === 'function') setSceneProgress = _setSceneProgress;
        if (typeof _setIntensityProgress === 'function') setIntensityProgress = _setIntensityProgress;
        if (typeof _setDeviceIntensityProgress === 'function') setDeviceIntensityProgress = _setDeviceIntensityProgress;
        if (typeof _addLog === 'function') addLog = _addLog;
        if (typeof _getUnreadNotifications === 'function') getUnreadNotifications = _getUnreadNotifications;
        if (typeof _getGroups === 'function') getGroups = _getGroups;
        if (typeof _getDevices === 'function') getDevices = _getDevices;
        if (typeof _getPlaces === 'function') getPlaces = _getPlaces;
        if (typeof _getScenes === 'function') getScenes = _getScenes;
        if (typeof _getReports === 'function') getReports = _getReports;
        if (typeof _getHistogramMeta === 'function') getHistogramMeta = _getHistogramMeta;
        if (typeof _statusChanged === 'function') statusChanged = _statusChanged;
    }
}

function onOpen () {

    statusChanged({connected: 1, info: "Connected"});
}

function onClose (event) {

    let reason = "Disconnected";
    if (event.code === 1000)
        reason = "Normal closure";
    else if(event.code === 1001)
        reason = "An endpoint is \"going away\"";
    else if(event.code === 1002)
        reason = "Protocol error";
    else if(event.code === 1003)
        reason = "Wrong data received";
    else if(event.code === 1005)
        reason = "Unknown reason";
    else if(event.code === 1006)
        reason = "Backend disconnected abnormally";
    else if(event.code === 1007)
        reason = "Backend received wrong message";
    else if(event.code === 1008)
        reason = "Backend message policy violated";
    else if(event.code === 1009)
        reason = "Too big message received";
    else if(event.code === 1010)
        reason = "Handshake error: " + event.reason;
    else if(event.code === 1011)
        reason = "Backend unexpected condition";
    else if(event.code === 1015)
        reason = "TLS handshake error";
    statusChanged({connected: 0, info: reason});
}

function onMessage (e) {

    if (typeof e.data === 'string') {
        let parts = e.data.split("_");
        if (parts.length > 0) {
            switch (parts[0]) {
                case 'hardware': updateHardware(); break;
                case 'scenes': updateScenes(); break;
                case 'reports': updateReports(); break;
                case 'TriggerSceneStart': setSceneProgress(true); break;
                case 'TriggerSceneEnd': setSceneProgress(false); getUnreadNotifications(); break;
                case 'GroupIntensityStart': groupIntensityStart(parts); break;
                case 'GroupIntensityEnd': groupIntensityEnd(parts); break;
                case 'DeviceIntensityStart': deviceIntensityStart(parts); break;
                case 'DeviceIntensityEnd': deviceIntensityEnd(parts); break;
                case 'DeviceTestingStart': deviceTestingStart(parts); break;
                case 'DeviceTestingEnd': deviceTestingEnd(parts); break;
                default: logOrNotification(e.data); break;
            }
        }
    }
}

function updateHardware() {

    getGroups();
    getDevices();
    getPlaces();
}

function updateScenes() {

    getScenes();
}

function updateReports() {

    getReports();
    getHistogramMeta();
}

function groupIntensityStart(parts) {

    if (parts.length < 3) return;
    let group = {area_id: parts[1], group_id: parts[2]};
    setIntensityProgress(group);
}

function groupIntensityEnd(parts) {

    if (parts.length < 3) return;
    let group = {area_id: parts[1], group_id: parts[2]};
    setIntensityProgress(group, true);
}

function deviceIntensityStart(parts) {

    let deviceId = parts.reduce((result, part, index) => {
        if (index === 0) return result;
        return result + (result.length > 0 ? "_" : "") + part;
    }, "");
    setDeviceIntensityProgress(deviceId);
}

function deviceIntensityEnd(parts) {

    let deviceId = parts.reduce((result, part, index) => {
        if (index === 0) return result;
        return result + (result.length > 0 ? "_" : "") + part;
    }, "");
    setDeviceIntensityProgress(deviceId);
}

function deviceTestingStart(parts) {

    console.log("## Device Testing Start: ", parts);
}

function deviceTestingEnd(parts) {

    console.log("## Device Testing End: ", parts);
}

function logOrNotification(data) {

    if(data.startsWith('LOG:')) {
        addLog(data.substr(4));
    } else {
        getUnreadNotifications();
    }
}