import L from "leaflet";

let polygonsIntersect = require('polygons-intersect');

export function layerOfPoint(point, layers) {

    return layers.find(layer => pointIsInsidePolygon(point, layer.getLatLngs()[0]));
}

export function arePolygonsIntersecting(poly1, poly2) {

    let polygon1 = poly1.getLatLngs()[0].map(pos => {return {x: pos.lat, y: pos.lng}});
    let polygon2 = poly2.getLatLngs()[0].map(pos => {return {x: pos.lat, y: pos.lng}});

    return polygonsIntersect(polygon1, polygon2).length > 0;
}

export function pointIsInsidePolygon(pos, polyPoints) {

    let inside = false;
    let x = pos.lat, y = pos.lng;
    for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
        let xi = polyPoints[i].lat, yi = polyPoints[i].lng;
        let xj = polyPoints[j].lat, yj = polyPoints[j].lng;

        let intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
}

export function selectLayer(allLayers, layer, defaultStyle, style) {

    allLayers.forEach(l => {
        if (l._leaflet_id === layer?._leaflet_id) {
            l.setStyle(style);
        } else {
            l.setStyle(defaultStyle);
        }
    });
}