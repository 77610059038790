import React from 'react'
import "../../styles/menus/notifications_menu.css"
import PropTypes from "prop-types";
import {connect} from "react-redux";
import NotificationPanel from "../panels/notification";
import Shifter from "../layout/shifter";

class NotificationsMenu extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            selected: -1,
        }
    }

    notificationSelected = id => {

        if (this.state.selected !== id) {
            this.setState({...this.state, selected: id});
        } else {
            this.setState({...this.state, selected: -1});
        }
    }

    notifications = () => {

        if (this.props.notifications.length === 0) {
            return <p className="vl_notifications_menu_info">No Notifications</p>
        }
        return (
            <div className="vl_notifications_menu_container">
                {
                    this.props.notifications.map(notification => {
                        return <NotificationPanel key={notification.id}
                                                  Select={() => this.notificationSelected(notification.id)}
                                                  Header={notification.header}
                                                  Text={notification.text}
                                                  Type={notification.type}
                                                  Time={notification.time}
                                                  Opened={this.state.selected === notification.id}
                        />
                    })
                }
            </div>
        )
    }

    render() {

        return (
            <div
                className={this.props.VlClass ? this.props.VlClass : "vl_notifications_menu"}
                style={this.props.VlStyle ? this.props.VlStyle : {}}>
                {
                    this.props.notifications.length === 0 ?
                    <div className="vl_notifications_menu_header_container"/> : <Shifter Vertical Size="10px"/>
                }
                {this.notifications()}
            </div>
        )
    }
}

NotificationsMenu.propTypes = {
    Finished: PropTypes.func,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}

const mapStateToProfileProps = state => ({
    notifications: state.notifications.notifications
})

export default connect(mapStateToProfileProps, null)(NotificationsMenu);