import {
    CREATE_AREA,
    CREATE_GROUP,
    GET_AREAS,
    MODIFY_DEVICES_LIST,
    REMOVE_AREA,
    REMOVE_GROUP,
    UPDATE_AREA,
    UPDATE_GROUP
} from "./types";

import {showError} from "./errors";
import {_getDevices} from "./device";

export const getAreas = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/map/areas/get",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                response.json().then(areas => {

                    dispatch({
                        type: GET_AREAS,
                        areas: areas
                    });
                    _getDevices(dispatch);
                })
            } else {
                dispatch(showError("Getting areas - Response status: " + response.status));
            }
        })
        .catch(function(error){

            dispatch(showError("Getting areas - " + error));
        });
}

export const createArea = (area, deviceIds, sensorIds) => dispatch => {

    let data = {
        area: area,
        device_ids: deviceIds,
        sensorIds: sensorIds,
    }
    fetch("http://" + window.location.hostname + ":8080/map/areas/create",
        {method: 'POST', credentials: 'include', mode: 'cors', body: JSON.stringify(data)})
        .then(response => {

            if (response.status === 200) {
                dispatch({
                    type: CREATE_AREA,
                    area: area,
                    deviceIds: deviceIds,
                    sensorIds: sensorIds,
                });
            } else {
                dispatch(showError("Creating area - Response status: " + response.status));
            }
        })
        .catch(function(error){

            dispatch(showError("Creating area - " + error));
        });
}

export const updateArea = (area, deviceIds, sensorIds, onlyArea) => dispatch => {

    let data = {
        area: area,
        device_ids: deviceIds,
        sensor_ids: sensorIds,
    }
    fetch("http://" + window.location.hostname + ":8080/map/areas/update" + (onlyArea ? "?only_area" : ""),
        {method: 'PUT', credentials: 'include', mode: 'cors', body: JSON.stringify(data)})
        .then(response => {

            if (response.status === 200) {
                dispatch({
                    type: UPDATE_AREA,
                    area: area,
                    deviceIds: deviceIds,
                    sensorIds: sensorIds,
                    onlyArea: onlyArea
                });
            } else {
                dispatch(showError("Updating area - Response status: " + response.status));
            }
        })
        .catch(function(error){

            dispatch(showError("Updating area - " + error));
        });
}

export const removeArea = (area_id) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/map/areas/remove?id=" + area_id,
        {method: 'DELETE', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                dispatch({
                    type: REMOVE_AREA,
                    area_id: area_id
                });
            } else {
                dispatch(showError("Removing area - Response status: " + response.status));
            }
        })
        .catch(function(error){

            dispatch(showError("Removing area - " + error));
        });
}

export const createGroup = (area_id, group, devices, sensors) => dispatch => {

    let data = {
        area_id: area_id,
        group: group,
        device_ids: devices.map(device => device.id),
        sensor_ids: sensors.map(sensor => sensor.id),
    }
    fetch("http://" + window.location.hostname + ":8080/map/areas/group/add",
        {method: 'POST', credentials: 'include', mode: 'cors', body: JSON.stringify(data)})
        .then(response => {

            if (response.status === 200) {
                dispatch({
                    type: CREATE_GROUP,
                    area_id: area_id,
                    group: group,
                    devices: devices,
                    sensors: sensors,
                })
            } else {
                dispatch(showError("Creating group - Response status: " + response.status));
            }
        })
        .catch(function(error){

            dispatch(showError("Creating group - " + error));
        });
}

export const updateGroup = (area_id, group, devices, sensors, onlyGroup) => dispatch => {

    let data = {
        area_id: area_id,
        group: group,
        device_ids: devices.map(device => device.id),
        sensor_ids: sensors.map(sensor => sensor.id),
    }
    fetch("http://" + window.location.hostname + ":8080/map/areas/group/add" + (onlyGroup ? "?only_group" : ""),
        {method: 'POST', credentials: 'include', mode: 'cors', body: JSON.stringify(data)})
        .then(response => {

            if (response.status === 200) {
                dispatch({
                    type: UPDATE_GROUP,
                    area_id: area_id,
                    group: group,
                    devices: devices,
                    sensors: sensors,
                    onlyGroup: onlyGroup
                });
            } else {
                dispatch(showError("Updating group - Response status: " + response.status));
            }
        })
        .catch(function(error){

            dispatch(showError("Updating group - " + error));
        });
}

export const removeGroup = (area_id, group_id) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/map/areas/group/remove?area_id=" + area_id + "&group_id=" + group_id,
        {method: 'DELETE', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                dispatch({
                    type: REMOVE_GROUP,
                    area_id: area_id,
                    group_id: group_id
                });
            } else {
                dispatch(showError("Removing group - Response status: " + response.status));
            }
        })
        .catch(function(error){

            dispatch(showError("Removing group - " + error));
        });
};

export const setGroupIntensity = (group, area_id, intensity) => dispatch => {

    let data = {
        area_id: area_id,
        group_id: group.id,
        intensity: intensity,
    }
    fetch("http://" + window.location.hostname + ":8080/map/areas/group/intensity",
        {method: 'POST', credentials: 'include', mode: 'cors', body: JSON.stringify(data)})
        .then(response => {

            if (response.status === 200) {
                group.intensity = intensity;
                dispatch({
                    type: UPDATE_GROUP,
                    group: group,
                    area_id: area_id,
                    intensity_changed: true,
                });
            } else {
                dispatch(showError("Set group intensity - Response status: " + response.status));
            }
        })
        .catch(function(error){

            dispatch(showError("Set group intensity - " + error));
        });
}

export const modifyDevicesList = (sort_field, reverse_sort_activated, search_text) => dispatch => {

    dispatch({
        type: MODIFY_DEVICES_LIST,
        sort_field: sort_field,
        reverse_sort_activated: reverse_sort_activated,
        search_text: search_text,
    });
}