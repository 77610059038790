import React from 'react'
import "../../styles/general/titlebar.css"
import NavigationMenu from "../menus/navigation_menu";
import PropTypes from "prop-types";
import NotificationsMenu from "../menus/notifications_menu";
import UserMenu from "../menus/user_menu";
import {connect} from "react-redux";
import {signOut} from "../../../actions/auth";
import {getInfo} from "../../../actions/account";
import {getLastNotifications, markAsSeen} from "../../../actions/notifications";
import Lottie from "react-lottie-player";
import notifications from "../../animations/notifications.json";
import LiveStatus from "../tools/live_status";

class Titlebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            navigationMenu: false,
            userMenu: false,
            notificationsMenu: false,
            notificationsHovered: false,
            time: new Date(),
        }

        this.wrapperRef = React.createRef();
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    componentDidMount() {

        window.addEventListener("mousedown", this.handleOutsideClick);
        this.interval = setInterval(() => this.setState({ ...this.state, time: new Date() }), 4000);

        if (!this.props.user)
            this.props.getInfo()
        this.props.getLastNotifications()
    }

    componentWillUnmount() {

        window.removeEventListener("mousedown", this.handleOutsideClick);
        clearInterval(this.interval);
    }

    handleOutsideClick(event) {

        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.closeAll();
        }
    }

    closeAll = () => {

        this.setState({...this.state, navigationMenu: false, userMenu: false, notificationsMenu: false});
    }

    triggerNavigationMenu = () => {

        this.setState({
            ...this.state,
            navigationMenu: !this.state.navigationMenu,
            notificationsMenu: false,
            userMenu: false,
        });
    }

    triggerUserMenu = () => {

        this.setState({
            ...this.state,
            userMenu: !this.state.userMenu,
            navigationMenu: false,
            notificationsMenu: false,
        });
    }

    triggerNotificationsMenu = () => {

        this.setState({
            ...this.state,
            notificationsMenu: !this.state.notificationsMenu,
            navigationMenu: false,
            userMenu: false,
        }, () => this.props.markAsSeen());
    }

    currentTime = () => {

        return ('0'+this.state.time.getHours()).slice(-2)+':'+
            ('0'+(this.state.time.getMinutes())).slice(-2);
    }

    currentDate = () => {

        return ('0'+this.state.time.getDate()).slice(-2)+'.'+
            ('0'+(this.state.time.getMonth()+1)).slice(-2)+'.'+
            this.state.time.getFullYear();
    }

    unseenNotificationsCount = () => {

        if (!this.props.notifications) return 0;
        return this.props.notifications.filter(n => !n.seen).length;
    }

    hoverNotifications = status => {

        if (this.state.notificationsHovered !== status)
            this.setState({...this.state, notificationsHovered: status});
    }

    notificationsAnimation = () => {

        return (
            <div className="vl_notifications_animation_container">
                <Lottie
                    loop
                    goTo={0}
                    animationData={notifications}
                    play={true}
                    style={{
                        width: "30px",
                        height: "30px",
                        position: "absolute",
                        top: "-4px",
                        left: "-4px",

                    }}
                />
            </div>
        )
    }

    render() {

        return (
            <div id="vl_titlebar"  ref={this.wrapperRef}>
                <div className="vl_logo"/>
                <div className="vl_title"/>
                <div className="vl_division"/>
                <LiveStatus VlClass="vl_live_status" Status={this.props.status} />
                <p className="vl_page">{this.props.Title}</p>
                <h2 className="vl_time">{this.currentTime()}</h2>
                <h2 className="vl_date">{this.currentDate()}</h2>
                <button className={this.state.notificationsHovered ? "vl_notifications_hovered" : "vl_notifications"}
                        onClick={this.triggerNotificationsMenu}
                        onMouseEnter={() => this.hoverNotifications(true)}
                        onMouseLeave={() => this.hoverNotifications(false)}
                >
                    {this.unseenNotificationsCount() ? <div className="vl_notifications_unseen_sign"/> : <></>}
                    {this.state.notificationsHovered ? this.notificationsAnimation() : <></>}
                </button>
                <button className="vl_user" onClick={this.triggerUserMenu}/>
                <button className="vl_menu" onClick={this.triggerNavigationMenu}/>
                <NotificationsMenu VlStyle={this.state.notificationsMenu ? {} : {display: "none"}}
                                   Finished={this.closeAll}
                />
                <UserMenu VlStyle={this.state.userMenu ? {} : {display: "none"}}
                          SignOut={this.props.signOut}
                          Finished={this.closeAll}
                          User={this.props.currentUser}
                />
                <NavigationMenu VlStyle={this.state.navigationMenu ? {} : {display: "none"}}
                                Finished={this.closeAll}
                />
            </div>
        )}
}

Titlebar.propTypes = {
    Title: PropTypes.string.isRequired,
}

const mapStateToTitlebarProps = state => ({
    currentUser: state.account.currentUser,
    notifications: state.notifications.notifications,
    status: state.status.status
})

export default connect(mapStateToTitlebarProps, { signOut, getInfo, getLastNotifications, markAsSeen })(Titlebar);