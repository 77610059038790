import {
    GET_ACTIVATION_BY_DATE,
    CREATE_ACTIVATION_BY_DATE,
    UPDATE_ACTIVATION_BY_DATE,
    REMOVE_ACTIVATION_BY_DATE, SET_ACTIVE_ACTIVATION_BY_DATE
} from "../actions/types";

const initialState = {
    activations_by_date: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default  (state = initialState, action) => {

    switch(action.type) {

        case GET_ACTIVATION_BY_DATE:
            return {
                ...state,
                activations_by_date: action.activations
            }
        
        case CREATE_ACTIVATION_BY_DATE:
            return { ...state, activations_by_date: [...state.activations_by_date, action.activation] }

        case UPDATE_ACTIVATION_BY_DATE:
            return {
                ...state,
                activations_by_date: state.activations_by_date.map(a => {
                    return a.id === action.activation.id ? action.activation : a;
                })
            }

        case SET_ACTIVE_ACTIVATION_BY_DATE:
            return {
                ...state,
                activations_by_date: state.activations_by_date.map(activation => {
                    if (activation.id === action.id) return {...activation, is_active: action.active}
                    return activation;
                })
            }

        case REMOVE_ACTIVATION_BY_DATE:
            return {
                ...state,
                activations_by_date: state.activations_by_date.filter(a => {
                    return a.id !== action.activation_id;
                })
            }

        default:
            return state;
    }
}