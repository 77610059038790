// AUTH
export const SIGNING_IN = 'SIGNING_IN'
export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'
export const CLEAN_ERROR = 'CLEAN_ERROR'
export const VERIFY_CODE = 'VERIFY_CODE'
export const SEND_EMAIL = 'SEND_EMAIL'

// ACCOUNT
export const GET_INFO = 'GET_INFO'
export const SAVING_INFO = 'SAVING_INFO'
export const SAVE_INFO = 'SAVE_INFO'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const ACTIVATE_2FA = 'ACTIVATE_2FA'
export const RESET_2FA = 'RESET_2FA'

// ADMIN
export const GET_USERS = 'GET_USERS'
export const CREATE_USER = 'CREATE_USER'
export const EDIT_USER = 'EDIT_USER'
export const DELETE_USER = 'DELETE_USER'

// SCENES
export const GET_SCENES = 'GET_SCENES'
export const ADD_SCENE = 'ADD_SCENE'
export const ACTIVATE_SCENE = 'ACTIVATE_SCENE'
export const CHANGE_SCENE = 'CHANGE_SCENE'
export const SAVE_SCENE = 'SAVE_SCENE'
export const DELETE_SCENE = 'DELETE_SCENE'
export const MODIFY_SCENES_LIST = 'MODIFY_SCENES_LIST'

// ACTIVATION_BY_DATE
export const GET_ACTIVATION_BY_DATE = 'GET_ACTIVATION_BY_DATE'
export const CREATE_ACTIVATION_BY_DATE = 'CREATE_ACTIVATION_BY_DATE'
export const UPDATE_ACTIVATION_BY_DATE = 'UPDATE_ACTIVATION_BY_DATE'
export const SET_ACTIVE_ACTIVATION_BY_DATE = 'SET_ACTIVE_ACTIVATION_BY_DATE'
export const REMOVE_ACTIVATION_BY_DATE = 'REMOVE_ACTIVATION_BY_DATE'

// HARDWARE
export const GET_DETAILS = 'GET_DETAILS'
export const GET_HARDWARE_GROUPS = 'GET_HARDWARE_GROUPS'
export const UPDATE_HARDWARE_GROUP = 'UPDATE_HARDWARE_GROUP'

// AREAS
export const GET_DEVICES = 'GET_DEVICES'
export const UPDATE_DEVICE = 'UPDATE_DEVICE'
export const REMOVE_DEVICE = 'REMOVE_DEVICE'
export const GET_AREAS = 'GET_AREAS'
export const CREATE_AREA = 'CREATE_AREA'
export const UPDATE_AREA = 'UPDATE_AREA'
export const REMOVE_AREA = 'REMOVE_AREA'
export const CREATE_GROUP = 'CREATE_GROUP'
export const UPDATE_GROUP = 'UPDATE_GROUP'
export const REMOVE_GROUP = 'REMOVE_GROUP'
export const SET_GROUP_INTENSITY = 'SET_GROUP_INTENSITY'
export const SELECT_DEVICE = 'SELECT_DEVICE'
export const MODIFY_DEVICES_LIST = 'MODIFY_DEVICES_LIST'

// SENSORS
export const GET_SENSORS = 'GET_SENSORS'
export const UPDATE_SENSOR = 'UPDATE_SENSOR'
export const REMOVE_SENSOR = 'REMOVE_SENSOR'
export const GET_SENSOR_EVENTS = 'GET_SENSOR_EVENTS'
export const GET_ALL_SENSOR_EVENTS = 'GET_ALL_SENSOR_EVENTS'

// PLACES
export const GET_PLACES = 'GET_PLACES'
export const ADD_PLACE = 'ADD_PLACE'
export const REMOVE_PLACE = 'REMOVE_PLACE'
export const GET_PLAN = 'GET_PLAN'
export const UPLOAD_PLAN = 'UPLOAD_PLAN'
export const PLAN_LOADING = 'PLAN_LOADING'
export const SET_DRAGGING_DEVICE = 'SET_DRAGGING_DEVICE'

// REPORTS
export const GET_REPORTS = 'GET_REPORTS'
export const GET_HISTOGRAM_META = 'GET_HISTOGRAM_META'
export const GET_HISTOGRAM = 'GET_HISTOGRAM'
export const GENERATE_REPORT = 'GENERATE_REPORT'
export const CLEAR_GENERATED_REPORT = 'CLEAR_GENERATED_REPORT'

// PROCESS
export const GROUP_INTENSITY_CHANGING = 'GROUP_INTENSITY_CHANGING'
export const DEVICE_INTENSITY_CHANGING = 'DEVICE_INTENSITY_CHANGING'
export const SCENE_CHANGING = 'SCENE_CHANGING'

// NOTIFICATIONS
export const GET_UNREAD_NOTIFICATIONS = 'GET_UNREAD_NOTIFICATIONS'
export const GET_LAST_NOTIFICATIONS = 'GET_LAST_NOTIFICATIONS'
export const MARK_AS_SEEN = 'MARK_AS_SEEN'

// LOGS
export const ADD_LOG = 'ADD_LOG'
export const GET_SERVICES = 'GET_SERVICES'
export const GET_LOGS = 'GET_LOGS'
export const GET_SERVICE_LOGS = 'GET_SERVICE_LOGS'
export const SELECT_LOG = 'SELECT_LOG'

// ERRORS
export const ENQUEUE_ERROR = 'ENQUEUE_ERROR'
export const POP_ERROR = 'POP_ERROR'

// STATUS
export const STATUS_CHANGED = 'STATUS_CHANGED'