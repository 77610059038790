import {
    ADD_LOG, GET_LOGS, GET_SERVICE_LOGS, GET_SERVICES, SELECT_LOG
} from "../actions/types";

const initialState = {
    logs: [],
    services: [],
    serviceLogs: [],
    selectedService: "",
}

function convertStrToObject(utcStr) {

    let parts = utcStr.split('>')
    if (parts.length < 3) return {service_name: "?", time: "?", text: "?"}

    let datetime = parts[1]
    datetime = datetime.replace("/", "T")
    datetime += "Z"
    let parsedDate = new Date(datetime)
    return {
        id: 1,
        service_name: parts[0],
        time: parsedDate.getTime() / 1000,
        text: parts[2]
    }
}

function selectLog(logs, id) {

    return logs.map(log => {
        if (id === -1)
            return {...log, selected: false}
        if (id === -2)
            return {...log, selected: true}
        if (log.id === id)
            return {...log, selected: !log.selected}
        return log
    })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {

    switch(action.type) {

        case ADD_LOG:
            let logObject = convertStrToObject(action.log)
            if (state.logs.length > 0) {
                logObject.id = state.logs[0].id + 1;
            }
            let newLogs = [logObject, ...state.logs];
            if (logObject.service_name === state.selectedService) {

                let lastId = state.serviceLogs.length > 0 ? state.serviceLogs[0].id + 1 : 0;
                let newServiceLogs = [{...logObject, id: lastId}, ...state.serviceLogs];
                return { ...state, logs: newLogs.slice(0, 1000), serviceLogs: newServiceLogs.slice(0, 1000) };
            }
            return { ...state, logs: newLogs.slice(0, 1000) }

        case GET_SERVICES:
            return { ...state, services: action.services }

        case GET_LOGS:
            return {
                ...state,
                logs: action.logs.map((log, index) => {
                    return {...log, id: (action.logs.length - index)}
                })
            }

        case GET_SERVICE_LOGS:
            return {
                ...state,
                serviceLogs: action.logs.map((log, index) => {
                    return {...log, id: (action.logs.length - index)}
                }),
                selectedService: action.service,
            }

        case SELECT_LOG:
            if (action.serviceSelected) {
                return {
                    ...state,
                    serviceLogs: selectLog(state.serviceLogs, action.id)
                }
            }
            return {
                ...state,
                logs: selectLog(state.logs, action.id)
            }

        default:
            return state;
    }
}