import React from 'react'
import "../../styles/lists/groups_list.css"
import PropTypes from "prop-types";
import Group from "../panels/devices/group"

export default class GroupsList extends React.Component {

    unusedDevicesComponent = () => {

        if (!this.props.UnusedDevices || this.props.UnusedDevices.length === 0) return <></>
        return (
            <Group key={-1}
                   Type={"unused"}
                   VlStyle={{...this.props.VlAreaStyle, border: "3px dotted lightcoral"}}
                   Group={{name: "Devices without group"}}
                   UnusedDevices={this.props.UnusedDevices}
                   UnusedSensors={this.props.UnusedSensors}
                   ChangeIntensity={this.props.ChangeIntensity}
                   DeleteCallback={this.props.DeleteCallback}
            />
        )
    }

    updateGroupName = (group, newName, areaId) => {

        if (newName.length < 3) {
            return "Name is too short";
        }
        if (this.props.Groups.find(group => group.name === newName)) {
            return "Name is already in use";
        }
        group.name = newName;
        this.props.UpdateCallback(areaId, group, [], true);
        return "";
    }

    componentFromGroup = group => {

        if (this.props.Select) {
            return <Group key={group.id}
                          ChangeIntensity={this.props.ChangeIntensity}
                          CheckCallback={() => this.props.Select(group.id)}
                          Checked={group.selected}
                          Group={group}
                          Type={"select"}/>
        }
        return <Group key={group.id}
                      IntensityProgress={!!this.props.GroupsIntensityProgress.find(g => g.group_id === String(group.id))}
                      DevicesIntensityProgress={this.props.DevicesIntensityProgress}
                      ChangeIntensity={this.props.ChangeIntensity}
                      DeleteCallback={this.props.DeleteCallback}
                      EditCallback={this.props.EditCallback}
                      EditDeviceCallback={this.props.EditDeviceCallback}
                      UpdateNameCallback={this.updateGroupName}
                      UpdateDevice={this.props.UpdateDevice}
                      AreaId={this.props.AreaId}
                      Group={group}
                      SelectedDevice={this.props.SelectedDevice}/>
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_groups_list"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}
                 disabled={this.props.Disabled}
            >
                { this.unusedDevicesComponent() }
                { this.props.Groups.map(group => this.componentFromGroup(group)) }
            </div>
        )
    }
}

GroupsList.propTypes = {
    Groups: PropTypes.array.isRequired,
    AreaId: PropTypes.number,
    UnusedSensors: PropTypes.array,
    UnusedDevices: PropTypes.array,
    SelectedDevice: PropTypes.object,
    GroupsIntensityProgress: PropTypes.array,
    DevicesIntensityProgress: PropTypes.array,

    ChangeIntensity: PropTypes.func,
    DeleteCallback: PropTypes.func,
    EditCallback: PropTypes.func,
    EditDeviceCallback: PropTypes.func,
    UpdateCallback: PropTypes.func,
    UpdateDevice: PropTypes.func,
    Select: PropTypes.func,

    Disabled: PropTypes.bool,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
