import {timestampToYear, timestampToYearAndMonth} from "./time";

export function reportsDiffer (reports_1, reports_2) {

    if (
        reports_1.months_by_day.length !== reports_2.months_by_day.length ||
        reports_1.years_by_month.length !== reports_2.years_by_month.length
    ) return true;

    for (let i = 0; i < reports_1.months_by_day.length; i++) {
        if (reports_1.months_by_day[i].power_list && reports_2.months_by_day[i].power_list) {
            if (reports_1.months_by_day[i].power_list.length !== reports_2.months_by_day[i].power_list.length) return true;
        } else {
            if (reports_1.months_by_day[i].power_list || reports_2.months_by_day[i].power_list) return true;
        }
    }

    for (let i = 0; i < reports_1.years_by_month.length; i++) {
        if (reports_1.years_by_month[i].power_list && reports_2.years_by_month[i].power_list) {
            if (reports_1.years_by_month[i].power_list.length !== reports_2.years_by_month[i].power_list.length) return true;
        } else {
            if (reports_1.years_by_month[i].power_list || reports_2.years_by_month[i].power_list) return true;
        }
    }

    return false;
}

export function yearsFromReports(reports, area_id, group_id) {

    let groupData = [];
    if (reports.years_by_month)
        groupData = reports.years_by_month.filter(report => report.area_id === area_id && report.group_id === group_id);
    else
        groupData = reports.filter(report => report.area_id === area_id && report.group_id === group_id);
    if (groupData.length === 0) return [];

    return groupData[0].power_list.reduce((result, power) => {
        if (power.period > 0) {
            let year = timestampToYear(power.period);
            if (result.filter(y => y.Name === year).length === 0) result.push({Name: year, Value: power.period});
        }
        return result;
    }, [])
}

export function monthsFromReports(reports, area_id, group_id) {

    let groupData = [];
    if (reports.months_by_day)
        groupData = reports.months_by_day.filter(report => report.area_id === area_id && report.group_id === group_id);
    else
        groupData = reports.filter(report => report.area_id === area_id && report.group_id === group_id);
    if (groupData.length === 0) return [];

    return groupData[0].power_list.reduce((result, power) => {
        if (power.period > 0) {
            let yearAndMonth = timestampToYearAndMonth(power.period);
            if (result.filter(y => y.Name === yearAndMonth).length === 0)
                result.push({Name: yearAndMonth, Value: power.period});
        }
        return result;
    }, [])
}

export function monthlyDataFromReports(reports, area_id, group_id, month_timestamp) {

    if (!reports) { return {...Data} }
    let selected_power = reports.filter(power => power.area_id === area_id && power.group_id === group_id);
    if (selected_power.length === 0) { return {...Data} }

    let data = selected_power[0].power_list.filter(power => {
        let date = new Date(power.period * 1000);
        let selected_date = new Date(month_timestamp * 1000);
        return date.getFullYear() === selected_date.getFullYear() && date.getMonth() === selected_date.getMonth();
    })
    if (data.length === 0) { return {...Data} }
    return data[0];
}

export function yearlyDataFromReports(reports, area_id, group_id, year_timestamp) {

    if (!reports) { return {...Data} }
    let selected_power = reports.filter(power => power.area_id === area_id && power.group_id === group_id);
    if (selected_power.length === 0) { return {...Data} }

    let data = selected_power[0].power_list.filter(power => {
        let date = new Date(power.period * 1000);
        let selected_date = new Date(year_timestamp * 1000);
        return date.getFullYear() === selected_date.getFullYear();
    })
    if (data.length === 0) { return {...Data} }
    return data[0];
}

export function monthlyAverageDataFromReports(reports, area_id, group_id) {

    let result = {...Data};
    let months = monthsFromReports(reports, area_id, group_id);
    for (let month of months) {
        let monthData = monthlyDataFromReports(reports, area_id, group_id, month.Value);
        result = sum(result, monthData);
    }
    return part(result, months.length);
}

export function yearlyAverageDataFromReports(reports, area_id, group_id) {

    let result = {...Data};
    let years = yearsFromReports(reports, area_id, group_id);
    for (let year of years) {
        let yearData = yearlyDataFromReports(reports, area_id, group_id, year.Value);
        result = sum(result, yearData);
    }
    return part(result, years.length);
}

export function allDataFromReports(reports, area_id, group_id) {

    let result = {...Data};
    let selected_power = reports.filter(power => power.area_id === area_id && power.group_id === group_id);

    if (selected_power.length > 0) {
        for (let power of selected_power[0].power_list) {
            result = sum(result, power)
        }
    }
    return result
}

function sum(result, data) {

    if (data.intervals !== undefined) {
        for (let i = 0; i < data.intervals.length; i++) {
            result.total_power += data.intervals[i].power;
        }
    }
    result.on_hours = Math.max(data.on_hours, result.on_hours);
    result.total_availability_errors += data.total_availability_errors;
    result.total_led_load_errors += data.total_led_load_errors;
    result.total_input_voltage_errors += data.total_input_voltage_errors;
    result.total_temperature_errors += data.total_temperature_errors;
    result.total_sync_intensity_errors += data.total_sync_intensity_errors;
    result.total_errors += data.total_errors;
    return toFixedTwoDecimal(result);
}

function part(result, number) {

    if (number === 0) return result;
    result.total_power /= number;
    result.on_hours /= number;
    result.total_availability_errors /= number;
    result.total_led_load_errors /= number;
    result.total_input_voltage_errors /= number;
    result.total_temperature_errors /= number;
    result.total_sync_intensity_errors /= number;
    result.total_errors /= number;
    return toFixedTwoDecimal(result);
}

function toFixedTwoDecimal(data) {

    data.total_power = (Math.round(data.total_power * 100) / 100).toFixed(2);
    data.on_hours = (Math.round(data.on_hours * 100) / 100).toFixed(2);

    return data
}

const Data = {
    total_power: 0,
    on_hours: 0,
    total_availability_errors: 0,
    total_led_load_errors: 0,
    total_input_voltage_errors: 0,
    total_temperature_errors: 0,
    total_sync_intensity_errors: 0,
    total_errors: 0,
}