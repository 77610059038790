import React from 'react'
import "../../styles/boxes/searchbox.css"
import PropTypes from "prop-types";

export default class SearchBox extends React.Component {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.state = { text: "" };
    }

    textChanged = e => {
        this.setState({...this.state, text: e.target.value}, () => {
            this.props.Callback(this.state.text);
        })
    }

    render() {
        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_search_container"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <input type="text"
                       className="vl_search_input"
                       onChange={this.textChanged}
                       ref={this.inputRef}
                />
                <span className="vl_search_icon"
                      style={this.state.text.length > 0 ? {display: "none"} : {}}
                      onClick={() => {this.inputRef.current.focus()}}
                />
                <span className="vl_placeholder"
                      style={this.state.text.length > 0 ? {display: "none"} : {}}
                      onClick={() => {this.inputRef.current.focus()}}>
                    Search
                </span>
            </div>
        )
    }
}

SearchBox.propTypes = {
    Callback: PropTypes.func.isRequired,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
