export const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];

export function timestampToYear (timestamp) {

    let date = new Date(timestamp * 1000);
    return date.getFullYear();
}

export function timestampToYearAndMonth (timestamp) {

    let date = new Date(timestamp * 1000);
    let month = date.getMonth()
    let year = date.getFullYear()

    return year + "-" + months[month];
}

export function timestampToFullDate (timestamp) {

    let date = new Date(timestamp * 1000);
    return date.getFullYear() + "-" +
        ("0" + (date.getMonth() + 1)).substr(-2) + "-" +
        ("0" + date.getDate()).substr(-2);
}

export function fullDatesForMonthOfTimestamp (timestamp) {

    let date = new Date(timestamp * 1000);
    let result = [];
    const daysCount = countOfDaysInMonthOfTimestamp(timestamp);
    for(let i = 1; i <= daysCount; i++) {
        result.push(date.getFullYear() + "-" +
            ("0" + (date.getMonth() + 1)).substr(-2) + "-" +
            ("0" + i).substr(-2));
    }
    return result;
}

export function fullDateToTimestamp (fullDate) {

    const words = fullDate.split('-');
    if (words.length < 3) return -1;
    return new Date(parseInt(words[0]), parseInt(words[1]) - 1, parseInt(words[2])).getTime();
}

export function countOfDaysInMonthOfTimestamp (timestamp) {

    let date = new Date(timestamp * 1000);
    return new Date(date.getFullYear(), date.getMonth(), 0).getDate();
}

export function fullDatesInBetweenTimestamps (start, end) {

    if (start < 0) {
        let endDate = new Date(end * 1000);
        return Array.from(Array(endDate.getDate()).keys()).map(value => {
            return endDate.getFullYear() + "-" +
                ("0" + (endDate.getMonth() + 1)).substr(-2) + "-" +
                ("0" + (1 + value)).substr(-2);
        });
    }

    let startDate = new Date(start * 1000);
    let endDate = new Date(end * 1000);
    if (startDate.getFullYear() !== endDate.getFullYear() ||
        startDate.getMonth() !== endDate.getMonth() ||
        startDate.getDate() >= endDate.getDate()
    ) return [];
    return Array.from(Array(endDate.getDate() - startDate.getDate()).keys()).map(value => {
        return startDate.getFullYear() + "-" +
            ("0" + (startDate.getMonth() + 1)).substr(-2) + "-" +
            ("0" + (startDate.getDate() + 1 + value)).substr(-2);
    });
}

export function timestampToTime (timestamp) {

    let date = new Date(timestamp * 1000);
    return ("0" + date.getHours()).substr(-2) + ":" +
        ("0" + date.getMinutes()).substr(-2) + ":" +
        ("0" + date.getSeconds()).substr(-2);
}

export function timestampToFullDateAndTime (timestamp) {

    let date = new Date(timestamp * 1000);
    let d = date.getFullYear() + "-" +
        ("0" + (date.getMonth() + 1)).substr(-2) + "-" +
        ("0" + date.getDate()).substr(-2);
    let t = ("0" + date.getHours()).substr(-2) + ":" +
        ("0" + date.getMinutes()).substr(-2) + ":" +
        ("0" + date.getSeconds()).substr(-2);

    return d + "/" + t
}

export function timestampToTimePassed (timestamp) {

    let now = new Date();
    let date = new Date(timestamp * 1000);
    let secondsDiff = (now.getTime() - date.getTime()) / 1000;
    if (secondsDiff < 10) { return "A second" }
    if (secondsDiff < 60) { return "Some seconds" }
    if (secondsDiff < 120) { return "A minute" }

    let minutesDiff = secondsDiff / 60;
    if (minutesDiff < 60) { return Math.floor(minutesDiff) + " minutes"}
    if (minutesDiff < 120) { return "An hour"}

    let hoursDiff = minutesDiff / 60;
    if (hoursDiff < 24) { return Math.floor(hoursDiff) + " hours"}
    if (hoursDiff < 48) { return "A day"}

    let daysDiff = hoursDiff / 24;
    if (daysDiff < 7) { return Math.floor(daysDiff) + " days"}
    if (daysDiff < 14) { return "A week"}

    let weeksDiff = daysDiff / 7;
    if (weeksDiff < 4) { return Math.floor(weeksDiff) + " weeks"}
    if (weeksDiff < 9) { return "A month"}
    return "A while";
}

export function dateToDayMonthYear(date) {

    function join(t, a, s) {
        function format(m) {
            let f = new Intl.DateTimeFormat('en', m);
            return f.format(t);
        }
        return a.map(format).join(s);
    }

    let a = [{day: 'numeric'}, {month: 'short'}, {year: 'numeric'}];
    return join(date, a, '-');
}

export function dayMonthYearFormatted(day, month, year) {

    return ("0" + day).substr(-2) + "-" + ("0" + month).substr(-2) + "-" + year;
}

export function numericDateToNamedMonthAndYear(date) {

    const words = date.split('-');
    if (words.length < 3) return "Illegal";
    return words[2] + "-" + months[parseInt(words[1]) - 1];
}

export function formatHoursAndMinutes(hours, minutes) {

    return formatIntoTwoDigitNumber(hours)+ ":" + formatIntoTwoDigitNumber(minutes);
}

export function isYear(value) {

    if (isNaN(value) || value.length === 0) return false;
    let number = parseInt(value);
    return number > 0;
}

export function isMonth(value) {

    if (isNaN(value) || value.length === 0 || value.length > 2) return false;
    let number = parseInt(value);
    return number > 0 && number <= 12;
}

export function isDay(value, month, year) {

    if (isNaN(value) || value.length === 0 || value.length > 2) return false;
    let number = parseInt(value);
    let monthNumber = parseInt(month);
    let yearNumber = parseInt(year);
    if (monthNumber) {
        if (monthNumber === 2)
            return number > 0 && number < (yearNumber % 4 === 0 ? 30 : 29);
        if (monthNumber === 4 || monthNumber === 6 || monthNumber === 9 || monthNumber === 11)
            return number > 0 && number < 31;
    }
    return number > 0 && number < 32;
}

export function isHour(value) {

    if (isNaN(value) || value.length === 0 || value.length > 2) return false;
    let number = parseInt(value);
    return number >= 0 && number < 24;
}

export function isMinute(value) {

    if (isNaN(value) || value.length === 0 || value.length > 2) return false;
    let number = parseInt(value);
    return number >= 0 && number < 60;
}

export function formatIntoTwoDigitNumber(number) {

    return ("0" + number).substr(-2);
}

export function monthNameFormattedFromNumberFormatted(numberFormattedName) {

    let parts = numberFormattedName.split('-');
    if (parts.length < 2) return numberFormattedName;

    return parts[0] + "-" + months[parseInt(parts[1]) - 1];
}

export function localGMT() {

    return new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
}

export function localToUTC(hours) {

    let date = new Date();
    let result = parseInt(hours, 10) + (date.getTimezoneOffset() / 60);
    return (result < 0 ? 24 + result : result) % 24;
}

export function UTCToLocal(hours) {

    let date = new Date();
    let result = parseInt(hours, 10) - (date.getTimezoneOffset() / 60);
    return (result < 0 ? 24 + result : result) % 24;
}

export function timeToHourMinute(time) {

    return formatIntoTwoDigitNumber(time.hour) + ":" + formatIntoTwoDigitNumber(time.minute);
}

export function hourFromDateTimeString(dateTime) {

    return dateTime.substring(0, dateTime.length - 4).replace("T", " ");
}

export function fullDateFromDateTimeString(dateTime) {

    return dateTime.substring(0, dateTime.indexOf("T"));
}

export function monthFromDateTimeString(dateTime) {

    return dateTime.substring(0, dateTime.indexOf("T") - 3);
}

export function yearFromDateTimeString(dateTime) {

    return dateTime.substring(0, dateTime.indexOf("T") - 6);
}