import {STATUS_CHANGED} from "../actions/types";

const initialState = {
    status: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {

    switch(action.type) {

        case STATUS_CHANGED:
            return {...state, status: action.status}

        default:
            return state;
    }
}