import React from "react";
import PropTypes from "prop-types";
import "../../../styles/tabs/reports/sensor_events.css"
import SelectBox from "../../boxes/selectbox";
import EventsChart from "../../charts/events_chart";
import {timestampToYearAndMonth} from "../../../../utils/time";

export default class SensorEvents extends React.Component {

    constructor(props) {

        super(props);
        let periods = [];
        if (this.props.Sensors.length > 0) {
            periods = this.periodCases(this.props.Sensors[0].Value);
        }
        this.state = {
            period: periods.length > 0 ? periods[0].Value : null,
            sensor: this.props.Sensors.length > 0 ? this.props.Sensors[0].Value.id : -1,
        }
    }

    sensorName = () => {

        return "";
    }

    sensorCases = () => {

        return this.props.Sensors;
    }

    onSensorSelected = sensor => {

        this.setState({...this.state, sensor: sensor.id});
    }

    selectedSensor = () => {

        return this.props.Sensors.find(sensor => sensor.Value.id === this.state.sensor);
    }

    periodCases = (sensor) => {

        if (sensor in this.props.Events) {
            return this.props.Events[sensor].reduce((result, event) => {
                let month = timestampToYearAndMonth(event.timestamp);
                if (!result.find(m => m.Name === month)) {
                    result.push({Name: month, Value: event.timestamp});
                }
                return result;
            }, []);
        }
        return [];
    }

    onPeriodSelected = period => {

        this.setState({...this.state, period: period});
    }


    chartFromSelections = () => {

        return <EventsChart Data={this.props.Events}
                            SelectedDate={this.state.period}
                            UseYear={!!this.state.useYear}
                            SelectedSensor={this.state.sensor}/>
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_sensor_events_tab"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_sensor_events_info_bar">
                    <p className="vl_sensor_events_title">{""}</p>
                    <SelectBox Callback={this.onPeriodSelected}
                               Cases={this.periodCases(this.state.sensor)}
                               VlStyle={{marginLeft: "auto"}}
                    />
                    <SelectBox Callback={this.onSensorSelected}
                               Cases={this.sensorCases()}
                               Selected={this.selectedSensor()}
                               VlStyle={{marginLeft: "2%", marginRight: "7.5%"}}
                    />
                </div>
                <div className="vl_sensor_events_chart_container">
                    { this.chartFromSelections() }
                </div>
            </div>
        )
    }
}

SensorEvents.propTypes = {
    Sensors: PropTypes.array,
    Events: PropTypes.object,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}