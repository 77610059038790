import {
    CLEAR_GENERATED_REPORT,
    GENERATE_REPORT,
    GET_HISTOGRAM,
    GET_HISTOGRAM_META,
    GET_REPORTS
} from "../actions/types";

const initialState = {
    reports: {months_by_day: [], years_by_month: []},
    histograms_meta: {network_groups: [], network_periods: []},
    histograms: {},
    generated_reports: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {

    switch(action.type) {

        case GET_REPORTS:
            return { ...state, reports: action.reports }

        case GET_HISTOGRAM_META:
            return { ...state, histograms_meta: action.meta }

        case GET_HISTOGRAM:
            let newHistograms = {...state.histograms}
            newHistograms[action.area_id + "-" + action.group_id + "-" + action.period] = action.histogram
            return { ...state, histograms: newHistograms }

        case GENERATE_REPORT:
            let newGeneratedReports = JSON.parse(JSON.stringify(state.generated_reports));
            newGeneratedReports[action.subject_id + "-" + action.id.toString()] = action.report;
            return { ...state, generated_reports: newGeneratedReports }

        case CLEAR_GENERATED_REPORT:
            return { ...state, generated_reports: {} }

        default:
            return state;
    }
}
