import React from 'react'
import "../../styles/boxes/triggerbox.css"
import PropTypes from "prop-types";

export default class TriggerBox extends React.Component {

    render() {
        return (
            <div className={
                this.props.VlClass ?
                this.props.VlClass :
                "vl_triggerbox_container " + (this.props.On ? "on" : "off")}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}
                 onClick={this.props.Callback}>
                <div className={"vl_triggerbox_toggle " + (this.props.On ? "on" : "off")}/>
                <p className={"vl_triggerbox_text " + (this.props.On ? "on" : "off")}>
                    {this.props.On ? "ON" : "OFF"}
                </p>
            </div>
        )
    }
}

TriggerBox.propTypes = {
    Callback: PropTypes.func.isRequired,
    On: PropTypes.bool.isRequired,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
