import React from 'react'
import "../../styles/boxes/timeeditbox.css"
import PropTypes from "prop-types";
import {formatIntoTwoDigitNumber, isHour, isMinute} from "../../../utils/time";

export default class TimeEditBox extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            minuteFocused: false,
            hourFocused: false,
        }
    }

    hourValue = () => {

        if (this.state.hourFocused)
            return this.props.Value.hour;
        return formatIntoTwoDigitNumber(this.props.Value.hour);
    }

    minuteValue = () => {

        if (this.state.minuteFocused)
            return this.props.Value.minute;
        return formatIntoTwoDigitNumber(this.props.Value.minute);
    }

    onHourChanged = e => {

        if (isHour(e.target.value)) {
            this.props.Callback({...this.props.Value, hour: e.target.value});
        } else {
            e.preventDefault();
        }
    }

    onMinuteChanged = e => {

        if (isMinute(e.target.value)) {
            this.props.Callback({...this.props.Value, minute: e.target.value});
        } else {
            e.preventDefault();
        }
    }

    render() {

        return (
            <div className={"vl_time_edit_container " + (this.props.VlClass ? this.props.VlClass : "")}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <input type="text"
                       className={"vl_time_edit_hour"}
                       onChange={this.onHourChanged}
                       onFocus={() => this.setState({...this.state, hourFocused: true})}
                       onBlur={() => this.setState({...this.state, hourFocused: false})}
                       value={this.hourValue()}
                       disabled={!this.props.Value}
                />
                <p className="vl_time_edit_divider">:</p>
                <input type="text"
                       className={"vl_time_edit_minute"}
                       onChange={this.onMinuteChanged}
                       onFocus={() => this.setState({...this.state, minuteFocused: true})}
                       onBlur={() => this.setState({...this.state, minuteFocused: false})}
                       value={this.minuteValue()}
                       disabled={!this.props.Value}
                />
            </div>
        )
    }
}

TimeEditBox.propTypes = {
    Callback: PropTypes.func.isRequired,
    Value: PropTypes.object,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
