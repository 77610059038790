export function findSensorById (id, state) {

    return state.sensors.find(d => d.id === id);
}

export function updatedAreasForSensor (sensor, areas, previousAreaId, previousGroupId) {

    areas.forEach(area => {
        // Sensor is moved to this area
        if (area.id === sensor.area_id) {
            // Sensor is in a group
            if (sensor.group_id > 0 && area.groups) {
                let foundGroup = area.groups.find(d => d.id === sensor.group_id);
                if (foundGroup) {
                    // Update sensor in a group
                    foundGroup.sensors = foundGroup.sensors.map(d => {
                        if (d.id === sensor.id) return sensor;
                        return d;
                    })
                }
            }
            // Sensor is not in any group
            else {
                if (!area.sensors) area.sensors = [];
                let foundSensor = area.sensors.find(d => d.id === sensor.id);
                // If sensor is already in the area then update
                if (foundSensor) area.sensors = area.sensors.map(d => {
                    if (d.id === sensor.id) return sensor;
                    return d;
                });
                // If is new for to the area add to list
                else area.sensors.push(sensor);
            }
        }
        // Sensor is moved from this area
        else if (area.id === previousAreaId) {
            // Previously had a group
            if (previousGroupId > 0 && area.groups) {
                let foundGroup = area.groups.find(d => d.id === previousGroupId);
                // Remove from previous group
                if (foundGroup) foundGroup.sensors = foundGroup.sensors.filter(d => d.id !== sensor.id)
            }
            // Previously had no group
            else if (area.sensors) {
                // Remove sensor from its previous area's sensors list
                area.sensors = area.sensors.filter(d => d.id !== sensor.id);
            }
        }
    });
    return areas;
}

export function sensorsWithIds(sensors, ids) {

    return sensors.filter(sensor => ids.find(id => sensor.id === id));
}