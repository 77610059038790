import {ADD_LOG, GET_LOGS, GET_SERVICE_LOGS, GET_SERVICES, SELECT_LOG} from './types';

export const addLog = (log) => dispatch => {

    dispatch({
        type: ADD_LOG,
        log: log
    })
};

export const getServices = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/monitoring/services",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                response.json().then(services => {

                    dispatch({
                        type: GET_SERVICES,
                        services: services,
                    })
                })
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const getLogs = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/monitoring/logs",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                response.json().then(logs => {

                    dispatch({
                        type: GET_LOGS,
                        logs: logs,
                    })
                })
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const getServiceLogs = (service) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/monitoring/logs?service=" + service + "&count=1000",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                response.json().then(logs => {

                    dispatch({
                        type: GET_SERVICE_LOGS,
                        service: service,
                        logs: logs,
                    })
                })
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const selectLog = (id, serviceSelected) => dispatch => {

    dispatch({
        type: SELECT_LOG,
        id: id,
        serviceSelected: serviceSelected,
    })
}