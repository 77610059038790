import React from 'react'
import "../../styles/menus/user_menu.css"
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

export default class UserMenu extends React.Component {

    render() {

        return (
            <div
                className={this.props.VlClass ? this.props.VlClass : "vl_user_menu"}
                style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_user_menu_header_container">
                    <p className="vl_user_menu_header_username">{this.props.User?.username}</p>
                </div>
                <Link className="vl_user_menu_entry" to="/profile"
                      onMouseUp={this.props.Finished}
                      style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <div className="vl_user_menu_profile"/>
                    <p>Profile</p>
                </Link>
                {
                    this.props.User?.is_admin ?
                    <Link className="vl_user_menu_entry" to="/users"
                          onMouseUp={this.props.Finished}
                          style={{color: 'inherit', textDecoration: 'inherit'}}>
                        <div className="vl_user_menu_users"/>
                        <p>Users</p>
                    </Link> : ""
                }
                <div className="vl_user_menu_entry"
                     onClick={this.props.SignOut}
                      onMouseUp={this.props.Finished}
                      style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <div className="vl_user_menu_logout"/>
                    <p>Log out</p>
                </div>
            </div>
        )}
}

UserMenu.propTypes = {
    SignOut: PropTypes.func.isRequired,
    Finished: PropTypes.func,

    User: PropTypes.object,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
