import React from 'react'
import "../../styles/tables/users_table.css"
import PropTypes from "prop-types";
import VlButton from "../buttons/vl_button";

export default class UsersTable extends React.Component {

    usersList = () => {

        return this.props.Users.map(user => {
            return (
                <tr key={user.ID} className="vl_users_table_row">
                    <td>{user.Name}</td>
                    <td><p className={
                        user.Status === 'admin' ? "status vl_users_table_status_admin" : "status vl_users_table_status_user"
                    }>{user.Status}</p></td>
                    <td>
                        {
                            user.Name !== "admin" ?
                            <VlButton Style="empty" Text="Edit" Callback={() => this.props.EditCallback(user)}/> : ""
                        }
                    </td>
                    <td>
                        {user.Status === "user" ?
                            <VlButton Style="empty"
                                      Icon="delete"
                                      Text="Delete user"
                                      Callback={() => this.props.DeleteCallback(user)}/>: ""}
                    </td>
                </tr>
            )
        })
    }

    render() {
        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_users_table_container"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <table className="vl_users_table">
                    <thead>
                        <tr className="vl_users_table_header">
                            <th>Username</th>
                            <th>Status</th>
                            <th />
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {this.usersList()}
                    </tbody>
                </table>
            </div>
        )}
}

UsersTable.propTypes = {
    DeleteCallback: PropTypes.func,
    EditCallback: PropTypes.func,

    Users: PropTypes.array.isRequired,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
