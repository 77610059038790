import {
    GET_DETAILS,
    GET_HARDWARE_GROUPS,
    SET_GROUP_INTENSITY,
    UPDATE_HARDWARE_GROUP,
} from "../actions/types";

const initialState = {
    devices: [],
    groups: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {

    switch(action.type) {

        case GET_DETAILS:
            return { ...state, devices: state.devices.map(device => {
                    let details = action.details.find(d => d.device_id === device.device_id);
                    if (details !== undefined)
                        device.details = details
                    return device
                }) }

        case GET_HARDWARE_GROUPS:
            for (let i = 0; i < action.groups.length; i++) {
                if (!("place" in action.groups[i])) {
                    action.groups[i].place = "Floor_1";
                }
            }
            return { ...state, groups: action.groups }

        case SET_GROUP_INTENSITY:
            if (!action.isHardware) return state;
            let groups = state.groups.map(group => {
                if (group.id === action.group.group_id && group.network === action.group.network)
                    group.intensity = action.group.intensity
                return group
            })
            return { ...state, groups: groups }

        case UPDATE_HARDWARE_GROUP:
            let g = state.groups.map(group => {
                if (group.id === action.group_id) {
                    group.x = action.x
                    group.y = action.y
                    group.place = action.place
                }
                return group
            })
            return { ...state, groups: g }

        default:
            return state;
    }
}
