import React from "react";
import  "../../styles/map/map_view.css";
import L from "leaflet";
import Slider from "../tools/slider";
import PropTypes from "prop-types";
import {imageBounds} from "../../../utils/map/consts";


export class MapView extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            horizontal: this.props.Horizontal,
            vertical: this.props.Vertical,
        }
    }

    componentDidMount() {

        this.map = L.map('map_view', {
            center: [0, 0],
            zoom: 2,
            zoomControl: true
        });
        this.map.setMaxBounds(imageBounds);
        let img = typeof this.props.Image === "string" ? this.props.Image : URL.createObjectURL(this.props.Image);
        this.currentPlan = L.imageOverlay(img, this.imageBounds()).addTo(this.map);
    }

    imageBounds = () => {

        return [[-this.state.vertical, -this.state.horizontal], [this.state.vertical, this.state.horizontal]];
    }

    imageBoundsChanged = () => {

        this.currentPlan.setBounds(this.imageBounds());
    }

    changeHorizontal = value => {

        this.setState({...this.state, horizontal: value}, this.imageBoundsChanged);
    }

    changeVertical = value => {

        this.setState({...this.state, vertical: value}, this.imageBoundsChanged);
    }

    updateSize = () => {

        this.props.BoundsChanged(this.imageBounds());
    }

    render() {

        return (
            <div className="vl_map_view_container">
                <Slider Callback={this.changeHorizontal}
                        StopCallback={this.updateSize}
                        VlClass="vl_map_view_horizontal_slider"
                        Min={50}
                        Max={240}
                        Horizontal
                        Value={this.state.horizontal} />
                <Slider Callback={this.changeVertical}
                        StopCallback={this.updateSize}
                        VlClass="vl_map_view_vertical_slider"
                        Min={20}
                        Max={85}
                        Value={this.state.vertical} />
                <div id='map_view'
                     className="vl_map_view_map_container"
                />
            </div>
        )
    }
}

MapView.propTypes = {
    BoundsChanged: PropTypes.func,
    Image: PropTypes.any,
    Horizontal: PropTypes.number,
    Vertical: PropTypes.number,
}