import React from 'react'
import "../../styles/boxes/selectbox.css"
import PropTypes from "prop-types";

export default class SelectBox extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            opened: false,
            selectedCase: this.selectedCaseFromProps(),
            hovered: -1,
        }
        this.wrapperRef = React.createRef();
        this.bodyRef = React.createRef();
    }

    componentDidMount() {

        window.addEventListener("mousedown", this.handleOutsideClick);
    }

    componentWillUnmount() {

        window.removeEventListener("mousedown", this.handleOutsideClick);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps !== this.props) {
           if (this.props.Selected &&
               this.props.Cases.find(c => c.Name === this.props.Selected.Name && c.Value === this.props.Selected.Value) &&
               (!this.state.selectedCase ||
                   this.state.selectedCase.Name !== this.props.Selected.Name ||
                   this.state.selectedCase.Value !== this.props.Selected.Value)) {
               this.setState({...this.state, selectedCase: this.props.Selected});
           } else if (
               this.props.Cases &&
               this.state.selectedCase &&
               !this.props.Cases.find(c => c.Name === this.state.selectedCase.Name && c.Value === this.state.selectedCase.Value)) {
               if (this.props.Cases.length > 0) {
                   this.setState({...this.state, selectedCase: this.props.Cases[0]});
               } else {
                   this.setState({...this.state, selectedCase: {Name: "No Options", Value: null}});
               }
           }
        }
    }

    setHovered = (index) => {

        this.setState({
            ...this.state,
            hovered: index
        });
    }

    selectedCaseFromProps = () => {

        if (this.props.Selected) return this.props.Selected;
        return this.props.Cases.length > 0 ? this.props.Cases[0] : null;
    }

    handleOutsideClick = (event) => {

        if ((this.wrapperRef && this.wrapperRef.current.contains(event.target)) ||
            (this.bodyRef && this.bodyRef.current && this.bodyRef.current.contains(event.target))
        ) {
            return;
        }
        this.setState({...this.state, opened: false});
    }

    trigger = () => {

        this.setState({...this.state, opened: !this.state.opened});
    }

    selectOption = o => {

        this.setState({...this.state, opened: false, selectedCase: o}, () => this.props.Callback(o.Value, o.Data));
    }

    body = () => {

        if (!this.state.opened) return "";
        return (
            <div className="vl_select_box_body" ref={this.bodyRef}>
                {
                    this.props.Cases.map((c, index) => this.option(c, index))
                }
            </div>
        )
    }

    tooltip = option => {

        return (
            <div className="vl_select_box_option_tooltip"
                 onClick={e => {
                     this.selectOption(option);
                     e.preventDefault();
                     e.stopPropagation();
                 }}>
                {option.Name}
            </div>
        )
    }

    option = (o, index) => {

        return (
            <div key={index} className="vl_select_box_option_container">
                {this.state.hovered === index ? this.tooltip(o) : ""}
                <div className="vl_select_box_option"
                     onMouseEnter={() => this.setHovered(index)}>
                    <p>{o.Name}</p>
                </div>
            </div>
        )
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_select_box"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}
                 ref={this.wrapperRef}
                 onClick={this.trigger}
                 disabled={this.props.Disabled}
            >
                <p className="vl_select_box_text">{this.state.selectedCase ? this.state.selectedCase.Name : "Not selected"}</p>
                <p className="vl_select_box_arrow"/>
                { this.body() }
            </div>
        )
    }
}

SelectBox.propTypes = {
    Callback: PropTypes.func.isRequired,
    Cases: PropTypes.array.isRequired,
    Selected: PropTypes.object,
    Disabled: PropTypes.bool,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
