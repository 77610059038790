import React from 'react'
import "../../styles/map/map.css"
import PropTypes from "prop-types";

import L from "leaflet";
import "leaflet/dist/leaflet.css"
import "leaflet-draw"
import "leaflet-draw/dist/leaflet.draw.css"

import MapControls from "./controls";
import {connect} from "react-redux";
import {getPlan, uploadPlace, uploadPlan, removePlace} from "../../../actions/places";
import {createArea, updateArea, removeArea} from "../../../actions/areas";
import {updateDevice, selectDevice} from "../../../actions/device"
import {updateSensor} from "../../../actions/sensors"
import NamingDialog from "../dialogs/naming";
import MapFooter from "./footer";
import ConfirmationDialog from "../dialogs/confirmation";
import {createMap, draggingDevice, dropDevice, goToBoundsOfArea, showMapItems, showPlan} from "../../../utils/map/map";
import {restoreRemoved, lastAreaPoints, removeLastArea, setAreas, geometriesDiffer} from "../../../utils/map/areas";
import {devicesInsideGeometry, unselectAllDevices} from "../../../utils/map/devices";
import {deviceFromAreas, devicesFromAreas} from "../../../utils/devices";
import Lottie from "react-lottie-player";
import loadingJson from "../../animations/IntensityLoading.json";
import {sensorFromAreas, sensorsFromAreas} from "../../../utils/sensors";

class Map extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            dragPos: null,
            dragDevice: null,
            namingDialogOpened: false,
            deleteMapDialogOpened: false,
            deleteAreaDialogOpened: false,
            deletedAreaIds: [],
            place: null,
            area: null,
        };
        this.wrapperRef = React.createRef();
    }

    componentDidMount() {

        window.addEventListener("dblclick", this.deactivateAllCallback);

        createMap(
            (success, deviceIds) => {
                if (success)
                    this.setState({
                        ...this.state,
                        namingDialogOpened: true,
                        createdAreaDeviceIds: deviceIds.filter(id => !id.startsWith("sensor")),
                        createdAreaSensorIds: deviceIds.filter(id => id.startsWith("sensor")),
                    });
                else
                    removeLastArea();
            },
            (ids) => {
                this.setState({...this.state, deleteAreaDialogOpened: true, deletedAreaIds: ids})
            },
            (areas) => {
                if (!areas) {
                    setAreas(this.props.Areas, this.state.place);
                } else {
                    areas.forEach(area => {
                        let a = this.props.Areas.find(a => a.id === area.id);
                        if (a && geometriesDiffer(a.geometry, area.geometry)) {
                            a.geometry = JSON.parse(JSON.stringify(area.geometry));
                            this.props.updateArea(a, devicesInsideGeometry(a.geometry));
                        }
                    })
                }
            },
            this.props.selectDevice,
            (device_id, area_id, pos) => {
                if (device_id.startsWith("sensor")) {
                    this.updateSensor(device_id, area_id, pos);
                } else {
                    this.updateDevice(device_id, area_id, pos);
                }
            }
        );
        this.showPlace(this.nextPlace());
    }

    updateSensor = (sensor_id, area_id, pos) => {

        let sensor = sensorFromAreas(sensor_id, this.props.Areas);
        if (!sensor) {
            this.props.updateSensor({
                id: sensor_id,
                area_id: area_id || -1,
                group_id: -1,
                position: {...pos, place: this.state.place}
            });
        } else {
            this.props.updateSensor({
                id: sensor_id,
                area_id: area_id || -1,
                group_id: sensor.area_id !== area_id ? -1 : sensor.group_id,
                position: {...pos, place: this.state.place}
            });
        }
    }

    updateDevice = (device_id, area_id, pos) => {

        let device = deviceFromAreas(device_id, this.props.Areas);
        if (!device) {
            this.props.updateDevice({
                id: device_id,
                area_id: area_id || -1,
                group_id: -1,
                position: {...pos, place: this.state.place}
            });
        } else {
            this.props.updateDevice({
                id: device_id,
                area_id: area_id || -1,
                group_id: device.area_id !== area_id ? -1 : device.group_id,
                position: {...pos, place: this.state.place}
            });
        }
    }

    componentWillUnmount() {

        window.removeEventListener("dblclick", this.deactivateAllCallback);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.placesDiffer(this.props.Places, prevProps.Places)) {
            if (this.props.Places.length === prevProps.Places.length && this.state.place) {
                this.showPlace(this.state.place);
            } else {
                this.showPlace(this.nextPlace());
            }
        } else if (this.plansLoaded(prevProps.plansLoading, this.props.plansLoading)) {
            this.showPlace(Object.keys(prevProps.plansLoading)[0]);
        } else if (this.state.place && (this.props.Areas !== prevProps.Areas || this.props.UnusedDevices !== prevProps.UnusedDevices)) {
            showMapItems(this.props.Areas, this.mapItemsList(), this.state.place);
        }
        this.dragUpdated();
    }

    showPlace = (place) => {

        if (!place) return;
        if (place in this.props.plans) {
            showPlan(this.props.plans[place], this.props.Places.find(p => p.name === place)?.bounds);
            showMapItems(this.props.Areas, this.mapItemsList(), place);
            this.setState({...this.state, place: place});
        } else {
            if (!this.props.plansLoading[place]) {
                this.props.getPlan(place);
            }
        }
    }

    mapItemsList = () => {

        return [...this.props.UnusedDevices, ...devicesFromAreas(this.props.Areas), ...this.props.Sensors];
    }

    nextPlace = () => {

        if (this.props.Places.length > 0) {
            return this.state.place ?
                this.props.Places[this.props.Places.length - 1].name :
                this.props.Places[0].name;
        }
        return null;
    }

    placesDiffer = (places_1, places_2) => {

        if (places_1.length !== places_2.length) return true;
        return places_1.reduce((result, place1, i) => {
            if (place1.name !== places_2[i].name ||
                place1.plan !== places_2[i].plan ||
                this.boundsDiffer(place1.bounds, places_2[i].bounds)) return true;
            return result;
        }, false);
    }

    boundsDiffer = (bounds_1, bounds_2) => {

        if ((!bounds_1 && bounds_2) || (bounds_1 && !bounds_2)) return true;
        if (!bounds_1 && !bounds_2) return false;
        const top_left_differs = bounds_1.top_left_point.lat !== bounds_2.top_left_point.lat ||
            bounds_1.top_left_point.lng !== bounds_2.top_left_point.lng;
        const bottom_right_differs = bounds_1.bottom_right_point.lat !== bounds_2.bottom_right_point.lat ||
            bounds_1.bottom_right_point.lng !== bounds_2.bottom_right_point.lng;
        return top_left_differs || bottom_right_differs;
    }

    plansLoaded = (prevPlansLoading, currentPlansLoading) => {

        return Object.keys(prevPlansLoading).length > 0 && Object.keys(currentPlansLoading).length === 0;
    }

    plansLoading = () => {

        return Object.keys(this.props.plansLoading).length > 0;
    }

    dragUpdated = () => {

        if (this.props.draggingDevice) {
            if (this.state.dragDevice === null || this.state.dragDevice.id !== this.props.draggingDevice.id)
                this.setState({...this.state, dragDevice: this.props.draggingDevice});
        } else if (dropDevice(this.state.dragDevice, this.state.dragPos)) {
            this.setState({...this.state, dragPos: null, dragDevice: null});
        }
    }

    deactivateAllCallback = event => {

        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            unselectAllDevices();
        }
    }

    onRemovePlace = () => {

        this.setState({...this.state, deleteMapDialogOpened: true});
    }

    onMapCreated = (img, name, bounds) => {

        this.props.uploadPlace(name, bounds);
        this.props.uploadPlan(img, name, bounds);
    }

    onMapEdited = (img, name, bounds) => {

        if (img) {
            this.props.uploadPlan(img, name, bounds);
        } else {
            this.props.uploadPlace(name, bounds, this.props.Places.find(place => place.name === name)?.plan);
        }
    }

    onAreaCreated = name => {

        if (name.length === 0) {
            removeLastArea();
        } else {
            this.props.createArea(
                {name: name, place: this.state.place, geometry: JSON.parse(JSON.stringify(lastAreaPoints()))},
                this.state.createdAreaDeviceIds,
                this.state.createdAreaSensorIds,
            );
        }
        this.setState({...this.state, namingDialogOpened: false});
    }

    onAreaSelected = area => {

        this.setState(
            {...this.state, area: this.props.Areas.find(a => a.name === area.Name)},
            () => {
                if (this.state.area?.id) goToBoundsOfArea(this.state.area.id);
            }
        )
    }

    onConfirmedMapDelete = confirmed => {

        this.setState(
            {...this.state, deleteMapDialogOpened: false},
            () => {
                if (confirmed) {
                    this.props.removePlace(this.state.place);
                }
            }
        );
    }

    onConfirmedAreaDelete = confirmed => {

        this.setState(
            {...this.state, deleteAreaDialogOpened: false},
            () => {
                if (confirmed && this.state.deletedAreaIds) {
                    this.state.deletedAreaIds.forEach(id => {
                        this.props.removeArea(id);
                    });
                } else {
                    restoreRemoved();
                }
            }
        );
    }

    onDragOver = e => {

        this.setState({...this.state, dragPos: e});
        draggingDevice(e);
    }

    maps = () => {

        if (!this.props.Places) return [];
        return this.props.Places.map((place, index) => { return {Name: place.name, Value: index} });
    }

    selectedMap = () => {

        if (!this.state.place) return null;
        return this.maps().find(m => m.Name === this.state.place);
    }

    areas = () => {

        if (!this.props.Areas) return [];
        return this.props.Areas
            .filter(area => area.place === this.selectedMap()?.Name)
            .map((area, index) => { return {Name: area.name, Value: index} });
    }

    selectedArea = () => {

        if (!this.state.area) return null;
        return this.areas().find(a => a.Name === this.state.area.name);
    }

    loading = () => {

        if (!this.plansLoading()) return <></>
        return (
            <div className="vl_map_loading_container">
                <Lottie
                    loop
                    goTo={0}
                    animationData={loadingJson}
                    speed={2}
                    play={true}
                    style={{
                        width: "800px",
                        height: "400px",
                        position: "absolute",
                        top: "45%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "black",
                    }}
                />
            </div>
        )
    }

    render() {

        return (
            <div className="vl_map_container" ref={this.wrapperRef} disabled={this.plansLoading()}>
                <MapControls MapCreated={this.onMapCreated}
                             MapEdited={this.onMapEdited}
                             MapSelected={this.showPlace}
                             AreaSelected={this.onAreaSelected}
                             SelectedMap={this.selectedMap()}
                             SelectedArea={this.selectedArea()}
                             Maps={this.maps()}
                             Areas={this.areas()}
                             Plans={this.props.plans}
                             Places={this.props.Places}
                />
                <div id='map'
                     className="vl_map_leaflet"
                     onDragOver={this.onDragOver}
                >
                    {this.loading()}
                </div>
                <MapFooter DeleteCallback={this.onRemovePlace} />
                {
                    this.state.namingDialogOpened ?
                        <NamingDialog Text="Enter name of the area"
                                      Callback={this.onAreaCreated} /> : ""
                }
                {
                    this.state.deleteMapDialogOpened ?
                        <ConfirmationDialog Text="Are you sure you want to delete this map?"
                                            Callback={this.onConfirmedMapDelete} /> : ""
                }
                {
                    this.state.deleteAreaDialogOpened ?
                        <ConfirmationDialog Text={"Are you sure you want to delete this area" + (this.state.deletedAreaIds.length > 1 ? "s?" : "?")}
                                            Callback={this.onConfirmedAreaDelete} /> : ""
                }
            </div>
        )}
}

Map.propTypes = {
    Areas: PropTypes.array,
    Sensors: PropTypes.array,
    UnusedDevices: PropTypes.array,
    Places: PropTypes.array,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}

const mapStateToOverviewProps = state => ({
    plans: state.places.plans,
    draggingDevice: state.places.draggingDevice,
    plansLoading: state.places.plansLoading,
})

export default connect(mapStateToOverviewProps, {
    getPlan,
    uploadPlace,
    uploadPlan,
    removePlace,
    selectDevice,
    createArea,
    updateArea,
    removeArea,
    updateDevice,
    updateSensor
})(Map);
