import React from 'react'
import "../../styles/buttons/undelete.css"
import PropTypes from "prop-types";
import {classForBrowser} from "../../../utils/browser";

export default class UndeleteButton extends React.Component {

    render() {
        return (
            <button
                className={this.props.VlClass ? this.props.VlClass : "vl_undelete_button"}
                style={this.props.VlStyle ? this.props.VlStyle : {}}
                onClick={this.props.Callback}>
                <p className={classForBrowser("vl_undelete_button_icon")} />
            </button>
        )}
}

UndeleteButton.propTypes = {
    Callback: PropTypes.func,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
