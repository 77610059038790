import React from "react";
import "../../../styles/panels/devices/sensor.css"
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {dragDevice} from "../../../../actions/places";
import InfoButton from "../../buttons/info";
import {deviceIsUsed, selectDevice} from "../../../../utils/map/devices";
import Shifter from "../../layout/shifter";
import RemoveButton from "../../buttons/remove";
import CheckBox from "../../boxes/checkbox";
import NameEditBox from "../../boxes/nameeditbox";
import {nameFromDevice} from "../../../../utils/devices";

class Sensor extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            opened: false,
            name_edit: false,
            name_edit_error: "",
        }
    }

    startDrag = e => {

        this.props.dragDevice(this.props.Sensor);
    }

    stopDrag = e => {

        this.props.dragDevice(null);
        e.preventDefault();
    }

    checkBox = () => {

        if (!this.props.CheckCallback) return "";
        return (
            <div className="vl_sensor_check_box_container">
                <CheckBox Callback={() => {this.props.CheckCallback(this.props.Sensor.id)}}
                          Checked={this.props.Checked}
                />
            </div>
        )
    }

    changeName = newName => {

        let error = null;
        if (newName.length < 3) {
            error = "Name is too short";
        } else {
            this.props.UpdateSensor({...this.props.Sensor, mnemonic: newName});
        }
        this.setState({
            ...this.state,
            name_edit_error: error,
            name_edit: error && error.length > 0
        });
    }

    name = () => {

        if (this.state.name_edit)
            return <NameEditBox VlClass="vl_sensor_name_edit"
                                VlEditClass="vl_sensor_name_edit_input"
                                VlErrorClass="vl_sensor_name_edit_error"
                                Error={this.state.name_edit_error}
                                Change={this.changeName}
                                Cancel={() => {this.setState({...this.state, name_edit: false, name_edit_error: ""})}}
                                Default={nameFromDevice(this.props.Sensor)}/>

        return (
            <h5 className="vl_sensor_name"
                onClick={() => {selectDevice(this.props.Sensor.id)}}
                onDoubleClick={() => {this.setState({...this.state, name_edit: true})}}>
                {nameFromDevice(this.props.Sensor)}
            </h5>
        )
    }

    typeInfo = () => {

        if (this.props.Sensor.id.includes("occupancy")) {
            return <div className="vl_sensor_occupancy_icon"/>
        } else if (this.props.Sensor.id.includes("button")) {
            return <div className="vl_sensor_button_icon"/>
        }
        return <div className="vl_sensor_unknown_icon"/>
    }

    generalInfo = () => {

        return (
            <>
                {this.typeInfo()}
                <div className="vl_sensor_info_container">
                    {this.name()}
                    <span className="vl_sensor_info">{this.props.Sensor.info1 ? this.props.Sensor.info1 : ""}</span>
                    <span className="vl_sensor_info">{this.props.Sensor.info2 ? this.props.Sensor.info2 : ""}</span>
                </div>
            </>
        )
    }

    sensorData = () => {

        return [
            { title: "ID", value: this.props.Sensor.id, general: true },
        ];
    }

    removeButton = () => {

        if (!this.props.DeleteCallback) return <></>;
        let removeDisplay = (this.state.opened || this.state.intensityBoxOpened) ? "none" : ""
        return (
            <>
                <Shifter VlStyle={{display: removeDisplay}}
                         Size="15px"/>
                <RemoveButton VlStyle={{display: removeDisplay}}
                              Permanent={!deviceIsUsed(this.props.Sensor)}
                              Callback={() => {this.props.DeleteCallback(this.props.Sensor, true)}}
                />
            </>
        )
    }

    buttons = () => {

        let infoDisplay = this.state.intensityBoxOpened ? "none" : ""
        return (
            <div className="vl_sensor_buttons">
                <InfoButton VlStyle={{display: infoDisplay}} Callback={() => {
                    this.setState({...this.state, opened: !this.state.opened})
                }} />
                {
                    this.props.CheckCallback ? "" :
                        <>
                            {this.removeButton()}
                        </>
                }
            </div>
        )
    }

    infoEntry = (item, index) => {

        return (
            <tr key={index} className="vl_sensor_info_table_row">
                <td className={"vl_sensor_info_table_item_title" + (item.general ? " vl_sensor_info_table_general_entry" : "")}>
                    {item.title}
                </td>
                <td className={"vl_sensor_info_table_item_value" + (item.general ? " vl_sensor_info_table_general_entry" : "")}>
                    {item.value}
                </td>
            </tr>
        )
    }

    info = () => {

        if (!this.state.opened) return "";
        return (
            <div className="vl_sensor_info_table_container">
                <table className="vl_sensor_info_table">
                    <tbody>
                    {this.sensorData().map((item, index) => this.infoEntry(item, index))}
                    </tbody>
                </table>
            </div>
        )
    }

    render() {

        return (
            <div className="vl_sensor_container">
                {this.checkBox()}
                <div className="vl_sensor"
                     style={this.props.VlStyle || {}}
                     onDragStart={this.startDrag}
                     onDragEnd={this.stopDrag}
                     draggable>
                    {this.generalInfo()}
                    {this.buttons()}
                    {this.info()}
                </div>
            </div>
        )
    }
}

Sensor.propTypes = {
    DeleteCallback: PropTypes.func,
    UpdateSensor: PropTypes.func,
    CheckCallback: PropTypes.func,
    EditCallback: PropTypes.func,

    Checked: PropTypes.bool,
    Sensor: PropTypes.object,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}

const mapStateToOverviewProps = state => ({
    selectedDevice: state.areas.selectedDevice,
})

export default connect(mapStateToOverviewProps, {
    dragDevice,
})(Sensor);