import L from "leaflet";
import {defaultAreaStyle} from "./consts";
import {arePolygonsIntersecting} from "./helpers";
import {isArray} from "../arrays";

let areas = new L.FeatureGroup();
let idMapping = {};
let lastCreatedArea = null;
let lastRemovedAreas = null;

export function setAreas(_areas, place) {

    removeAllAreas();
    _areas.filter(area => area.place === place).forEach(area => {
        if (area.geometry) {
            let layer = L.polygon(area.geometry, defaultAreaStyle);
            areas.addLayer(layer);
            idMapping[layer._leaflet_id] = area.id;
        }
    })
}

function removeAllAreas() {

    Object.values(areas._layers)
        .forEach(layer => {
            areas.removeLayer(layer);
        });
    idMapping = {};
}

export function getAreasFeatureGroup() {

    return areas;
}

export function getAreasAsLayers() {

    return Object.values(areas._layers);
}

export function getAreaIdFromLayer(layer) {

    return idMapping[layer?._leaflet_id];
}

export function getAreaGeometries() {

    let layers = getAreasAsLayers();
    return layers.map(layer => {
        return {id: getAreaIdFromLayer(layer), geometry: layer._latlngs[0]}
    })
}

export function removeLastArea() {

    if (lastCreatedArea)
        areas.removeLayer(lastCreatedArea);
}

export function lastAreaPoints() {

    if (lastCreatedArea)
        return lastCreatedArea._latlngs[0];
    return [];
}

export function areaCreated(e) {

    lastCreatedArea = e.layer;
    areas.addLayer(e.layer);
    return !layerIntersectingWithLayers(e.layer, getAreasAsLayers());
}

export function areaRemoved(e) {

    lastRemovedAreas = e.layers._layers;
    return Object.keys(lastRemovedAreas).map(leaflet_id => idMapping[leaflet_id]);
}

export function restoreRemoved() {

    if (lastRemovedAreas) {
        Object.values(lastRemovedAreas).forEach(layer => {
            areas.addLayer(layer);
        });
    }
}

export function areaEditStop(e) {

    return !layersIntersecting(getAreasAsLayers());
}

export function restoreEdited() {

    if (lastRemovedAreas) {
        Object.values(lastRemovedAreas).forEach(layer => {
            areas.addLayer(layer);
        });
    }
}

function layersIntersecting(layers) {

    for (let i = 0; i < layers.length; ++i) {
        for (let j = i + 1; j < layers.length; ++j) {
            if (arePolygonsIntersecting(layers[i], layers[j])) {
                return true;
            }
        }
    }
    return false;
}

function layerIntersectingWithLayers(layer, layers) {

    for (let i = 0; i < layers.length; ++i) {
        if (layer._leaflet_id !== layers[i]._leaflet_id && arePolygonsIntersecting(layers[i], layer)) {
            return true;
        }
    }
    return false;
}

export function geometriesDiffer(geometry_1, geometry_2) {

    if (!isArray(geometry_1) || !isArray(geometry_2) || geometry_1.length !== geometry_2.length) return true;
    return geometry_1.reduce((differ, point, i) => {
        if (point.lat !== geometry_2[i].lat || point.lng !== geometry_2[i].lng) return true;
        return differ;
    }, false);
}

export function areaLayerFromAreaId(areaId) {

    return Object.keys(idMapping).reduce((result, leafletId) => {
        if (idMapping[leafletId] === areaId) {
            let layers = getAreasAsLayers();
            return layers.find(layer => layer._leaflet_id.toString() === leafletId);
        }
        return result;
    }, null);
}