import {isArray} from "./arrays";
import {manufacturerFromID} from "./devices";

export const modifiedAreas = (areas, text, sort_field, reverse_sort) => {

    let modifiedAreas = searchForAreas(areas, text);
    modifiedAreas = sortAreasAccordingToField(modifiedAreas, sort_field, reverse_sort);
    return modifiedAreas;
}

export const searchForAreas = (areas, text) => {

    let start = text.indexOf("#");  // Gets the first index where a # occurs
    let field = start > 0 ? text.substr(0, start) : ""; // Gets the field part
    let search_text = start > 0 ? text.substr(start + 1) : text;  // Gets the search text part
    let end = search_text.indexOf("#");
    let modifiedAreas = reduceAreasAccordingToSearch(
        JSON.parse(JSON.stringify(areas)),
        field,
        end > 0 ? search_text.substr(0, end) : search_text
    );
    if (end > 0) modifiedAreas = searchForAreas(modifiedAreas, search_text.substr(end + 1));
    return modifiedAreas
}

const reduceAreasAccordingToSearch = (areas, field, search_text) => {

    switch (field) {
        case "p": // Search for place
            return reduceAreasAccordingToPlaceSearch(areas, search_text);
        case "a": // Search only for area names
            return reduceAreasAccordingToAreaNameSearch(areas, search_text);
        case "g": // Search only for group names
            return reduceAreasAccordingToGroupNameSearch(areas, search_text);
        case "d": // Search only for device names
            return reduceAreasAccordingToDeviceNameSearch(areas, search_text);
        case "i": // Search for intensity or intensity range (e.g. 50-100)
            return reduceAreasAccordingToIntensitySearch(areas, search_text);
        case "m": // Search for device manufacturer
            return reduceAreasAccordingToManufacturerSearch(areas, search_text);
        case "s": // Search for service of device
            return reduceAreasAccordingToServiceSearch(areas, search_text);
        default: // Search for area/group/device/sensor names
            return reduceAreasAccordingToNameSearch(areas, search_text);
    }
}

const reduceAreasAccordingToNameSearch = (areas, search) => {

    return areas.reduce((areasList, area) => {
        if (area.name.includes(search)) {
            areasList.push(area);
        } else {
            if (isArray(area.groups)) {
                let groups = area.groups.reduce((groupsList, group) => {
                    if (group.name.includes(search)) {
                        groupsList.push(group);
                    } else if (isArray(group.devices) && isArray(group.sensors)) {
                        let devices = group.devices.filter(device => device.name.includes(search));
                        if (devices.length > 0) {
                            group.devices = devices;
                        }
                        let sensors = group.sensors.filter(sensor => sensor.name.includes(search));
                        if (sensors.length > 0) {
                            group.sensors = sensors;
                        }
                        if (devices.length > 0 || sensors.length > 0) {
                            groupsList.push(group);
                        }
                    }
                    return groupsList;
                }, [])
                if (groups.length > 0) {
                    area.groups = groups;
                    areasList.push(area);
                }
            }
        }
        return areasList;
    }, []);
}

const reduceAreasAccordingToPlaceSearch = (areas, search) => {

    return areas.reduce((areasList, area) => {
        if (area.place.includes(search)) {
            areasList.push(area);
        }
        return areasList;
    }, []);
}

const reduceAreasAccordingToAreaNameSearch = (areas, search) => {

    return areas.reduce((areasList, area) => {
        if (area.name.includes(search)) {
            areasList.push(area);
        }
        return areasList;
    }, []);
}

const reduceAreasAccordingToGroupNameSearch = (areas, search) => {

    return areas.reduce((areasList, area) => {
        if (isArray(area.groups)) {
            let groups = area.groups.reduce((groupsList, group) => {
                if (group.name.includes(search)) {
                    groupsList.push(group);
                }
                return groupsList;
            }, [])
            if (groups.length > 0) {
                area.groups = groups;
                areasList.push(area);
            }
        }
        return areasList;
    }, []);
}

const reduceAreasAccordingToDeviceNameSearch = (areas, search) => {

    return areas.reduce((areasList, area) => {
        if (isArray(area.groups)) {
            let groups = area.groups.reduce((groupsList, group) => {
                if (isArray(group.devices)) {
                    let devices = group.devices.filter(device => device.name.includes(search));
                    if (devices.length > 0) {
                        group.sensors = [];
                        group.devices = devices;
                        groupsList.push(group);
                    }
                }
                return groupsList;
            }, [])
            if (groups.length > 0) {
                area.groups = groups;
                areasList.push(area);
            }
        }
        return areasList;
    }, []);
}

const reduceAreasAccordingToManufacturerSearch = (areas, search) => {

    return areas.reduce((areasList, area) => {
        if (isArray(area.groups)) {
            let groups = area.groups.reduce((groupsList, group) => {
                if (isArray(group.devices) && isArray(group.sensors)) {
                    let devices = group.devices.filter(device => manufacturerFromID(device.id).toLowerCase() === search.toLowerCase());
                    if (devices.length > 0) {
                        group.devices = devices;
                    }
                    let sensors = group.sensors.filter(sensor => manufacturerFromID(sensor.id).toLowerCase() === search.toLowerCase());
                    if (sensors.length > 0) {
                        group.sensors = sensors;
                    }
                    if (devices.length > 0 || sensors.length > 0) {
                        groupsList.push(group);
                    }
                }
                return groupsList;
            }, [])
            if (groups.length > 0) {
                area.groups = groups;
                areasList.push(area);
            }
        }
        return areasList;
    }, []);
}

const reduceAreasAccordingToServiceSearch = (areas, search) => {

    return areas.reduce((areasList, area) => {
        if (isArray(area.groups)) {
            let groups = area.groups.reduce((groupsList, group) => {
                if (isArray(group.devices) && isArray(group.sensors)) {
                    let devices = group.devices.filter(device => device.service.toLowerCase() === search.toLowerCase());
                    if (devices.length > 0) {
                        group.devices = devices;
                    }
                    let sensors = group.sensors.filter(sensor => sensor.service.toLowerCase() === search.toLowerCase());
                    if (sensors.length > 0) {
                        group.sensors = sensors;
                    }
                    if (devices.length > 0 || sensors.length > 0) {
                        groupsList.push(group);
                    }
                }
                return groupsList;
            }, [])
            if (groups.length > 0) {
                area.groups = groups;
                areasList.push(area);
            }
        }
        return areasList;
    }, []);
}

const reduceAreasAccordingToIntensitySearch = (areas, search) => {

    let range = intensityRangeFromSearch(search);
    return areas.reduce((areasList, area) => {
        if (isArray(area.groups)) {
            let groups = area.groups.reduce((groupsList, group) => {
                if (group.intensity >= range[0] && group.intensity <= range[1]) {
                    groupsList.push(group);
                } else if (isArray(group.devices)) {
                    let devices = group.devices.filter(device => device.intensity >= range[0] && device.intensity <= range[1]);
                    if (devices.length > 0) {
                        group.sensors = [];
                        group.devices = devices;
                        groupsList.push(group);
                    }
                }
                return groupsList;
            }, [])
            if (groups.length > 0) {
                area.groups = groups;
                areasList.push(area);
            }
        }
        return areasList;
    }, []);
}

const sortAreasAccordingToField = (areas, field, reverse_sort_activated) => {

    if (parseInt(field) === 1) {
        areas.sort((first, second) => compare(first.name, second.name, reverse_sort_activated));
        areas.forEach(area => {
            if (isArray(area.groups)) {
                area.groups.sort((first, second) => compare(first.name, second.name, reverse_sort_activated));
                area.groups.forEach(group => {
                    if (isArray(group.devices))
                        group.devices.sort((first, second) => compare(first.name, second.name, reverse_sort_activated));
                    if (isArray(group.sensors))
                        group.sensors.sort((first, second) => compare(first.name, second.name, reverse_sort_activated));
                })
            }
        });
    } else if (parseInt(field) === 2) {
        areas.forEach(area => {
            if (isArray(area.groups)) {
                area.groups.sort((first, second) => compare(first.intensity, second.intensity, reverse_sort_activated));
                area.groups.forEach(group => {
                    if (isArray(group.devices))
                        group.devices.sort((first, second) => compare(first.intensity, second.intensity, reverse_sort_activated));
                    group.sensors = [];
                })
            }
        });
    } else if (parseInt(field) === 3) {
        areas.sort((first, second) => compare(first.place, second.place, reverse_sort_activated));
    }
    return areas;
}

const compare = (first, second, reverse) => {

    let result;
    if (reverse)
        result = first < second;
    else
        result = first > second;
    return result ? 1 : -1;
}

const intensityRangeFromSearch = (search) => {

    let index = search.indexOf("-");  // Gets the first index where a # occurs
    let start_text = index > 0 ? search.substr(0, index) : search; // Gets the field part
    let end_text = index > 0 ? search.substr(index + 1) : "";  // Gets the search text part

    let start = -1;
    if (start_text.length > 0 && !isNaN(start_text)) {
        start = parseInt(start_text);
    }

    let end = start;
    if (start >= 0 && end_text.length > 0 && !isNaN(end_text)) {
        end = parseInt(end_text);
    }
    return [start, end];
}