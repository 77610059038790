import React from 'react'
import "../../styles/tabs/scenes_tab.css"
import PropTypes from "prop-types";
import ScenesList from "../lists/scenes_list";
import AddButton from "../buttons/add";
import AddScene from "../panels/scenes/add_scene";
import Filter from "../general/filter";
import ConfirmationDialog from "../dialogs/confirmation";

export default class ScenesTab extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            add: false,
            modification: {
                sort_field: 1,
                sort_activated: false,
                search_text: "",
            },
            sceneToDelete: null,
            sceneToEdit: null,
        }
    }

    componentDidMount() {

        this.props.GetScenes();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.modification !== prevState.modification) {
            this.props.Modify(
                this.state.modification.sort_field,
                this.state.modification.sort_activated,
                this.state.modification.search_text
            );
        }
    }

    saveScene = (name, groups) => {

        let intensityList = groups.map(g => {
            return {group_id: g.id, area_id: g.area_id, intensity: g.intensity};
        })
        if (this.state.sceneToEdit) {
            this.props.SaveScene({...this.state.sceneToEdit, groups: intensityList});
        } else {
            this.props.AddScene(name, intensityList);
        }
        this.setState({...this.state, add: false, sceneToEdit: null});
    }

    deleteRequest = scene => {

        this.setState({...this.state, sceneToDelete: scene});
    }

    editRequest = scene => {

        this.setState({...this.state, add: true, sceneToEdit: scene});
    }

    delete = confirmed => {

        if (confirmed) {
            this.props.DeleteScene(this.state.sceneToDelete.id);
        }
        this.setState({...this.state, sceneToDelete: null});
    }

    scenesList = () => {

        return (
            <div style={{position: "relative", width: "100%"}}>
                <Filter SortChanged={field => {this.setState({...this.state, modification: {...this.state.modification, sort_field: field}})}}
                        SortActivate={() => {this.setState({...this.state, modification: {...this.state.modification, sort_activated: !this.state.modification.sort_activated}})}}
                        SearchChanged={text => {this.setState({...this.state, modification: {...this.state.modification, search_text: text}})}}
                        SortActivated={this.state.modification.sort_activated}
                        Filters={[{Name: "Name", Value: 1}]}/>
                <div style={{position: "relative", width: "100%", height: "7vh"}}>
                    <AddButton Text={"Add Scene"}
                               VlStyle={{width: "160px", position: "absolute", right: 0, bottom: 0}}
                               Callback={() => {
                                   this.setState({...this.state, add: true, sceneToEdit: null});
                               }}/>
                </div>
                <ScenesList Scenes={this.props.Scenes}
                            Areas={this.props.Areas}
                            Loading={this.props.SceneChanging}
                            DeleteCallback={this.deleteRequest}
                            EditCallback={this.editRequest}
                            ActivateCallback={this.props.ActivateScene}
                />
            </div>
        )
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_scenes_tab"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                {
                    this.state.add ?
                        <AddScene SaveCallback={this.saveScene}
                                  CancelCallback={() => { this.setState({...this.state, add: false, sceneToEdit: null}) }}
                                  RealtimeUpdate={this.props.RealtimeSceneUpdate}
                                  Scene={this.state.sceneToEdit}
                                  Areas={this.props.Areas}/> :
                        this.scenesList()
                }
                {
                    this.state.sceneToDelete ?
                        <ConfirmationDialog Callback={this.delete}
                                            Text={"Are you sure you want to delete '" + this.state.sceneToDelete.name + "' ?"}/> :
                        ""
                }
            </div>
        )
    }
}

ScenesTab.propTypes = {
    Areas: PropTypes.array,
    Scenes: PropTypes.array,
    SceneChanging: PropTypes.bool,

    GetScenes: PropTypes.func,
    AddScene: PropTypes.func,
    SaveScene: PropTypes.func,
    DeleteScene: PropTypes.func,
    ActivateScene: PropTypes.func,
    RealtimeSceneUpdate: PropTypes.func,
    Modify: PropTypes.func,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}