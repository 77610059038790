import React from 'react'
import "../../styles/lists/parameters_list.css"
import PropTypes from "prop-types";
import CheckBox from "../boxes/checkbox";
import EditBox from "../boxes/editbox";

export default class ParametersList extends React.Component {

    arrayFromParameters = () => {

        let result = [];
        if ("lifetime_hours" in this.props.Parameters) {
            result.push({
                id: "lifetime_hours",
                used: this.props.Parameters["use_lifetime_hours"],
                name: "Lifetime in hours",
                value: this.props.Parameters["lifetime_hours"]
            });
        }
        if ("power_for_full_intensity_in_hour" in this.props.Parameters) {
            result.push({
                id: "power_for_full_intensity_in_hour",
                used: this.props.Parameters["use_power_for_full_intensity_in_hour"],
                name: "Power for full intensity in hour (W/H)",
                value: this.props.Parameters["power_for_full_intensity_in_hour"]
            });
        }
        return result;
    }

    changeValue = (id, value) => {

        if (id === "lifetime_hours") {
            this.props.Change(id, parseInt(value, 10));
        } else if(id === "power_for_full_intensity_in_hour") {
            this.props.Change(id, parseFloat(value));
        }
    }

    componentFromParameter = parameter => {

        return (
            <div className="vl_parameters_list_parameter_container" key={parameter.id}>
                <CheckBox Callback={() => {this.props.Change("use_"+parameter.id)}}
                          Checked={parameter.used}
                />
                <h6 className="vl_parameters_list_parameter_name">{parameter.name}</h6>
                <EditBox Callback={(value) => {this.changeValue(parameter.id, value)}}
                         Default={String(parameter.value)}
                         VlStyle={{width: "100px", marginLeft: "10px"}}
                />
            </div>
        )
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_parameters_list"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}
            >
                {
                    this.arrayFromParameters(this.props.Parameters).map(device => this.componentFromParameter(device))
                }
            </div>
        )}
}

ParametersList.propTypes = {
    Parameters: PropTypes.object.isRequired,
    Change: PropTypes.func,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
