import {
    CREATE_AREA,
    CREATE_GROUP, GET_ALL_SENSOR_EVENTS, GET_AREAS, GET_DETAILS, GET_DEVICES, GET_SENSOR_EVENTS, GET_SENSORS,
    MODIFY_DEVICES_LIST, REMOVE_AREA, REMOVE_DEVICE,
    REMOVE_GROUP, REMOVE_PLACE, REMOVE_SENSOR,
    SELECT_DEVICE,
    UPDATE_AREA, UPDATE_DEVICE,
    UPDATE_GROUP, UPDATE_SENSOR
} from "../actions/types";
import {devicesFromAreas, frontEndDevice} from "../utils/devices";
import {modifiedAreas} from "../utils/area_modify";
import {
    regroupDevicesInArea,
    devicesWithIds,
    findDeviceById,
    updatedAreas,
    devicesWithoutIds,
    updatedUnusedDevices, regroupSensorsInArea
} from "./areas/devices";
import {
    findSensorById, sensorsWithIds,
    updatedAreasForSensor
} from "./areas/sensors";
import {
    addGroupToArea,
    removeGroupInArea,
    updateGroupInArea,
} from "./areas/areas";
import {frontEndSensor} from "../utils/sensors";

const initialState = {
    areas: [],
    modifiedAreas: [],
    unused_devices: [],
    sensors: [],
    events: {},
    selectedDevice: null,
    updated: 0,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {

    switch (action.type) {

        case GET_DEVICES:
            return {
                ...state,
                areas: state.areas.map(area => {
                    let areaDevices = action.devices.filter(device => device.area_id === area.id).map(device => frontEndDevice(device));
                    area.devices = areaDevices.filter(device => !device.group_id || device.group_id < 0);
                    if (!area.groups) area.groups = [];
                    area.groups = area.groups.map(group => {
                        return {...group, devices: areaDevices.filter(device => device.group_id === group.id)}
                    });
                    return area;
                }),
                unused_devices: action.devices.filter(device => !device.area_id || device.area_id < 0).map(device => frontEndDevice(device)),
            };

        case UPDATE_DEVICE:
            let device = findDeviceById(action.device.id, state);
            if (!device) return state;
            let previousArea = device?.area_id || -1;
            let previousGroup = device?.group_id || -1;
            device = {
                ...device,
                area_id: action.device.area_id || -1,
                group_id: previousArea === action.device.area_id ? device.group_id : -1,
                position: action.device.position,
                deleted: action.device.deleted,
                intensity: action.device.intensity || device.intensity
            };
            return {
                ...state,
                areas: updatedAreas(device, state.areas, previousArea, previousGroup),
                modifiedAreas: updatedAreas(device, state.modifiedAreas, previousArea, previousGroup),
                unused_devices: updatedUnusedDevices(device, state.unused_devices),
                updated: (state.updated + 1) % 2
            };

        case REMOVE_DEVICE:
            let deviceToRemove = findDeviceById(action.device_id, state);
            if (!deviceToRemove) return state;
            let previousAreaOfRemovedDevice = deviceToRemove?.area_id || -1;
            let previousGroupOfRemovedDevice = deviceToRemove?.group_id || -1;
            deviceToRemove = {
                ...deviceToRemove,
                area_id: -1,
                position: undefined,
                group_id: -1
            };
            if (action.permanent) deviceToRemove.deleted = true;
            return {
                ...state,
                areas: updatedAreas(deviceToRemove, state.areas, previousAreaOfRemovedDevice, previousGroupOfRemovedDevice),
                modifiedAreas: updatedAreas(deviceToRemove, state.modifiedAreas, previousAreaOfRemovedDevice, previousGroupOfRemovedDevice),
                unused_devices: updatedUnusedDevices(deviceToRemove, state.unused_devices),
                updated: (state.updated + 1) % 2
            };

        case GET_DETAILS:
            return { ...state, devices: state.devices.map(device => {
                    let details = action.details.find(d => d.device_id === device.device_id);
                    if (details !== undefined)
                        device.details = details
                    return device
                }) }

        case GET_AREAS:
            return { ...state, areas: action.areas, modifiedAreas: JSON.parse(JSON.stringify(action.areas)) }

        case CREATE_AREA:
            let newAreas = JSON.parse(JSON.stringify(state.areas));
            let newArea = {
                id: state.areas.length > 0 ? state.areas[state.areas.length - 1].id + 1 : 1,
                ...action.area,
                devices: devicesWithIds(state.unused_devices, action.deviceIds),
                sensors: sensorsWithIds(state.sensors, action.sensorIds)
            };
            if (!newAreas.groups) newArea.groups = [];
            newAreas.push(newArea);
            return {
                ...state,
                areas: newAreas,
                unused_devices: devicesWithoutIds(state.unused_devices, action.deviceIds),
                sensors: state.sensors.map(sensor => {
                    if (action.sensorIds.includes(sensor.id)) {
                        return {...sensor, area_id: newArea.id}
                    }
                    return sensor;
                })
            }

        case UPDATE_AREA:
            let changedAreas = state.areas.map(area => {
                if (area.id === action.area.id) {
                    return action.area;
                }
                return area;
            });
            if (action.onlyArea) return {...state, areas: changedAreas};
            let newUnused = JSON.parse(JSON.stringify(state.unused_devices));
            newUnused = regroupDevicesInArea(action.area, newUnused, action.deviceIds);
            let newSensors = JSON.parse(JSON.stringify(state.sensors));
            newSensors = regroupSensorsInArea(action.area, newSensors, action.sensorIds);
            return {
                ...state,
                areas: changedAreas,
                unused_devices: newUnused,
                sensors: newSensors,
            }

        case REMOVE_AREA:
            return { ...state, areas: state.areas.filter(area => {
                    return area.id !== action.area_id;
                }) }

        case CREATE_GROUP:
            return {
                ...state,
                areas: addGroupToArea(state.areas, JSON.parse(JSON.stringify(action.group)), action.area_id, action.devices, action.sensors),
                modifiedAreas: addGroupToArea(state.modifiedAreas, JSON.parse(JSON.stringify(action.group)), action.area_id, action.devices, action.sensors),
                updated: (state.updated + 1) % 2
            }

        case UPDATE_GROUP:
            return {
                ...state,
                areas: updateGroupInArea(state.areas, JSON.parse(JSON.stringify(action.group)), action.area_id, action.devices, action.sensors, action.intensity_changed, action.onlyGroup),
                modifiedAreas: updateGroupInArea(state.modifiedAreas, JSON.parse(JSON.stringify(action.group)), action.area_id, action.devices, action.sensors, action.intensity_changed, action.onlyGroup),
                updated: (state.updated + 1) % 2
            }

        case REMOVE_GROUP:
            return {
                ...state,
                areas: removeGroupInArea(state.areas, action.group_id, action.area_id),
                modifiedAreas: removeGroupInArea(state.modifiedAreas, action.group_id, action.area_id),
                updated: (state.updated + 1) % 2
            }

        case SELECT_DEVICE:
            return { ...state, selectedDevice: action.device }

        case MODIFY_DEVICES_LIST:
            return {
                ...state,
                modifiedAreas: modifiedAreas(
                    state.areas,
                    action.search_text,
                    action.sort_field,
                    action.reverse_sort_activated
                )
            }

        case REMOVE_PLACE:
            let areasInPlace = state.areas.filter(area => area.place === action.name);
            return {
                ...state,
                unused_devices: [
                    ...state.unused_devices,
                    devicesFromAreas(areasInPlace).map(device => { return {
                        ...device,
                        position: null,
                        area_id: -1,
                        group_id: -1,
                    }})
                ],
                sensors: state.sensors.map(sensor => {
                    if (areasInPlace.find(area => area.id === sensor.area_id))
                        return {
                            ...sensor,
                            position: null,
                            area_id: -1,
                            group_id: -1,
                        }
                    return sensor;
                }),
                areas: state.areas.filter(area => area.place !== action.name),
                modifiedAreas: state.modifiedAreas.filter(area => area.place !== action.name)
            }

        case GET_SENSORS:
            return {
                ...state,
                areas: state.areas.map(area => {
                    let areaSensors = action.sensors.filter(sensor => sensor.area_id === area.id).map(device => frontEndSensor(device));
                    area.sensors = areaSensors.filter(device => !device.group_id || device.group_id < 0);
                    if (!area.groups) area.groups = [];
                    area.groups = area.groups.map(group => {
                        return {...group, sensors: areaSensors.filter(device => device.group_id === group.id)}
                    });
                    return area;
                }),
                sensors: action.sensors.map(device => frontEndSensor(device))
            };

        case UPDATE_SENSOR:
            let sensor = findSensorById(action.sensor.id, state);
            if (!sensor) return state;
            let previousSensorArea = sensor?.area_id || -1;
            let previousSensorGroup = sensor?.group_id || -1;
            sensor = {
                ...sensor,
                area_id: action.sensor.area_id || -1,
                group_id: previousSensorArea === action.sensor.area_id ? action.sensor.group_id : -1,
                position: action.sensor.position,
                deleted: action.sensor.deleted
            };
            return {
                ...state,
                sensors: state.sensors.map(s => {
                    if (s.id === sensor.id) return sensor;
                    return s;
                }),
                areas: updatedAreasForSensor(sensor, state.areas, previousSensorArea, previousSensorGroup),
                modifiedAreas: updatedAreasForSensor(sensor, state.modifiedAreas, previousSensorArea, previousSensorGroup),
                updated: (state.updated + 1) % 2
            };

        case REMOVE_SENSOR:
            let sensorToRemove = findSensorById(action.sensor_id, state);
            if (!sensorToRemove) return state;
            let previousAreaOfRemovedSensor = sensorToRemove?.area_id || -1;
            let previousGroupOfRemovedSensor = sensorToRemove?.group_id || -1;
            sensorToRemove = {
                ...sensorToRemove,
                area_id: -1,
                position: undefined,
                group_id: -1
            };
            if (action.permanent) sensorToRemove.deleted = true;
            return {
                ...state,
                sensors: state.sensors.map(s => {
                    if (s.id === sensorToRemove.id) return sensorToRemove;
                    return s;
                }),
                areas: updatedAreasForSensor(sensorToRemove, state.areas, previousAreaOfRemovedSensor, previousGroupOfRemovedSensor),
                modifiedAreas: updatedAreasForSensor(sensorToRemove, state.modifiedAreas, previousAreaOfRemovedSensor, previousGroupOfRemovedSensor),
                updated: (state.updated + 1) % 2
            };

        case GET_SENSOR_EVENTS:
            let newEvents = {...state.events};
            newEvents[action.id] = action.sensor_events;
            return {...state, events: newEvents};

        case GET_ALL_SENSOR_EVENTS:
            return {...state, events: action.sensor_events};

        default:
            return state
    }
}