import React from "react";
import PropTypes from "prop-types";
import "../../../styles/tabs/reports/all_data.css"
import ErrorsChart from "../../charts/errors_chart";
import SelectBox from "../../boxes/selectbox";
import {
    allDataFromReports,
    monthlyAverageDataFromReports,
    monthlyDataFromReports,
    monthsFromReports, yearlyAverageDataFromReports, yearlyDataFromReports,
    yearsFromReports
} from "../../../../utils/reports";

const MONTHLY = 0;
const MONTHLY_AVERAGE = 1;
const YEARLY = 2;
const YEARLY_AVERAGE = 3;
const SINCE = 4;

const Categories = [
    {Name: "Monthly", Value: MONTHLY},
    {Name: "Monthly Average", Value: MONTHLY_AVERAGE},
    {Name: "Yearly", Value: YEARLY},
    {Name: "Yearly Average", Value: YEARLY_AVERAGE},
    {Name: "Since", Value: SINCE},
];
const DefaultGroups = [{Name: "All Groups", Value: { area_id: -1, group_id: -1 }}];

export default class AllData extends React.Component {

    constructor(props) {

        super(props);
        let months = monthsFromReports(this.props.Reports, -1, -1);
        let month = (months.length > 0 ? months[0].Value : -1);
        let group = this.props.Groups.length > 0 ? this.props.Groups[0].Value : DefaultGroups.Value;
        this.state = {
            category: MONTHLY,
            group: group,
            period: month,
            data: {
                total_power: 0,
                on_hours: 0,
                total_availability_errors: 0,
                total_led_load_errors: 0,
                total_input_voltage_errors: 0,
                total_temperature_errors: 0,
                total_sync_intensity_errors: 0,
                total_errors: 0,
            },
            // data: monthlyDataFromReports(this.props.Reports?.months_by_day, group.area_id, group.group_id, month),
        }
    }

    updateData = () => {

        if (this.state.period === 0) return null;
        let result = null;
        switch (this.state.category) {
            case MONTHLY:
                result = monthlyDataFromReports(this.props.Reports.months_by_day, this.state.group.area_id, this.state.group.group_id, this.state.period);
                break;
            case MONTHLY_AVERAGE:
                result = monthlyAverageDataFromReports(this.props.Reports.months_by_day, this.state.group.area_id, this.state.group.group_id);
                break;
            case YEARLY:
                result = yearlyDataFromReports(this.props.Reports.years_by_month, this.state.group.area_id, this.state.group.group_id, this.state.period);
                break;
            case YEARLY_AVERAGE:
                result = yearlyAverageDataFromReports(this.props.Reports.years_by_month, this.state.group.area_id, this.state.group.group_id);
                break;
            default:
                result = allDataFromReports(this.props.Reports.years_by_month, this.state.group.area_id, this.state.group.group_id);
                break;
        }
        this.setState({...this.state, data: result});
    }

    title = () => {

        return "All Data";
    }

    categoryCases = () => {

        return Categories;
    }

    groupCases = () => {

        return DefaultGroups.concat(this.props.Groups)
    }

    onCategorySelected = category => {

        if (category === this.state.category) return;

        let period = this.state.period;
        if (category === MONTHLY) {
            let months = monthsFromReports(this.props.Reports, this.state.group.area_id, this.state.group.group_id);
            period = (months.length > 0 ? months[0].Value : -1);
        } else if (category === YEARLY) {
            let years = yearsFromReports(this.props.Reports, this.state.group.area_id, this.state.group.group_id);
            period = (years.length > 0 ? years[0].Value : -1);
        }

        this.setState({...this.state, category: parseInt(category), period: period}, this.updateData);
    }

    onGroupSelected = group => {

        this.setState({...this.state, group: group}, this.updateData);
    }

    selectedGroup = () => {

        return this.props.Groups.find(group => group.Value.area_id === this.state.group.area_id && group.Value.group_id === this.state.group.group_id);
    }

    onYearSelected = year => {

        this.setState({...this.state, period: year}, this.updateData);
    }

    onMonthSelected = month => {

        this.setState({...this.state, period: month}, this.updateData);
    }

    render() {

        return(
            <div className={this.props.VlClass ? this.props.VlClass : "vl_all_data_tab"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_all_data_info_bar">
                    <p className="vl_all_data_title">{this.title()}</p>
                    <SelectBox Callback={this.onMonthSelected}
                            Cases={monthsFromReports(this.props.Reports, this.state.group?.area_id, this.state.group?.group_id)}
                            VlStyle={{marginLeft: "auto", display: this.state.category === 0 ? "block" : "none"}}/>
                    <SelectBox Callback={this.onYearSelected}
                            Cases={yearsFromReports(this.props.Reports, this.state.group?.area_id, this.state.group?.group_id)}
                            VlStyle={{marginLeft: "auto", display: this.state.category === 2 ? "block" : "none"}}/>
                    <SelectBox Callback={this.onGroupSelected}
                               Cases={this.groupCases() }
                               Selected={this.selectedGroup()}
                               VlStyle={{marginLeft: "2%"}}/>
                    <SelectBox Callback={this.onCategorySelected}
                               Cases={this.categoryCases() }
                               VlStyle={{marginLeft: "2%", marginRight: "7.5%"}}/>
                </div>
                <div className="vl_all_data_chart_container">
                    <ErrorsChart VlWidth={"70%"} Errors={this.state.data} />
                    <div className="vl_all_data_info_container">
                        <div className="vl_all_data_info_power">
                            <p className="vl_all_data_card_title">Power Consumption</p>
                            <div className="vl_all_data_info_power_icon"/>
                            <p className="vl_all_data_card_value">{this.state.data?.total_power || 0} KW</p>
                        </div>
                        <div className="vl_all_data_info_on_hours">
                            <p className="vl_all_data_card_title">Hours On</p>
                            <div className="vl_all_data_info_on_hours_icon"/>
                            <p className="vl_all_data_card_value">{this.state.data?.on_hours || 0} hours</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AllData.propTypes = {
    Reports: PropTypes.object.isRequired,
    Groups: PropTypes.array.isRequired,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}