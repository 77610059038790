import {ENQUEUE_ERROR, POP_ERROR} from "./types";

export const showError = error => dispatch => {

    dispatch({
        type: ENQUEUE_ERROR,
        error: error
    });
}

export const hideLastError = () => dispatch => {

    dispatch({type: POP_ERROR});
}