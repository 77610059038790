import {
    GET_LAST_NOTIFICATIONS, GET_UNREAD_NOTIFICATIONS,
    MARK_AS_SEEN
} from "../actions/types";

const initialState = {
    notifications: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {

    switch(action.type) {

        case GET_LAST_NOTIFICATIONS:
            action.notifications.sort((first, second) => {
                return second.time - first.time;
            });
            return {...state, notifications: action.notifications};
        case GET_UNREAD_NOTIFICATIONS:
            let result = [...state.notifications];
            result.push(...action.notifications.filter(notification => {
                return !state.notifications.find(n => n.id === notification.id);
            }));
            result.sort((first, second) => {
                return second.time - first.time;
            });
            return {...state, notifications: result};
        case MARK_AS_SEEN:
            return {
                ...state,
                notifications: state.notifications.map(notification => {
                    notification.seen = true
                    return notification
                })
            };
        default:
            return state;
    }
}