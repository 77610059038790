import React from 'react'
import PropTypes from "prop-types";
import CanvasJSReact from "../../../lib/canvasjs.react";
let CanvasJSChart = CanvasJSReact.CanvasJSChart;


export default class PowerChart extends React.Component {

    selectedPowerList = () => {

        let power_list = [];
        if (this.props.SelectedGroup < 0) {
            power_list = this.props.Data[this.props.Data.length - 1].power_list;
        } else {
            let group = this.props.Data.find(group =>
                group.area_id === this.props.SelectedArea &&
                group.group_id === this.props.SelectedGroup
            );
            if (!group) return [];
            power_list = group.power_list;
        }
        return power_list;
    }

    points = () => {

        if (this.props.SelectedDate < 0) {
            return []
        }

        let power_list = this.selectedPowerList();
        let data = power_list.filter(power => {
            let date = new Date(power.period * 1000);
            let selected_date = new Date(this.props.SelectedDate * 1000);
            return date.getFullYear() === selected_date.getFullYear() && date.getMonth() === selected_date.getMonth()
        });
        if (data.length === 0) return []

        return data[0].intervals.map(interval => {
            return {label: interval.time.substring(0, this.props.UseYear ? 7 : 10), y: interval.power}
        })
    }

    canvasOptions = () => {

        return {
            theme: "light2",
            animationEnabled: true,
            title: {
                text: this.props.Title,
            },
            axisY: {
                title: "KWatt/Hour",
                suffix: " Kwh"
            },
            data: [{
                type: "line",
                dataPoints: this.props.UseDataAsPoints ? this.props.Data : this.points()
            }]
        }
    }

    render() {

        return (
                <CanvasJSChart options = {this.canvasOptions()}
                               onRef={ref => this.chart = ref}
                               containerProps={{
                                   width: this.props.VlWidth ? this.props.VlWidth : "100%",
                                   height: this.props.VlWidth ? this.props.VlHeight : "90%",
                               }}
                />
        );
    }
}

PowerChart.propTypes = {
    Data: PropTypes.array.isRequired,
    Title: PropTypes.string,
    UseDataAsPoints: PropTypes.bool,
    UseYear: PropTypes.bool,
    SelectedDate: PropTypes.number,
    SelectedArea: PropTypes.number,
    SelectedGroup: PropTypes.number,
    VlWidth: PropTypes.string,
    VlHeight: PropTypes.string,
}
