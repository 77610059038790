import React from 'react'
import "../../styles/map/footer.css"
import PropTypes from "prop-types";

export default class MapFooter extends React.Component {

    render() {
        return (
            <div className={"vl_map_footer " + (this.props.VlClass || "")}
                 style={this.props.VlStyle || {}}>
                <button className="vl_map_footer_delete_button"
                        onClick={this.props.DeleteCallback}>Delete Map</button>
            </div>
        )
    }
}

MapFooter.propTypes = {
    DeleteCallback: PropTypes.func.isRequired,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
