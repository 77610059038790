import React from 'react'
import "../styles/general.css"
import "../styles/pages/reports.css"
import {connect} from "react-redux";
import {getAreas} from "../../actions/areas";
import {getSensors, getAllEvents} from "../../actions/sensors";
import {getReports, getHistogram, getHistogramMeta, generateReport, clearGeneratedReport} from "../../actions/reports";
import Tabs from "../components/general/tabs";
import PowerConsumption from "../components/tabs/reports/power_consumption";
import PowerDistribution from "../components/tabs/reports/power_distribution";
import CustomReports from "../components/tabs/reports/custom";
import AllData from "../components/tabs/reports/all_data";
import SensorEvents from "../components/tabs/reports/sensor_events";
import {sensorsListToLabelValueFormat} from "../../utils/sensors";

class Reports extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            Selected: "Consumption"
        }
    }

    componentDidMount() {

        if (this.props.areas.length === 0) {
            this.props.getAreas();
        }
        this.props.getReports();
        this.props.getHistogramMeta();
        this.props.getSensors();
        this.props.getAllEvents();
    }

    groupsFromAreas = () => {

        if (!this.props.areas)
            return [];

        let result = [];
        this.props.areas.forEach(area => {
            result = result.concat(area.groups.map(group => {
                return {Name: group.name + " ( " + area.name + " ) ", Value: {area_id: area.id, group_id: group.id}};
            }))
        })
        return result;
    }

    tabFromSelected = () => {

        switch (this.state.Selected) {

            case "Sensors":
                return <SensorEvents Sensors={sensorsListToLabelValueFormat(this.props.sensors)} Events={this.props.sensor_events}/>
            case "Consumption":
                return <PowerConsumption Reports={this.props.reports}
                                         Groups={this.groupsFromAreas()} />;
            case "Distribution":
                return <PowerDistribution GetHistogram={this.props.getHistogram}
                                          HistogramsMeta={this.props.histograms_meta}
                                          Histograms={this.props.histograms}
                                          Groups={this.groupsFromAreas()} />
            case "Custom":
                return <CustomReports GeneratedReports={this.props.generated_reports}
                                      Areas={this.props.areas}
                                      UnusedDevices={this.props.unused_devices}
                                      Sensors={sensorsListToLabelValueFormat(this.props.sensors)}
                                      GenerateReport={this.props.generateReport}
                                      CleanReports={this.props.clearGeneratedReport} />
            default:
                return <AllData Reports={this.props.reports}
                                Groups={this.groupsFromAreas()} />
        }
    }

    render() {

        return (
            <div className="vl_reports_panel">
                <h1 className="vl_header">Reports</h1>
                <Tabs VlStyle={{height: "8%", marginLeft: "7.5%", width: "50%"}}
                      Style="set"
                      Titles={["Consumption", "Distribution", "All data", "Sensors", "Custom"]}
                      Selected={this.state.Selected}
                      Callback={(tab) => { this.setState({...this.state, Selected: tab}) }}/>
                {this.tabFromSelected()}
            </div>
        )
    }
}

const mapStateToReportsProps = state => ({
    areas: state.areas.areas,
    unused_devices: state.areas.unused_devices,
    reports: state.reports.reports,
    histograms_meta: state.reports.histograms_meta,
    histograms: state.reports.histograms,
    sensors: state.areas.sensors,
    sensor_events: state.areas.events,
    generated_reports: state.reports.generated_reports,
})

export default connect(mapStateToReportsProps, {
    getAreas,
    getReports,
    getHistogram,
    getHistogramMeta,
    getSensors,
    getAllEvents,
    generateReport,
    clearGeneratedReport
})(Reports);
