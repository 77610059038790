import React from 'react'
import "../../../styles/panels/reports/custom_report.css"
import PropTypes from "prop-types";
import SelectBox from "../../boxes/selectbox";
import DateEditBox from "../../boxes/dateeditbox";
import Shifter from "../../layout/shifter";

const SUBJECT_TYPES = [
    {Name: "Device", Value: "device", Data: {Name: "Device", Value: "device"}},
    {Name: "Group", Value: "group", Data: {Name: "Group", Value: "group"}},
    {Name: "Area", Value: "area", Data: {Name: "Area", Value: "area"}},
    {Name: "Sensor", Value: "sensor", Data: {Name: "Sensor", Value: "sensor"}}
];

const DEVICE_REPORT_TYPES = [
    {Name: "Consumption", Value: "consumption", Data: {Name: "Consumption", Value: "consumption"}},
    {Name: "Distribution", Value: "distribution", Data: {Name: "Distribution", Value: "distribution"}},
    {Name: "Errors", Value: "errors", Data: {Name: "Errors", Value: "errors"}}
];

const SENSOR_REPORT_TYPES = [
    {Name: "Triggers", Value: "triggers", Data: {Name: "Triggers", Value: "triggers"}},
    {Name: "Intervals", Value: "intervals", Data: {Name: "Intervals", Value: "intervals"}}
];

const INTERVAL_TYPES = [
    {Name: "By Hour", Value: "hour", Data: {Name: "By Hour", Value: "hour"}},
    {Name: "By Day", Value: "day", Data: {Name: "By Day", Value: "day"}},
    {Name: "By Month", Value: "month", Data: {Name: "By Month", Value: "month"}},
    {Name: "By Year", Value: "year", Data: {Name: "By Year", Value: "year"}}
];

export default class CustomReport extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            subject_type: this.props.Report.subject_type,
            subject: this.props.Report.subject,
            report_type: this.props.Report.report_type,
            interval: this.props.Report.interval,
            startTimestamp: this.props.Report.start,
            endTimestamp: this.props.Report.end,
        }
    }

    optionFromArea = (area) => {

        return {Name: area.name, Value: area.id};
    }

    optionFromGroup = (group) => {

        return {
            Name: group.name+" ("+this.props.Areas.find(a => a.id === group.area_id).name+")",
            Value: group.id+"_"+group.area_id
        };
    }

    optionFromDevice = (device) => {

        return {
            Name: device.name+(device.area_id && device.area_id > 0 ? " ("+this.props.Areas.find(a => a.id === device.area_id).name+")": ""),
            Value: device.id
        };
    }

    optionFromSensor = (sensor) => {

        return {
            Name: sensor.Name,
            Value: sensor.Value.id,
        };
    }


    defaultSubject = (type) => {

        if (type?.Value === "device") {
            return this.props.Devices.length > 0 ? this.devices()[0] : null;
        } else if (type?.Value === "group") {
            return this.props.Groups.length > 0 ? this.groups()[0] : null;
        } else if (type?.Value === "area") {
            return this.props.Areas.length > 0 ? this.areas()[0] : null;
        } else if (type?.Value === "sensor") {
            return this.props.Sensors.length > 0 ? this.sensors()[0] : null;
        }
        return null;
    }

    defaultReportType = () => {

        if (this.state.subject_type?.Value === "sensor") {
            return SENSOR_REPORT_TYPES[0];
        }
        return DEVICE_REPORT_TYPES[0];
    }

    updateReport = () => {

        this.props.ChangeCallback({
            id: this.props.Report.id,
            start: this.state.startTimestamp,
            end: this.state.endTimestamp,
            subject_type: this.state.subject_type || SUBJECT_TYPES[0],
            subject: this.state.subject || this.defaultSubject(this.state.subject_type),
            report_type: this.state.report_type || this.defaultReportType(),
            interval: this.state.interval || INTERVAL_TYPES[0],
        });
    }

    startTimeChanged = start_timestamp => {

        this.setState({...this.state, startTimestamp: start_timestamp}, () => { this.render(); this.updateReport(); });
    }

    endTimeChanged = end_timestamp => {

        this.setState({...this.state, endTimestamp: end_timestamp}, () => { this.render(); this.updateReport(); });
    }

    selectSubjectType = (t, value) => {

        if (value.Value === "sensor" && this.state.subject_type.Value !== "sensor") {
            this.setState({
                ...this.state,
                subject: this.defaultSubject(value),
                report_type: SENSOR_REPORT_TYPES[0],
                subject_type: value
            }, () => { this.updateReport(); });
        } else if (value.Value !== "sensor" && this.state.subject_type.Value === "sensor") {
            this.setState({
                ...this.state,
                subject: this.defaultSubject(value),
                report_type: DEVICE_REPORT_TYPES[0],
                subject_type: value
            }, () => { this.updateReport(); });
        } else if (value.Value !== this.state.subject_type.Value) {
            this.setState({
                ...this.state,
                subject: this.defaultSubject(value),
                subject_type: value
            }, () => { this.updateReport(); });
        }
    }

    selectReportType = (t, value) => {

        this.setState({...this.state, report_type: value}, () => { this.updateReport(); });
    }

    selectSubject = (t, subject) => {

        this.setState({...this.state, subject: subject}, () => { this.updateReport(); });
    }

    selectInterval = (t, value) => {

        this.setState({...this.state, interval: value}, () => { this.updateReport(); });
    }

    areas = () => {

        return this.props.Areas.map(area => {
            return {...this.optionFromArea(area), Data: this.optionFromArea(area)};
        });
    }

    groups = () => {

        return this.props.Groups.map(group => {
            return {...this.optionFromGroup(group), Data: this.optionFromGroup(group)};
        });
    }

    devices = () => {

        return this.props.Devices.map(device => {
            return {...this.optionFromDevice(device), Data: this.optionFromDevice(device)};
        });
    }

    sensors = () => {

        return this.props.Sensors.map(sensor => {
           return {...this.optionFromSensor(sensor), Data: this.optionFromSensor(sensor)};
        });
    }

    reportTypes = () => {

        if (this.state.subject_type.Value === "sensor") {
            return SENSOR_REPORT_TYPES;
        }
        return DEVICE_REPORT_TYPES;
    }

    subjects = () => {

        if (this.state.subject_type.Value === "device") {
            return this.devices();
        } else if (this.state.subject_type.Value === "group") {
            return this.groups();
        } else if (this.state.subject_type.Value === "area") {
            return this.areas();
        } else if (this.state.subject_type.Value === "sensor") {
            return this.sensors();
        }
        return [];
    }

    noIntervals = () => {

        return this.state.report_type?.Value === "distribution" ||
            this.state.report_type?.Value === "intervals" ||
            this.state.report_type?.Value === "errors";
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_custom_report"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}
                 key={this.props.Report.id}
            >
                <div className="vl_custom_report_main">
                    <div className="vl_custom_report_interval">
                        <Shifter Size="3%"/>
                        <DateEditBox Callback={this.startTimeChanged}
                                     Timestamp={this.state.startTimestamp}
                                     MaxTimestamp={this.state.endTimestamp}
                                     UseMonth={true}
                                     UseDay={true}
                        />
                        <Shifter Size="5%"/>
                        <DateEditBox Callback={this.endTimeChanged}
                                     Timestamp={this.state.endTimestamp}
                                     MinTimestamp={this.state.startTimestamp}
                                     UseMonth={true}
                                     UseDay={true}
                        />
                        <Shifter Size="50%"/>
                    </div>
                    <div className="vl_custom_report_entity">
                        <SelectBox VlStyle={{marginLeft: "3%"}}
                                   Callback={this.selectSubjectType}
                                   Cases={SUBJECT_TYPES}
                                   Selected={this.state.subject_type}
                        />
                        <SelectBox VlStyle={{marginLeft: "5%"}}
                                   Callback={this.selectReportType}
                                   Cases={this.reportTypes()}
                                   Selected={this.state.report_type}
                        />
                        <SelectBox VlStyle={{marginLeft: "5%"}}
                                   Callback={this.selectSubject}
                                   Cases={this.subjects()}
                                   Selected={this.state.subject}
                        />
                        <SelectBox VlStyle={{marginLeft: "5%"}}
                                   Callback={this.selectInterval}
                                   Cases={INTERVAL_TYPES}
                                   Selected={this.state.interval}
                                   Disabled={this.noIntervals()}
                        />
                    </div>
                </div>
                <div className="vl_custom_report_remove_icon"
                     onClick={() => this.props.DeleteCallback(this.props.Report.id)}/>
            </div>
        )
    }
}

CustomReport.propTypes = {
    Report: PropTypes.object,
    Areas: PropTypes.array,
    Groups: PropTypes.array,
    Devices: PropTypes.array,
    Sensors: PropTypes.array,

    DeleteCallback: PropTypes.func,
    ChangeCallback: PropTypes.func,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
