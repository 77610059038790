import React from 'react'
import "../../styles/tools/live_status.css"
import PropTypes from "prop-types";

export default class LiveStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tooltipShown: false,
        }
    }

    statusClass = () => {

        switch(this.props.Status.connected) {
            case 0:
                return "vl_live_status_error";
            case 1:
                return "vl_live_status_ok";
            default:
                return "vl_live_status_error";
        }
    }

    showTooltip = show => {

        if (this.state.tooltipShown !== show)
            this.setState({...this.state, tooltipShown: show});
    }

    tooltip = () => {

        if (!this.state.tooltipShown || !this.props.Status.info) return <></>;
        let w = this.props.Status.info.length * 10 + 10 + "px";
        return (
            <p className="vl_live_status_tooltip" style={{width: w}}>
                {this.props.Status.info}
            </p>
        )
    }

    render() {

        return (
            <div className={"vl_live_status_container " + (this.props.VlClass || "")}
                 style={this.props.VlStyle || {}}
                 onMouseEnter={() => this.showTooltip(true)}
                 onMouseLeave={() => this.showTooltip(false)}
            >
                <div className={this.statusClass()}/>
                {this.tooltip()}
            </div>
        )
    }
}

LiveStatus.propTypes = {
    Status: PropTypes.object.isRequired,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
