import React from 'react'
import PropTypes from "prop-types";
import CanvasJSReact from "../../../lib/canvasjs.react";
import { timestampToFullDateAndTime } from "../../../utils/time";
let CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ButtonEvents = {
    "Press": 2,
    "Release": 1,
    2: "Press",
    1: "Release"
};

const OccupancyEvents = {
    "Occupied": 2,
    "Unoccupied": 1,
    2: "Occupied",
    1: "Unoccupied"
};

export default class IntervalsChart extends React.Component {

    points = () => {

        let intervals = this.props.Data.intervals;
        if (intervals[intervals.length - 1].start < this.props.Data.end) {
            intervals.push({start: this.props.Data.end, event_type: intervals[intervals.length - 1].event_type});
        }

        return intervals.map(interval => {
            return {label: timestampToFullDateAndTime(interval.start), x: interval.start, y: this.valueFromInterval(interval)};
        })
    }

    percentOfEventType = eventType => {

        let currentIsEvent = false;
        let lastTimestamp = this.props.Data.start;
        let distance = this.props.Data.end - this.props.Data.start;
        return this.props.Data.intervals.reduce((r, i) => {
            if (currentIsEvent && i.event_type !== eventType) {
                currentIsEvent = false;
                return r + (i.start - lastTimestamp) / distance * 100;
            }
            if (i.event_type === eventType) {
                let currentLastTimestamp = lastTimestamp;
                lastTimestamp = i.start;
                if (currentIsEvent) {
                    return r + (i.start - currentLastTimestamp) / distance * 100;
                }
                currentIsEvent = true;
            }
            return r;
        }, 0)
    }

    valueFromInterval = interval => {

        if (this.props.Data.id.includes("button") && interval.event_type in ButtonEvents) {
            return ButtonEvents[interval.event_type];
        }
        if (this.props.Data.id.includes("occupancy") && interval.event_type in OccupancyEvents) {
            return OccupancyEvents[interval.event_type];
        }
        return 1;
    }

    XLabelFormatter = e => {

        return timestampToFullDateAndTime(e.value);
    }

    YLabelFormatter = e => {

        if (this.props.Data.id.includes("button")) {
            return ButtonEvents[e.value];
        }
        if (this.props.Data.id.includes("occupancy")) {
            return OccupancyEvents[e.value] + " " + this.percentOfEventType(OccupancyEvents[e.value]).toFixed(1) + "%";
        }
        return "Unknown";
    }

    contentFormatter = e => {

        if (this.props.Data.id.includes("button")) {
            return ButtonEvents[e.entries[0].dataPoint.y] + " ( " + timestampToFullDateAndTime(e.entries[0].dataPoint.x) + " )";
        }
        if (this.props.Data.id.includes("occupancy")) {
            return OccupancyEvents[e.entries[0].dataPoint.y] + " ( " + timestampToFullDateAndTime(e.entries[0].dataPoint.x) + " )";
        }
        return "Unknown";
    }

    canvasOptions = () => {

        return {
            theme: "light2",
            animationEnabled: true,
            title: {
                text: this.props.Title,
            },
            axisY: {
                title: "Status",
                valueFormatString: "#",
                minimum: 0.9,
                maximum: 2.1,
                interval: 1,
                labelFormatter: this.YLabelFormatter
            },
            axisX: {
                valueFormatString: "#",
                labelFormatter: this.XLabelFormatter
            },
            toolTip:{
                contentFormatter: this.contentFormatter
            },
            data: [{
                type: "stepLine",
                dataPoints: this.points(),
            }]
        }
    }

    render() {

        return (
            <CanvasJSChart options = {this.canvasOptions()}
                           onRef={ref => this.chart = ref}
                           containerProps={{
                               width: this.props.VlWidth ? this.props.VlWidth : "100%",
                               height: this.props.VlWidth ? this.props.VlHeight : "100%",
                           }}
            />
        );
    }
}

IntervalsChart.propTypes = {
    Data: PropTypes.object,
    Title: PropTypes.string,
    VlWidth: PropTypes.string,
    VlHeight: PropTypes.string,
}