import React from "react";
import "../../styles/tools/slider.css"
import PropTypes from "prop-types";

export default class Slider extends React.Component {

    constructor(props) {

        super(props);
        this.trackRef = React.createRef();
        this.state = {
            isMoving: false,
            size: -1,
            borderPos: -1,
        }
    }

    componentDidMount() {

        window.addEventListener("mouseup", this.setNotMoving);
        window.addEventListener("resize", this.fixSize);
        this.fixSize();
    }

    componentWillUnmount() {

        window.removeEventListener("mouseup", this.setNotMoving);
        window.removeEventListener("resize", this.fixSize);
    }

    fixSize = () => {

        if (!this.trackRef.current) return;
        let rect = this.trackRef.current.getBoundingClientRect();
        if (this.props.Horizontal) {
            if (rect.left !== this.state.borderPos || rect.width !== this.state.size) {
                this.setState({...this.state, size: rect.width, borderPos: rect.left});
            }
        } else {
            if (rect.bottom !== this.state.borderPos || rect.height !== this.state.size) {
                this.setState({...this.state, size: rect.height, borderPos: rect.bottom});
            }
        }
    }

    setIsMoving = () => {

        this.setState({...this.state, isMoving: true});
    }

    setNotMoving = () => {

        if (!this.state.isMoving) return;
        this.stopMovingCallback();
        this.setState({...this.state, isMoving: false});
    }

    stopMovingCallback = () => {

        if (typeof this.props.StopCallback === 'function') this.props.StopCallback();
    }

    containerClass = () => {

        return (this.props.Horizontal ? "vl_slider_container_horizontal" : "vl_slider_container_vertical") +
            (" " + (this.props.VlClass || ""));
    }

    trackClass = () => {

        return this.props.Horizontal ? "vl_slider_track_horizontal" : "vl_slider_track_vertical";
    }

    thumbClass = () => {

        return this.props.Horizontal ? "vl_slider_thumb_horizontal" : "vl_slider_thumb_vertical";
    }

    thumbStyle = () => {

        if (this.props.Horizontal) {
            let value = this.state.size * (this.props.Value - this.props.Min) / (this.props.Max - this.props.Min);
            return {left: String(value) + "px"};
        } else {
            let value = this.state.size * (this.props.Value - this.props.Min) / (this.props.Max - this.props.Min);
            return {bottom: String(value) + "px"};
        }
    }

    onMouseMove = e => {

        if (this.state.isMoving) {
            this.changeValue(this.props.Horizontal ? e.pageX : e.pageY);
            e.preventDefault();
        }
    }

    onTrackClicked = e => {

        this.changeValue(this.props.Horizontal ? e.pageX : e.pageY);
        setTimeout(this.stopMovingCallback, 200);
        e.preventDefault();
    }

    onThumbClicked = e => {

        this.setIsMoving();
        e.preventDefault();
    }

    changeValue = newPos => {

        let factor = (newPos - this.state.borderPos) / this.state.size;
        if (!this.props.Horizontal) factor = -factor;
        let value = this.props.Min + (this.props.Max - this.props.Min) * factor;
        if (value < this.props.Min) value = this.props.Min;
        if (value > this.props.Max) value = this.props.Max;
        this.props.Callback(value);
    }

    render() {

        return(
            <div className={this.containerClass()} onMouseMove={this.onMouseMove}>
                {/* Track */}
                <div className={this.trackClass()} onClick={this.onTrackClicked} ref={this.trackRef}/>
                {/* Thumb */}
                <div className={this.thumbClass()} style={this.thumbStyle()} onMouseDown={this.onThumbClicked}/>
            </div>
        )
    }
}

Slider.propTypes = {
    Callback: PropTypes.func.isRequired,
    StopCallback: PropTypes.func,

    Value: PropTypes.number.isRequired,
    Horizontal: PropTypes.bool,
    Min: PropTypes.number,
    Max: PropTypes.number,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}