import {CLEAN_ERROR, SIGN_IN, SIGN_OUT, SIGNING_IN, VERIFY_CODE} from './types';

export const signIn = (username, password) => dispatch => {

    let data = new URLSearchParams()

    data.append('username', username)
    data.append('password', password)

    dispatch({type: SIGNING_IN})

    fetch("http://" + window.location.hostname + ":8080/auth/login",
        {method: 'POST', credentials: 'include', mode: 'cors', body: data})
        .then(response => {

            response.json().then(client => {
                dispatch({
                    type: SIGN_IN,
                    status: response.status,
                    username: client.username,
                    password: password,
                    verified: client.verified
                })
            })
            .catch(function() {
                dispatch({
                    type: SIGN_IN,
                    status: response.status,
                    username: username,
                    password: password,
                    verified: false
                })
            })
        })
        .catch(function(error){

            dispatch({
                type: SIGN_IN,
                status: 500,
            })
        })
};

export const signOut = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/auth/logout",
        {method: 'POST', credentials: 'include', mode: 'cors'})
        .then(response => {

            dispatch({
                type: SIGN_OUT,
                success: (response.status === 200)
            })
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const verifyCode = (code) => dispatch => {

    let data = new URLSearchParams()
    data.append('code', code)

    dispatch({type: SIGNING_IN});

    fetch("http://" + window.location.hostname + ":8080/auth/verify",
        {method: 'POST', credentials: 'include', mode: 'cors', body: data})
        .then(response => {

            dispatch({
                type: VERIFY_CODE,
                status: response.status
            })
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const cleanError = () => dispatch => {

    dispatch({type: CLEAN_ERROR})
};

export const sendEmail = (email) => dispatch => {

};
