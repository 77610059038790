import React from "react";
import PropTypes from "prop-types";
import "../../../styles/panels/devices/add_group.css"
import DevicesList from "../../lists/devices_list";
import EditBox from "../../boxes/editbox";
import VlButton from "../../buttons/vl_button";
import SensorsList from "../../lists/sensors_list";

export default class AddGroup extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            devices: this.devicesFromAreaAndGroup(),
            sensors: this.sensorsFromAreaAndGroup(),
            name: this.props.Group?.name || "",
            error: null,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.Area.unused_devices !== prevProps.Area.unused_devices) {
            this.setState({...this.state, devices: this.devicesFromAreaAndGroup()});
        }
    }

    title = () => {

        if (this.props.Group) return "Change Group '" + this.props.Group.name + "'";
        return "Add Group in '" + this.props.Area.name + "'";
    }

    devicesFromAreaAndGroup = () => {

        let result = [];
        if (this.props.Group && this.props.Group.devices) {
            result.push(...this.props.Group.devices.map(device => {
                return {...device, selected: true};
            }));
        }
        if (this.props.Area.devices) {
            result.push(...this.props.Area.devices.map(device => {
                return {...device, selected: false};
            }));
        }
        return result;
    }

    sensorsFromAreaAndGroup = () => {

        let result = [];
        if (this.props.Group && this.props.Group.sensors) {
            result.push(...this.props.Group.sensors.map(sensor => {
                return {...sensor, selected: true};
            }));
        }
        if (this.props.Area.sensors) {
            result.push(...this.props.Area.sensors.map(sensor => {
                return {...sensor, selected: false};
            }));
        }
        return result;
    }

    error = () => {

        return (
            <p className={this.state.error?.Type === "name" ? "vl_add_group_name_error" : "vl_add_group_select_error"}>
                {this.state.error?.Text || ""}
            </p>
        );
    }

    changeGroupName = name => {

        this.setState({...this.state, name: name, error: null});
    }

    triggerDevice = id => {

        this.setState({
            ...this.state,
            devices: this.state.devices.map(device => {
                if (device.id === id) {
                    return {...device, selected: !device.selected};
                }
                return device;
            }),
            error: null,
        });
    }

    triggerSensor = id => {

        this.setState({
            ...this.state,
            sensors: this.state.sensors.map(sensor => {
                if (sensor.id === id) {
                    return {...sensor, selected: !sensor.selected};
                }
                return sensor;
            }),
            error: null,
        });
    }

    save = () => {

        let selectedDevices = this.state.devices.filter(device => {
            return device.selected;
        }).map(device => {
            return {id: device.id, name: device.name};
        })
        let selectedSensors = this.state.sensors.filter(sensor => {
            return sensor.selected;
        }).map(sensor => {
            return {id: sensor.id, name: sensor.name};
        })
        if (selectedDevices.length === 0 && selectedSensors.length === 0 && !this.props.Group) {
            this.setState({...this.state, error: {Type: "select", Text: "No devices selected"}})
            return;
        }

        if (this.state.name.length === 0) {
            this.setState({...this.state, error: {Type: "name", Text: "Group name should be none empty"}})
            return;
        }
        this.props.SaveCallback(this.state.name, selectedDevices, selectedSensors, !!this.props.Group);
    }

    render() {

        return (
            <div className={this.props.VlClass || "vl_add_group"}
                 style={this.props.VlStyle || {}}>
                <div className="vl_add_group_header">
                    <h5 className="vl_add_group_header_text">{this.title()}</h5>
                </div>
                <div className="vl_add_group_main">
                    <EditBox Callback={this.changeGroupName}
                             VlClass="vl_add_group_main_name_edit"
                             Placeholder={"Enter group name"}
                             Disabled={!!this.props.Group}
                             Default={this.props.Group?.name}
                    />
                    {this.state.error?.Type === "name" ? this.error() : ""}
                    <h5 className="vl_add_group_choose_header">Choose devices</h5>
                    <div className="vl_add_group_select_error_container">
                        {this.state.error?.Type === "select" ? this.error() : ""}
                    </div>
                    <div className="vl_add_group_devices_list_container">
                        <DevicesList Type="Select"
                                     Devices={this.state.devices}
                                     Select={this.triggerDevice}
                        />
                        <SensorsList Type="Select"
                                     Sensors={this.state.sensors}
                                     Select={this.triggerSensor}
                        />
                    </div>
                    <VlButton Text="Cancel"
                              Style="negative"
                              VlStyle={{position: "absolute", bottom: "2.5vh", right: "12vw", width: "8vw"}}
                              Callback={this.props.CancelCallback}/>
                    <VlButton Text="Save"
                              VlStyle={{position: "absolute", bottom: "2.5vh", right: "3vw", width: "8vw"}}
                              Callback={this.save}/>
                </div>
            </div>
        )
    }
}

AddGroup.propTypes = {
    Area: PropTypes.object.isRequired,
    Group: PropTypes.object,

    SaveCallback: PropTypes.func.isRequired,
    CancelCallback: PropTypes.func.isRequired,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}