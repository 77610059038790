import React from 'react'
import "../../styles/tabs/activation_tab.css"
import PropTypes from "prop-types";
import Tabs from "../general/tabs";
import ActivationByDateTab from "./activation/by_date";
import ActivationBySensorTab from "./activation/by_sensor";
import ActivationByOptionalTab from "./activation/by_optional";
import ConfirmationDialog from "../dialogs/confirmation";


export default class ActivationTab extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            Selected: "By date",
            activationToDelete: null,
        }
    }

    componentDidMount() {

        this.props.GetScenes();
        this.props.GetActivationByDate();
    }

    tabFromSelected = () => {

        switch (this.state.Selected) {
            case "By date":
                return <ActivationByDateTab Activations={this.props.ActivationsByDate}
                                            Scenes={this.props.Scenes}
                                            CreateActivationByDate={this.props.CreateActivationByDate}
                                            UpdateActivationByDate={this.props.UpdateActivationByDate}
                                            RemoveActivationByDate={this.deleteRequest}
                                            SetActiveActivationByDate={this.props.SetActiveActivationByDate}
                />
            case "By sensor":
                return <ActivationBySensorTab />
            case "By event":
                return <ActivationByOptionalTab />
            default:
                return <></>
        }
    }

    deleteRequest = activation => {

        this.setState({...this.state, activationToDelete: activation});
    }

    delete = confirmed => {

        if (confirmed) { this.props.RemoveActivationByDate(this.state.activationToDelete.id); }
        this.setState({...this.state, activationToDelete: null});
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_activation_tab"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <Tabs VlStyle={{height: "60px", marginTop: "1.5vh", marginBottom: "10px", width: "80%"}}
                      Style="set"
                      Titles={["By date", "By sensor", "By event"]}
                      Selected={this.state.Selected}
                      Callback={(tab) => { this.setState({...this.state, Selected: tab}) }}/>
                {this.tabFromSelected()}
                {
                    this.state.activationToDelete ?
                        <ConfirmationDialog Callback={this.delete}
                                            Text={"Are you sure you want to delete '" + this.state.activationToDelete.name + "' ?"}/> :
                        ""
                }
            </div>
        )
    }
}

ActivationTab.propTypes = {
    ActivationsByDate: PropTypes.array,
    Scenes: PropTypes.array,

    GetScenes: PropTypes.func,
    GetActivationByDate: PropTypes.func,
    CreateActivationByDate: PropTypes.func,
    UpdateActivationByDate: PropTypes.func,
    RemoveActivationByDate: PropTypes.func,
    SetActiveActivationByDate: PropTypes.func,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}