import React from 'react'
import "../../styles/general/filter.css"
import SelectBox from "../boxes/selectbox"
import SearchBox from "../boxes/searchbox"
import PropTypes from "prop-types";

export default class Filter extends React.Component {

    render() {
        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_filter"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <SelectBox Callback={(Selected) => { this.props.SortChanged(Selected) }} Cases={this.props.Filters} />
                <button className={this.props.SortActivated ? "vl_sort_activated_icon" : "vl_sort_icon"} onClick={this.props.SortActivate}/>
                <SearchBox Callback={(Searched) => { this.props.SearchChanged(Searched) }} />
            </div>
        )}
}

Filter.propTypes = {
    SortActivate: PropTypes.func.isRequired,
    SortChanged: PropTypes.func.isRequired,
    SearchChanged: PropTypes.func.isRequired,

    Filters: PropTypes.array.isRequired,
    SortActivated: PropTypes.bool,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
