import React from 'react'
import "../../../styles/panels/scenes/add_scene.css"
import EditBox from "../../boxes/editbox"
import PropTypes from "prop-types";
import AreasList from "../../lists/areas_list";
import VlButton from "../../buttons/vl_button";
import {
    EditListFromAreasAndScene,
    TriggerAreaInEditList,
    TriggerGroupInEditList,
    ChangeGroupIntensityInEditList } from "../../../../utils/scene"
import CheckBox from "../../boxes/checkbox";
import {selectedGroupsFromAreas} from "../../../../utils/devices";

export default class AddScene extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            name: this.props.Scene?.name || "",
            opened : false,
            showRealtime: false,
            editAreas: EditListFromAreasAndScene(this.props.Areas, this.props.Scene),
            error: {text: "", type: ""},
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.showRealtime) {

            let newLiveUpdate = selectedGroupsFromAreas(this.state.editAreas).map(group => {
                return {area_id: group.area_id, group_id: group.id, intensity: group.intensity};
            });
            if (!prevState.showRealtime || this.state.groupsChanged) {
                this.setState({...this.state, groupsChanged: false}, () => {
                    if (newLiveUpdate.length > 0) {
                        this.props.RealtimeUpdate(newLiveUpdate);
                    }
                });
            }
        } else if (prevState.showRealtime) {
            this.props.RealtimeUpdate([], true);
        }
    }

    componentWillUnmount() {

        this.props.RealtimeUpdate([], true);
    }

    triggerEntry = (area_id, group_id) => {

        if (group_id)
            this.setState({
                ...this.state,
                editAreas: TriggerGroupInEditList(this.state.editAreas, area_id, group_id),
                groupsChanged: true,
            });
        else
            this.setState({
                ...this.state,
                editAreas: TriggerAreaInEditList(this.state.editAreas, area_id),
                groupsChanged: true,
            });
    }

    saveScene = () => {

        let groups = selectedGroupsFromAreas(this.state.editAreas);
        if (groups.length === 0) {
            this.setState({...this.state, error: {text: "No groups selected", type: "device"}});
        } else if (this.state.name.length === 0) {
            this.setState({...this.state, error: {text: "Name shouldn't be empty", type: "name"}});
        } else {
            this.props.SaveCallback(this.state.name, groups);
        }
    }

    error = type => {

        if (this.state.error.type !== type) return <></>;
        switch(this.state.error.type) {

            case "device":
                return <p className="vl_add_scene_device_error">{this.state.error.text}</p>
            case "name":
                return <p className="vl_add_scene_name_error">{this.state.error.text}</p>
            default:
                return <></>
        }
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_add_scene"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_add_scene_header">
                    <h5 className="vl_add_scene_header_text">{this.props.Scene ? "Edit Scene" : "Add Scene"}</h5>
                </div>
                <div className="vl_add_scene_main">
                    <EditBox Callback={text => this.setState({...this.state, name: text})}
                             VlClass="vl_add_scene_main_name_edit"
                             Placeholder={"Enter scene name"}
                             Default={this.props.Scene?.name || ""}
                             Disabled={!!this.props.Scene}
                    />
                    {this.error("name")}
                    <h5 className="vl_add_scene_choose_header">Choose areas and groups</h5>
                    <div className="vl_add_scene_areas_container">
                        {this.error("device")}
                        <AreasList Areas={this.state.editAreas}
                                   ChangeIntensity={(intensity, area_id, group) => {
                                       this.setState({
                                           ...this.state,
                                           editAreas: ChangeGroupIntensityInEditList(this.state.editAreas, intensity, area_id, group.id),
                                           groupsChanged: true,
                                       });
                                   }}
                                   Select={this.triggerEntry}/>
                    </div>
                    <CheckBox VlClass="vl_add_scene_show_realtime_checkbox"
                              Callback={() => this.setState({...this.state, showRealtime: !this.state.showRealtime})}
                              Checked={this.state.showRealtime}/>
                    <p className="vl_add_scene_show_realtime_text">Show realtime</p>
                    <VlButton Text="Cancel"
                              Style="negative"
                              VlStyle={{position: "absolute", bottom: "2.4vh", right: "12vw", width: "8vw"}}
                              Callback={this.props.CancelCallback}/>
                    <VlButton Text="Save Scene"
                              VlStyle={{position: "absolute", bottom: "2.4vh", right: "3vw", width: "8vw"}}
                              Callback={this.saveScene}/>
                </div>
            </div>
        )
    }
}

AddScene.propTypes = {
    Areas: PropTypes.array.isRequired,
    SaveCallback: PropTypes.func.isRequired,
    CancelCallback: PropTypes.func.isRequired,
    RealtimeUpdate: PropTypes.func.isRequired,

    Scene: PropTypes.object,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
