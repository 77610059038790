import React from 'react'
import "../../../styles/panels/devices/group.css"
import IntensityButton from "../../buttons/intensity"
import RemoveButton from "../../buttons/remove"
import ArrowButton from "../../buttons/arrow"
import Shifter from "../../layout/shifter"
import PropTypes from "prop-types";
import CheckBox from "../../boxes/checkbox";
import IntensityBox from "../../boxes/intensitybox";
import DevicesList from "../../lists/devices_list";
import EditButton from "../../buttons/edit";
import Lottie from "react-lottie-player";
import loadingJson from "../../../animations/IntensityLoading.json";
import {isArray} from "../../../../utils/arrays";
import NameEditBox from "../../boxes/nameeditbox";
import SensorsList from "../../lists/sensors_list";


export default class Group extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            opened : false,
            intensityBoxOpened: false,
            intensity: this.props.Group.intensity,
            name_edit: false,
            name_edit_error: "",
        }
    }

    changeName = newName => {

        let error = this.props.UpdateNameCallback(this.props.Group, newName, this.props.AreaId);
        this.setState({
            ...this.state,
            name_edit_error: error,
            name_edit: error && error.length > 0
        });
    }

    groupName = () => {

        if (this.state.name_edit)
            return <NameEditBox VlClass="vl_group_name_edit"
                                VlEditClass="vl_group_name_edit_input"
                                Error={this.state.name_edit_error}
                                Change={this.changeName}
                                Cancel={() => {this.setState({...this.state, name_edit: false, name_edit_error: ""})}}
                                Default={this.props.Group.name}/>
        return <h5 className="vl_group_name" onDoubleClick={() => { this.setState({...this.state, name_edit: true}) }}>{this.props.Group.name}</h5>
    }

    regularBody = () => {

        return (
            <div className="vl_group_container" disabled={this.props.IntensityProgress}>
                <p className={this.statusOK() ? "vl_group_status_ok" : "vl_group_status_error"} />
                {this.groupName()}
                <div className="vl_group_buttons">
                    {this.state.intensityBoxOpened ? "" : <Shifter Size="1.6rem"/>}
                    {this.state.intensityBoxOpened ? "" : <EditButton HideText
                                                                      VlStyle={{position: "relative", top: "13px", right: "30px"}}
                                                                      Callback={this.editCallback}/>}
                    {this.state.intensityBoxOpened ? <Shifter Size="11vw"/> : ""}
                    <IntensityButton Callback={this.triggerIntensityBox}/>
                    {this.state.intensityBoxOpened ? "" : <Shifter Size="1.6rem"/>}
                    {this.state.intensityBoxOpened ? "" : <RemoveButton Callback={this.removeCallback}/>}
                    {this.state.intensityBoxOpened ? "" : <Shifter Size="1.6rem"/>}
                    {this.state.intensityBoxOpened ? "" : <ArrowButton Status={this.state.opened} Callback={this.openCallback}/>}
                </div>
                {this.intensityBox()}
            </div>
        )
    }

    selectBody = () => {

        return (
            <div className="vl_group_container">
                <CheckBox VlStyle={{marginTop: "20px", marginLeft: "30px"}}
                          Callback={this.props.CheckCallback}
                          Checked={this.props.Checked ? this.props.Checked : false} />
                <h5 className="vl_group_name">{this.props.Group.name}</h5>
                <div className="vl_group_buttons" disabled={!this.props.Checked}>
                    <IntensityBox Callback={intensity => this.changeIntensity(intensity)}
                                  StopCallback={() => this.props.ChangeIntensity(this.state.intensity, this.props.Group)}
                                  Intensity={this.state.intensity}
                                  VlStyle={{boxShadow: "none", top: 0, backgroundColor: "transparent"}}
                                  Horizontal/>
                </div>
            </div>
        )
    }

    unusedBody = () => {

        return (
            <div className="vl_group_container" disabled={this.props.IntensityProgress}>
                <h5 className="vl_group_name">Devices without group</h5>
                <div className="vl_group_buttons">
                    {this.state.intensityBoxOpened ? "" : <Shifter Size="10rem"/>}
                    {this.state.intensityBoxOpened ? "" : <ArrowButton Status={this.state.opened} Callback={this.openCallback}/>}
                </div>
            </div>
        )
    }

    unusedDevices = () => {

        if (!isArray(this.props.UnusedDevices) || !this.state.opened) return "";
        return <DevicesList Type="Info"
                            Devices={this.props.UnusedDevices}
                            DevicesIntensityProgress={this.props.DevicesIntensityProgress}
                            ChangeIntensity={(intensity, device) => {
                                this.props.ChangeIntensity(intensity, this.props.Group, device);
                            }}
                            DeleteCallback={this.props.DeleteCallback}/>
    }

    unusedSensors = () => {

        if (!isArray(this.props.UnusedSensors) || !this.state.opened) return "";
        return <SensorsList Type="Info"
                            Sensors={this.props.UnusedSensors}
                            DeleteCallback={this.props.DeleteCallback}/>
    }

    statusOK = () => {

        if (!this.props.Group.devices) return true;
        return this.props.Group.devices.filter(device => device.status !== "OK").length === 0;
    }

    editCallback = () => {

        this.props.EditCallback(this.props.Group);
    }

    triggerIntensityBox = () => {

        this.setState({...this.state, intensityBoxOpened: !this.state.intensityBoxOpened});
    }

    removeCallback = () => {

        this.props.DeleteCallback(
            this.props.Group,
            false // isDevice
        );
    }

    openCallback = () => {

        this.setState({...this.state, opened : !this.state.opened});
    }

    hasActiveDevice = () => {

        return this.props.SelectedDevice &&
            this.props.Group.devices.filter(device => device.id === this.props.SelectedDevice.id).length > 0 &&
            this.props.Type !== "select";
    }

    changeIntensity = intensity => {

        this.setState({...this.state, intensity: intensity});
    }

    intensityBox = () => {

        if (!this.state.intensityBoxOpened || this.props.IntensityProgress) return "";
        return <IntensityBox Callback={intensity => this.changeIntensity(intensity)}
                             StopCallback={() => this.props.ChangeIntensity(this.state.intensity, this.props.Group)}
                             VlStyle={{position: "absolute", right: "4vw", top: "0.4vh", boxShadow: "none", backgroundColor: "transparent"}}
                             Intensity={this.state.intensity}
                             Horizontal/>
    }

    devices = () => {

        if (!(this.state.opened && this.props.Group.devices)) return "";
        return (
            <DevicesList Devices={this.props.Group.devices}
                         Disabled={this.props.IntensityProgress}
                         DevicesIntensityProgress={this.props.DevicesIntensityProgress}
                         ChangeIntensity={(intensity, device) => {
                             this.props.ChangeIntensity(intensity, this.props.Group, device);
                         }}
                         DeleteCallback={this.props.DeleteCallback}
                         UpdateDevice={this.props.UpdateDevice}
                         EditDevice={this.props.EditDeviceCallback}
                         VlStyle={{backgroundColor: "transparent"}}/>
        )
    }

    sensors = () => {

        if (!(this.state.opened && this.props.Group.sensors)) return "";
        return (
            <SensorsList Sensors={this.props.Group.sensors}
                         DeleteCallback={this.props.DeleteCallback}
                         UpdateSensor={this.props.UpdateDevice}
                         EditSensor={this.props.EditDeviceCallback}
                         VlStyle={{backgroundColor: "transparent"}}/>
        )
    }

    bodyFromType = () => {

        switch (this.props.Type) {
            case "select":
                return this.selectBody();
            case "unused":
                return this.unusedBody();
            default:
                return this.regularBody();
        }
    }

    loading = () => {

        if (!this.props.IntensityProgress) return <></>
        return (
            <div className="vl_group_loading_container">
                <Lottie
                    loop
                    goTo={0}
                    animationData={loadingJson}
                    speed={2}
                    play={true}
                    style={{
                        width: "400px",
                        height: "200px",
                        position: "absolute",
                        top: "-83px",
                        left: "-125px",
                    }}
                />
            </div>
        )
    }

    content = () => {

        if (this.props.UnusedDevices || this.props.UnusedSensors) {
            return <>
                {this.unusedDevices()}
                {this.unusedSensors()}
            </>
        }
        return <>
            {this.devices()}
            {this.sensors()}
        </>
    }

    render() {

        let groupClass = "vl_group";
        if (this.hasActiveDevice() && !this.state.opened) { groupClass += " vl_group_active"; }
        return (
            <div className={this.props.VlClass ? this.props.VlClass : groupClass}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                { this.bodyFromType() }
                { this.content() }
                { this.loading() }
            </div>
        )
    }
}

Group.propTypes = {
    DeleteCallback: PropTypes.func,
    CheckCallback: PropTypes.func,
    EditCallback: PropTypes.func,
    EditDeviceCallback: PropTypes.func,
    UpdateNameCallback: PropTypes.func,
    ChangeIntensity: PropTypes.func,
    UpdateDevice: PropTypes.func,

    AreaId: PropTypes.number,
    Group: PropTypes.object,
    UnusedDevices: PropTypes.array,
    IntensityProgress: PropTypes.bool,
    DevicesIntensityProgress: PropTypes.array,
    SelectedDevice: PropTypes.object,
    Type: PropTypes.string,
    Checked: PropTypes.bool,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
