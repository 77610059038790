import React from 'react'
import "../styles/pages/monitoring.css"
import "../styles/general.css"
import SelectBox from "../components/boxes/selectbox";
import LogCard from "../components/cards/log_card"
import VlButton from "../components/buttons/vl_button";
import {connect} from "react-redux";
import {getLogs, getServiceLogs, getServices, selectLog} from "../../actions/logs";
import CheckBox from "../components/boxes/checkbox";
import {selectedLogsToClipboardData} from "../../utils/logs";
import SendToHelpDialog from "../components/dialogs/sendToHelp";

const DefaultServices = [{name: "All", last_update: 0}];

class Monitoring extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            selectAll: false,
            selected_service: "",
            send_to_help_dialog_opened: false,
        }
    }

    componentDidMount() {

        this.props.getServices()
        this.props.getLogs()
    }

    services = () => {

        return DefaultServices.concat(this.props.services).map(service => {
            return {Name: service.name, Value: service.name}
        });
    }

    logs = () => {

        if (this.state.selected_service.length > 0 && this.props.serviceLogs !== undefined) {
            return this.props.serviceLogs;
        }
        return this.props.logs;
    }

    serviceSelected = service => {

        if (service !== "All")
            this.props.getServiceLogs(service)

        this.setState({
            ...this.state,
            selected_service: service === "All" ? "" : service,
        })
    }

    triggerSelectAll = () => {

        if (this.state.selectAll) {
            this.props.selectLog(-1, this.state.selected_service.length > 0);
        } else {
            this.props.selectLog(-2, this.state.selected_service.length > 0);
        }

        this.setState({
            ...this.state,
            selectAll: !this.state.selectAll,
        })
    }

    logLine = log => {

        return (
            <div key={log.id} style={{display: "flex", flexDirection: "row", height: "120px", marginRight: "10px"}}>
                <CheckBox Callback={() => {this.props.selectLog(log.id, this.state.selected_service.length > 0)}}
                          Checked={!!log.selected}
                          VlStyle={{marginTop: "34px", marginRight: "20px", width: "28px"}}/>
                <LogCard Log={log} />
            </div>
        )
    }

    selectAll = () => {

        return (
            <div className="vl_monitoring_select_all">
                <CheckBox Callback={this.triggerSelectAll} Checked={this.state.selectAll} VlStyle={{marginTop: "3px"}}/>
                <p className="vl_monitoring_select_all_text">Select All</p>
            </div>
        )
    }

    copySelected = () => {

        navigator.clipboard.writeText(selectedLogsToClipboardData(this.logs()))
            .then(() => {})
            .catch(err => {alert(err);});
    }

    sendToHelp = (email, notes) => {

        this.setState({...this.state, send_to_help_dialog_opened: false});
    }

    render() {

        return (
            <div className="vl_monitoring_panel">
                <h1 className="vl_header">Monitoring</h1>
                { this.selectAll() }
                <SelectBox Callback={this.serviceSelected}
                           Cases={this.services()}
                           VlStyle={{marginLeft: "auto", marginRight: "10.5%", marginTop: "16px"}}/>
                <div className="vl_monitoring_logs">
                    {
                        this.logs().map(log => this.logLine(log))
                    }
                </div>
                <VlButton Text={"Copy Selected"}
                          VlStyle={{position: "absolute", bottom: "6%", left: "28%", width: "19%"}}
                          Callback={this.copySelected}
                />
                <VlButton Text={"Send to help"}
                          VlStyle={{position: "absolute", bottom: "6%", right: "28%", width: "19%"}}
                          Callback={() => this.setState({...this.state, send_to_help_dialog_opened: true})}
                />
                {
                    this.state.send_to_help_dialog_opened ?
                        <SendToHelpDialog Text="Enter an email or leave the default one for the help center."
                                          DefaultEmail="help@visibleconnect.com"
                                          Callback={this.sendToHelp} /> :
                        ""
                }
            </div>
        )
    }
}

const mapStateToProfileProps = state => ({
    logs: state.logs.logs,
    serviceLogs: state.logs.serviceLogs,
    services: state.logs.services,
})

export default connect(mapStateToProfileProps, {
    getServices,
    getLogs,
    getServiceLogs,
    selectLog
})(Monitoring);