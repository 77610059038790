import React from 'react'
import "../../styles/dialogs/dialog.css"
import "../../styles/dialogs/confirmation.css"
import PropTypes from "prop-types";
import VlButton from "../buttons/vl_button";

export default class ConfirmationDialog extends React.Component {

    render() {
        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_dialog"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_confirmation_dialog">
                    <div className="vl_confirmation_dialog_icon" />
                    <p className="vl_confirmation_dialog_text">{this.props.Text}</p>
                    <VlButton Text="Yes"
                              VlClass="vl_confirmation_dialog_yes"
                              Callback={() => this.props.Callback(true)}
                    />
                    <VlButton Text="No"
                              Style="negative"
                              VlClass="vl_confirmation_dialog_no"
                              Callback={() => this.props.Callback(false)}
                    />
                </div>
            </div>
        )
    }
}

ConfirmationDialog.propTypes = {
    Callback: PropTypes.func.isRequired,
    Text: PropTypes.string,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
