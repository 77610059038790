import React from 'react'
import "../../../styles/panels/activation/add_by_date.css"
import PropTypes from "prop-types";
import EditBox from "../../boxes/editbox";
import VlButton from "../../buttons/vl_button";
import ScenesList from "../../lists/scenes_list";
import EditByDateActivation from "./edit/edit_by_date";
import {
    IdsAndNamesOfSelectedScenes,
    IdsOfSelectedScenes,
    SelectionSceneListFromScenes,
    TriggerSceneInList
} from "../../../../utils/scene";
import {activationWithSortedData} from "../../../../utils/activation";

let DefaultActivation = {
    id: -1,
    name: "",
    scene_ids: [],
    timing: { weekdays: [], day_times: [{hour: 0, minute: 0}], single_shots: [] }
}

export default class AddByDateActivation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            scenesSelected: false,
            sceneToSetup: null,
            resultActivation: JSON.parse(JSON.stringify(this.props.Activation)) || DefaultActivation,
            selectedScenes: SelectionSceneListFromScenes(
                this.props.Scenes,
                this.props.Activation?.scene_ids || []
            ),
            error: {type: "", message: ""},
        }
    }

    savingActivation = () => {

        if (this.state.resultActivation.name.length === 0) {
            this.setState({...this.state, error: {type: "name", message: "Name should not be empty"}})
        } else {
            this.props.SaveCallback(activationWithSortedData(this.state.resultActivation));
        }
    }

    editName = name => {

        this.setState({
            ...this.state,
            resultActivation: {
                ...this.state.resultActivation,
                name: name
            },
            error: {type: "", message: ""},
        });
    }

    activationChanged = newActivation => {

        this.setState({
            ...this.state,
            resultActivation: newActivation
        });
    }

    saveScenes = () => {

        let scene_ids = IdsOfSelectedScenes(this.state.selectedScenes);
        if (scene_ids.length === 0) {
            this.setState({...this.state, error: {type: "scenes", message: "Scenes list should not be empty"}})
        } else {
            this.setState({
                ...this.state,
                resultActivation: {
                    ...this.state.resultActivation,
                    scene_ids: scene_ids
                },
                scenesSelected: true
            })
        }
    }

    triggerEntry = scene_id => {

        this.setState({
            ...this.state,
            selectedScenes: TriggerSceneInList(this.state.selectedScenes, scene_id),
            error: {type: "", message: ""},
        });
    }

    selectPage = () => {

        return (
            <>
                <h5 className="vl_add_by_date_choose_header">Choose scenes</h5>
                {this.error("scenes")}
                <div className="vl_add_by_date_scenes_container">
                    <ScenesList Scenes={this.state.selectedScenes} Select={this.triggerEntry}/>
                </div>
                <VlButton Text="Cancel"
                          Style="negative"
                          VlStyle={{position: "absolute", bottom: "2.4vh", right: "12vw", width: "8vw"}}
                          Callback={this.props.CancelCallback}/>
                <VlButton Text="Next"
                          VlStyle={{position: "absolute", bottom: "2.4vh", right: "3vw", width: "8vw"}}
                          Callback={this.saveScenes}/>
            </>
        )
    }

    setupPage = () => {

        return (
            <>
                <EditBox Callback={this.editName}
                         VlClass="vl_add_by_date_main_name_edit"
                         Placeholder={"Enter activation name"}
                         Default={this.props.Activation?.name || ""}
                         Disabled={!!this.props.Activation}
                />
                {this.error("name")}
                <h5 className="vl_add_by_date_setup_header">Enter activation date and time</h5>
                <div className="vl_add_by_date_edit_container">
                    <EditByDateActivation TabColor="white"
                                          Changed={this.activationChanged}
                                          Activation={this.state.resultActivation}
                                          SelectedScenes={IdsAndNamesOfSelectedScenes(this.state.selectedScenes)}
                    />
                </div>
                <VlButton Text="Back"
                          Style="negative"
                          VlStyle={{position: "absolute", bottom: "2.4vh", left: "2vw", width: "5vw"}}
                          Callback={() => {this.setState({...this.state, scenesSelected: false})}}/>
                <VlButton Text="Cancel"
                          Style="negative"
                          VlStyle={{position: "absolute", bottom: "2.4vh", right: "12vw", width: "8vw"}}
                          Callback={this.props.CancelCallback}/>
                <VlButton Text="Save"
                          VlStyle={{position: "absolute", bottom: "2.4vh", right: "3vw", width: "8vw"}}
                          Callback={this.savingActivation}/>
            </>
        )
    }

    error = type => {

        if (this.state.error.type !== type) return <></>
        switch(type) {
            case "scenes":
                return <p className="vl_add_by_date_scenes_error">{this.state.error.message}</p>
            case "name":
                return <p className="vl_add_by_date_name_error">{this.state.error.message}</p>
            default:
                return <></>
        }
    }

    render() {
        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_add_by_date"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_add_by_date_header">
                    <h5 className="vl_add_by_date_header_text">
                        {this.props.Activation ? "Edit Activation" : "Add Activation"}
                    </h5>
                </div>
                <div className="vl_add_by_date_main">
                    {
                        this.state.scenesSelected ? this.setupPage() : this.selectPage()
                    }
                </div>
            </div>
        )
    }
}

AddByDateActivation.propTypes = {
    SaveCallback: PropTypes.func.isRequired,
    CancelCallback: PropTypes.func.isRequired,

    Scenes: PropTypes.array.isRequired,
    Activation: PropTypes.object,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
