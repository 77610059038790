import React from 'react'
import "../../../styles/panels/activation/activation_scene.css"
import "../../../styles/panels/activation/by_date.css"
import PropTypes from "prop-types";
import TriggerBox from "../../boxes/triggerbox";
import RemoveButton from "../../buttons/remove";
import {timeFromActivation, localTimeFromActivation, Weekdays} from "../../../../utils/activation";

export default class ActivationSceneByDate extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            active : true,
            opened : false,
            timeHovered: false,
        }
    }

    componentDidMount() {

        this.setState({...this.state, active: !!this.props.Activation.is_active})
    }

    triggerActive = () => {

        this.setState({...this.state, active: !this.state.active}, () => {
            this.props.SetActiveActivationByDate(this.props.Activation.id, this.state.active);
        });
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_activation_scene"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_activation_scene_container">
                    <div className="vl_activation_scene_by_date_info_container vl_activation_scene_by_date_active">
                        <h5 className="vl_activation_scene_by_date_name">{this.props.Activation.name}</h5>
                        <div className="vl_activation_scene_by_date_days">
                            {
                                this.props.Activation.timing.weekdays.map((day, index) => <p key={index}>{Weekdays[day]}</p>)
                            }
                        </div>
                        <p className="vl_activation_scene_by_date_time"
                           onMouseEnter={() => this.setState({...this.state, timeHovered: true})}
                           onMouseLeave={() => this.setState({...this.state, timeHovered: false})}
                        >
                            {localTimeFromActivation(this.props.Activation)}
                            <span style={this.state.timeHovered ? {} : {display: "none"}}>
                                {timeFromActivation(this.props.Activation) + " UTC"}
                            </span>
                        </p>
                    </div>
                    <RemoveButton VlStyle={{
                        position: "absolute",
                        right: "31%",
                        top: "21%",
                    }} Callback={() => this.props.DeleteCallback(this.props.Activation)}/>
                    <button className="vl_activation_scene_edit_button" onClick={() => this.props.EditCallback(this.props.Activation)}/>
                    <div className="vl_activation_scene_by_date_active_container">
                        <TriggerBox Callback={this.triggerActive} On={this.state.active} />
                    </div>
                </div>
            </div>
        )
    }
}

ActivationSceneByDate.propTypes = {
    DeleteCallback: PropTypes.func,
    EditCallback: PropTypes.func,
    SetActiveActivationByDate: PropTypes.func,

    Activation: PropTypes.object.isRequired,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
