import L from "leaflet";

export function setupDrawToolBarOnMap(map, drawItems) {

    map.addLayer(drawItems);
    let drawControl = new L.Control.Draw({
        position: 'topright',
        draw: toolbarDrawOptions,
        edit: {
            featureGroup: drawItems,
        }
    });
    map.addControl(drawControl);
}

const toolbarDrawOptions = {
    polygon: {
        shapeOptions: {
            color: 'purple'
        },
        allowIntersection: false,
        drawError: {
            color: 'orange',
            timeout: 1000
        },
        showArea: true,
        metric: false,
        repeatMode: true
    },
    // disable toolbar item by setting it to false
    polyline: false, // Turns off this drawing tool
    circle: false,
    circlemarker: false,
    marker: false,
};