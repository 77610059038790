import React from "react";
import "../../../styles/pages/profile.css"
import "../../../styles/panels/profile/profile_2fa_approval.css"
import doneJson from '../../../animations/icons8-done.json'
import VlButton from "../../buttons/vl_button";
import PropTypes from "prop-types";
import Lottie from "react-lottie-player";

export default class Profile2FAApproval extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            code: "",
        }
    }

    render() {

        return (
            <div className="vl_profile_2fa_approval_panel">
                <h1 className="vl_header vl_profile_header">Two-factor authentication</h1>
                <p className="vl_profile_2fa_approval_title">Your code approved!</p>
                <div className="vl_profile_2fa_approval_approved_icon">
                    <Lottie
                        loop={false}
                        goTo={12}
                        direction={1}
                        speed={0.5}
                        animationData={doneJson}
                        play={true}
                        className="vl_profile_2fa_approval_approved_icon_animation"
                    />
                </div>
                <VlButton VlStyle={{position: "absolute", bottom: "4.5vh", right: "4vw"}}
                          Text="Finish"
                          Callback={() => {this.props.Navigate("edit")}}
                />
            </div>
        )
    }
}

Profile2FAApproval.propTypes = {
    Navigate: PropTypes.func.isRequired
}