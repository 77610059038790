import React from "react";
import PropTypes from "prop-types";
import "../../../styles/tabs/reports/power_distribution.css"
import Histogram from "../../charts/histogram";
import SelectBox from "../../boxes/selectbox";
import {monthNameFormattedFromNumberFormatted} from "../../../../utils/time";

export default class PowerDistribution extends React.Component {

    constructor(props) {

        super(props);
        let periods = [];
        if (this.props.Groups.length > 0) {
            periods = this.periodCases(this.props.Groups[0].Value);
        }
        this.state = {
            period: periods.length > 0 ? periods[0].Value : null,
            group: this.props.Groups.length > 0 ? this.props.Groups[0].Value : -1,
        }
    }

    fullGroupNameFromGroup = (group) => {

        if (typeof group !== 'object') return "";
        return group.area_id.toString() + "-" + group.group_id.toString();
    }

    onPeriodSelected = period => {

        this.setState({...this.state, period: period});
    }

    onGroupSelected = group => {

        this.setState({...this.state, group: group});
    }

    selectedGroup = () => {

        return this.props.Groups.find(group => group.Value.area_id === this.state.group.area_id && group.Value.group_id === this.state.group.group_id);
    }

    periodCases = (group) => {

        if (!this.props.HistogramsMeta?.periods || !(this.props.HistogramsMeta.periods[this.fullGroupNameFromGroup(group)])) {
            return [];
        }
        return this.props.HistogramsMeta.periods[this.fullGroupNameFromGroup(group)].map(period => {
            return {Name: monthNameFormattedFromNumberFormatted(period), Value: period};
        })
    }

    groupCases = () => {

        return this.props.Groups;
    }

    title = () => {

        if (!this.state.period) return "Time not selected";
        let group = this.props.Groups.filter(g => g.Value.area_id === this.state.group.area_id && g.Value.group_id === this.state.group.group_id);
        if (group.length === 0) return "";
        let groupText = "'" + group[0].Name + "'";
        return "Intensity distribution for " + groupText + " in " +  monthNameFormattedFromNumberFormatted(this.state.period);
    }

    histogramFromSelections = () => {

        if (!this.state.period) {
            return <Histogram Data={{}} />;
        }
        let histogram = this.props.Histograms[this.fullGroupNameFromGroup(this.state.group) + "-" + String(this.state.period)];
        if (histogram) {
            return <Histogram Data={histogram} />;
        } else {
            this.props.GetHistogram(this.state.group.area_id, this.state.group.group_id, this.state.period);
        }
        return <Histogram Data={{}} />;
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_power_distribution_tab"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_power_distribution_info_bar">
                    <p className="vl_power_distribution_title">{this.title()}</p>
                    <SelectBox Callback={this.onGroupSelected}
                               Cases={this.groupCases()}
                               Selected={this.selectedGroup()}
                               VlStyle={{marginLeft: "auto"}}
                    />
                    <SelectBox Callback={this.onPeriodSelected}
                               Cases={this.periodCases(this.state.group)}
                               VlStyle={{marginLeft: "2%", marginRight: "7.5%"}}/>
                </div>
                <div className="vl_power_distribution_chart_container">
                    { this.histogramFromSelections() }
                </div>
            </div>
        )
    }
}

PowerDistribution.propTypes = {
    GetHistogram: PropTypes.func.isRequired,
    Histograms: PropTypes.object.isRequired,
    HistogramsMeta: PropTypes.object.isRequired,
    Groups: PropTypes.array.isRequired,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}