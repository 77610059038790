import React from 'react'
import "../../styles/buttons/remove.css"
import PropTypes from "prop-types";
import {classForBrowser} from "../../../utils/browser";

export default class RemoveButton extends React.Component {

    render() {
        return (
            <button
                className={this.props.VlClass ? this.props.VlClass : "vl_remove_button" + (this.props.Permanent ? " vl_remove_permanent_button" : "")}
                style={this.props.VlStyle ? this.props.VlStyle : {}}
                onClick={this.props.Callback}>
                <p className={classForBrowser("vl_remove_button_icon")} />
            </button>
        )}
}

RemoveButton.propTypes = {
    Callback: PropTypes.func,
    Permanent: PropTypes.bool,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
