import React from 'react'
import "../../styles/tools/code.css"
import PropTypes from "prop-types";

export default class CodeInput extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            code: Array.from(Array(this.props.Count).keys()).map(i => {
                return {text: "", ref: React.createRef()}
            }),
        }
    }

    inputChanged = (e, index) => {

        if (e.target.value.length === 1 &&
            index < this.state.code.length - 1) {
            this.state.code[(index + 1) % this.state.code.length].ref.current.focus();
            this.state.code[(index + 1) % this.state.code.length].ref.current.select();
        }

        this.setState({
            ...this.state,
            code: this.state.code.map((field, i) => {
                if (i === index && e.target.value.length <= 1)
                    return {...field, text: e.target.value};
                return field;
            }),
            focused: (e.target.value.length === 1 ? (this.state.focused + 1) % this.props.Count : this.state.focused)
        }, () => {
            this.props.Callback(this.state.code.map(c => c.text).join(''));
        })
    }

    inputs = () => {

        return (
            <>
                {
                    Array.from(Array(this.props.Count).keys()).map(index => {
                        return (
                            <input key={index}
                                   value={this.state.code[index].text}
                                   ref={this.state.code[index].ref}
                                   type="password"
                                   className={"vl_code_input_field " +
                                       (this.props.Black ? "vl_code_input_field_black" : "vl_code_input_field_white")}
                                   autoFocus={index === 0}
                                   onClick={() => {this.state.code[index].ref.current.select()}}
                                   onInput={e => this.inputChanged(e, index)}
                            />
                        )
                    })
                }
            </>
        )
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_code_input"}
                style={this.props.VlStyle ? this.props.VlStyle : {}}>
                {this.inputs()}
            </div>
        )}
}

CodeInput.propTypes = {
    Count: PropTypes.number.isRequired,
    Black: PropTypes.bool,
    Callback: PropTypes.func,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
