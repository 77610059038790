export function findDeviceById (id, state) {

    // If is in unused devices return by id
    let result = state.unused_devices.find(d => d.id === id);
    if (result) return result;

    // If is in areas
    return state.areas.reduce((r, area) => {
        if (r) return r;
        // Check in area devices (without groups)
        if (area.devices) {
            let result = area.devices.find(device => device.id === id);
            if (result) return result;
        }
        // Check in area group devices
        return area.groups.reduce((result, group) => {
            if (result) return result;
            if (group.devices) {
                let r = group.devices.find(d => d.id === id);
                if (r) return r;
            }
            return result;
        }, null);
    }, null);
}

export function updatedUnusedDevices (device, unused_devices) {

    let deviceFound = unused_devices.find(d => d.id === device.id);
    // If device has no area (is moved to part with no area)
    if (device.area_id < 0) {
        // If devices was already in unused list just update (position)
        if (deviceFound) {
            unused_devices = unused_devices.map(d => {
                if (d.id === device.id) return device;
                return d;
            })
        }
        // If device is moved to unused list push to array
        else {
            unused_devices.push(device);
        }
    }
    // If device is moved to some area then remove it from the unused list
    else if (deviceFound) {
        unused_devices = unused_devices.filter(d => d.id !== device.id);
    }
    return unused_devices;
}

export function updatedAreas (device, areas, previousAreaId, previousGroupId) {

    areas.forEach(area => {
        // Device is moved to this area
        if (area.id === device.area_id) {
            // Device is in a group
            if (device.group_id > 0 && area.groups) {
                let foundGroup = area.groups.find(d => d.id === device.group_id);
                if (foundGroup) {
                    // Update device in a group
                    foundGroup.devices = foundGroup.devices.map(d => {
                        if (d.id === device.id) return device;
                        return d;
                    })
                }
            }
            // Device is not in any group
            else {
                if (!area.devices) area.devices = [];
                let foundDevice = area.devices.find(d => d.id === device.id);
                // If device is already in the area then update
                if (foundDevice) area.devices = area.devices.map(d => {
                    if (d.id === device.id) return device;
                    return d;
                });
                // If is new for to the area add to list
                else area.devices.push(device);
            }
        }
        // Device is moved from this area
        else if (area.id === previousAreaId) {
            // Previously had a group
            if (previousGroupId > 0 && area.groups) {
                let foundGroup = area.groups.find(d => d.id === previousGroupId);
                // Remove from previous group
                if (foundGroup) foundGroup.devices = foundGroup.devices.filter(d => d.id !== device.id)
            }
            // Previously had no group
            else if (area.devices) {
                // Remove device from its previous area's devices list
                area.devices = area.devices.filter(d => d.id !== device.id);
            }
        }
    });
    return areas;
}

export function devicesWithIds(devices, ids) {

    return devices.filter(device => ids.find(id => device.id === id));
}

export function devicesWithoutIds(devices, ids) {

    return devices.filter(device => !ids.find(id => device.id === id));
}

export function regroupDevicesInArea(area, unused_devices, ids) {

    let remainingIds = ids;
    let removedDevices = [];
    area.groups.forEach(group => {
        removedDevices.push(...group.devices.filter(device => !remainingIds.find(id => device.id === id)));
        group.devices = group.devices.filter(device => remainingIds.find(id => device.id === id));
        remainingIds = remainingIds.filter(id => !group.devices.find(device => device.id === id));
    });
    removedDevices.push(...area.devices.filter(device => !remainingIds.find(id => device.id === id)));
    area.devices = area.devices.filter(device => remainingIds.find(id => device.id === id));
    area.devices.push(...unused_devices.filter(device => remainingIds.find(id => device.id === id)).map(device => {
        return {...device, area_id: area.id}
    }));
    unused_devices = unused_devices.filter(device => !remainingIds.find(id => device.id === id));
    unused_devices.push(...removedDevices.map(device => {return {...device, area_id: -1, group_id: -1}}));
    return unused_devices;
}

export function regroupSensorsInArea(area, allSensors, ids) {

    let remainingIds = ids;
    let removedSensors = [];
    area.groups.forEach(group => {
        removedSensors.push(...group.sensors.filter(device => !remainingIds.find(id => device.id === id)));
        group.sensors = group.sensors.filter(sensor => remainingIds.find(id => sensor.id === id));
        remainingIds = remainingIds.filter(id => !group.sensors.find(sensor => sensor.id === id));
    });
    removedSensors.push(...area.sensors.filter(sensor => !remainingIds.find(id => sensor.id === id)));
    area.sensors = area.sensors.filter(sensor => remainingIds.find(id => sensor.id === id));
    area.sensors.push(...allSensors.filter(sensor => remainingIds.find(id => sensor.id === id)).map(sensor => {
        return {...sensor, area_id: area.id}
    }));
    allSensors = allSensors.map(sensor => {
        if (remainingIds.find(id => id === sensor.id)) return {...sensor, area_id: area.id}
        if (removedSensors.find(s => s.id === sensor.id)) return {...sensor, area_id: -1, group_id: -1}
        return sensor;
    })
    return allSensors;
}