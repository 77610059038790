import React from 'react'
import PropTypes from "prop-types";
import CanvasJSReact from "../../../lib/canvasjs.react";
import {
    timestampToYearAndMonth,
    timestampToFullDate,
    fullDatesForMonthOfTimestamp,
    fullDateToTimestamp
} from "../../../utils/time";
let CanvasJSChart = CanvasJSReact.CanvasJSChart;


export default class EventsChart extends React.Component {

    points = () => {

        if (this.props.SelectedDate > 0 && this.props.SelectedSensor && (this.props.SelectedSensor in this.props.Data)) {
            let eventsForDays = {};
            let previousDays = fullDatesForMonthOfTimestamp(this.props.SelectedDate);
            for (const day of previousDays) {
                if (fullDateToTimestamp(day) > Date.now()) {
                    break;
                }
                if (!(day in eventsForDays)) {
                    eventsForDays[day] = 0;
                }
            }
            let points = this.props.Data[this.props.SelectedSensor].filter(event => {
                return timestampToYearAndMonth(event.timestamp) === timestampToYearAndMonth(this.props.SelectedDate)
            }).reduce((result, event) => {
                let label = timestampToFullDate(event.timestamp);
                result[label]++;
                return result;
            }, eventsForDays);
            return Object.keys(points).map(day => {
                return {label: day, y: points[day]};
            })
        }
        return [];
    }

    canvasOptions = () => {

        return {
            theme: "light2",
            animationEnabled: true,
            title: {
                text: this.props.Title,
            },
            axisY: {
                title: "Event",
                valueFormatString: "#",
            },
            data: [{
                type: "line",
                dataPoints: this.props.Points ? this.props.Points : this.points()
            }]
        }
    }

    render() {

        return (
            <CanvasJSChart options = {this.canvasOptions()}
                           onRef={ref => this.chart = ref}
                           containerProps={{
                               width: this.props.VlWidth ? this.props.VlWidth : "100%",
                               height: this.props.VlWidth ? this.props.VlHeight : "100%",
                           }}
            />
        );
    }
}

EventsChart.propTypes = {
    SelectedSensor: PropTypes.string,
    Data: PropTypes.object,
    Title: PropTypes.string,
    UseYear: PropTypes.bool,
    Points: PropTypes.array,
    SelectedDate: PropTypes.number,
    VlWidth: PropTypes.string,
    VlHeight: PropTypes.string,
}
