import React from 'react'
import "../../styles/dialogs/dialog.css"
import "../../styles/dialogs/naming.css"
import PropTypes from "prop-types";
import VlButton from "../buttons/vl_button";

export default class NamingDialog extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            value: "",
        }
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_dialog"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_naming_dialog">
                    <p className="vl_naming_dialog_text">{this.props.Text}</p>
                    <input className="vl_naming_dialog_input"
                           type="text"
                           onChange={e => { this.setState({...this.state, value: e.target.value}); }}/>
                    <VlButton Text="Yes"
                              Style={this.state.value.length > 0 ? "" : "negative"}
                              VlClass="vl_naming_dialog_yes"
                              Callback={() => {
                                  if (this.state.value.length > 0)
                                    this.props.Callback(this.state.value);
                              }}
                    />
                    <VlButton Text="Cancel"
                              Style="negative"
                              VlClass="vl_naming_dialog_no"
                              Callback={() => this.props.Callback("")}
                    />
                </div>
            </div>
        )
    }
}

NamingDialog.propTypes = {
    Callback: PropTypes.func.isRequired,
    Text: PropTypes.string,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
