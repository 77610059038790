import React from 'react'
import "../../styles/boxes/dateeditbox.css"
import PropTypes from "prop-types";
import {formatIntoTwoDigitNumber, isDay, isMonth, isYear} from "../../../utils/time";

export default class DateEditBox extends React.Component {

    constructor(props) {

        super(props);
        let date = this.props.Timestamp ? new Date(this.props.Timestamp * 1000) : new Date();
        this.state = {
            year: date.getFullYear(),
            month: date.getMonth()+1,
            day: date.getDate(),
            monthFocused: false,
            dayFocused: false,
        }
    }

    timestampFromInput = () => {

        return new Date(Date.UTC(this.state.year, this.state.month - 1, this.state.day, 0,0, 0, 0)).getTime() / 1000;
    }

    isInvalid = () => {

        if (this.props.MinTimestamp && this.timestampFromInput() < this.props.MinTimestamp) {
            return true;
        }
        return !!(this.props.MaxTimestamp && this.timestampFromInput() > this.props.MaxTimestamp);

    }

    yearValue = () => {

        return this.state.year;
    }

    monthValue = () => {

        if (this.state.monthFocused)
            return this.state.month;
        return formatIntoTwoDigitNumber(this.state.month);
    }

    dayValue = () => {

        if (this.state.dayFocused)
            return this.state.day;
        return formatIntoTwoDigitNumber(this.state.day);
    }

    onYearChanged = e => {

        if (isYear(e.target.value)) {
            this.setState({
                ...this.state,
                year: e.target.value,
                invalid: this.isInvalid()
            }, () => {
                this.props.Callback(this.timestampFromInput());
            });
        } else {
            e.preventDefault();
        }
    }

    onMonthChanged = e => {

        if (isMonth(e.target.value)) {
            this.setState({
                ...this.state,
                month: e.target.value,
                invalid: this.isInvalid()
            }, () => {
                this.props.Callback(this.timestampFromInput());
            });
        } else {
           e.preventDefault();
        }
    }

    onDayChanged = e => {

        if (isDay(e.target.value, this.state.month, this.state.year)) {
            this.setState({
                ...this.state,
                day: e.target.value,
                invalid: this.isInvalid()
            }, () => {
                this.props.Callback(this.timestampFromInput());
            });
        } else {
            e.preventDefault();
        }
    }

    yearPart = () => {

        return (
            <>
                <input type="text"
                       className={"vl_date_edit_year" + (this.isInvalid() ? " vl_date_edit_invalid_input" : "")}
                       onChange={this.onYearChanged}
                       value={this.yearValue()}
                />
            </>
        )
    }

    monthPart = () => {

        if (!this.props.UseMonth) { return <></> }
        return (
            <>
                <p className="vl_date_edit_divider">-</p>
                <input type="text"
                       className={"vl_date_edit_month" + (this.isInvalid() ? " vl_date_edit_invalid_input" : "")}
                       onFocus={() => this.setState({...this.state, monthFocused: true})}
                       onBlur={() => this.setState({...this.state, monthFocused: false})}
                       onChange={this.onMonthChanged}
                       value={this.monthValue()}
                />
            </>
        )
    }

    dayPart = () => {

        if (!this.props.UseDay) { return <></> }
        return (
            <>
                <p className="vl_date_edit_divider">-</p>
                <input type="text"
                       className={"vl_date_edit_day" + (this.isInvalid() ? " vl_date_edit_invalid_input" : "")}
                       onFocus={() => this.setState({...this.state, dayFocused: true})}
                       onBlur={() => this.setState({...this.state, dayFocused: false})}
                       onChange={this.onDayChanged}
                       value={this.dayValue()}
                />
            </>
        )
    }

    render() {

        return (
            <div className={"vl_date_edit_container " + (this.props.VlClass ? this.props.VlClass : "") + (this.isInvalid() ? " vl_date_edit_invalid_input" : "")}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                {this.yearPart()}
                {this.monthPart()}
                {this.dayPart()}
            </div>
        )
    }
}

DateEditBox.propTypes = {
    Callback: PropTypes.func.isRequired,
    Timestamp: PropTypes.number,
    MinTimestamp: PropTypes.number,
    MaxTimestamp: PropTypes.number,

    UseMonth: PropTypes.bool,
    UseDay: PropTypes.bool,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
