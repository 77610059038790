import {DEVICE_INTENSITY_CHANGING, GROUP_INTENSITY_CHANGING, SCENE_CHANGING} from './types';

export const setIntensityProgress = (group, end) => dispatch => {

    dispatch({
        type: GROUP_INTENSITY_CHANGING,
        Group: group,
        End: end
    })
};

export const setDeviceIntensityProgress = (device_id) => dispatch => {

    dispatch({
        type: DEVICE_INTENSITY_CHANGING,
        DeviceId: device_id
    })
};

export const setSceneProgress = (status) => dispatch => {

    dispatch({
        type: SCENE_CHANGING,
        Status: status
    })
};
