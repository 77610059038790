import {
    ACTIVATE_SCENE,
    ADD_SCENE,
    DELETE_SCENE,
    GET_SCENES,
    MODIFY_SCENES_LIST,
    SAVE_SCENE
} from "../actions/types";

const initialState = {
    scenes: [],
    modifiedScenes: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {

    switch(action.type) {

        case GET_SCENES:
            return { ...state, scenes: action.scenes, modifiedScenes: action.scenes }

        case ADD_SCENE:
            return {
                ...state,
                scenes: [ ...state.scenes, action.scene ],
                modifiedScenes: [ ...state.modifiedScenes, action.scene ]
            }

        case ACTIVATE_SCENE:
            let activatedScenes = state.scenes.map(scene => {
                if (scene.id === action.scene_id) {
                    return {...scene, is_activated: true}
                }
                return {...scene, is_activated: false}
            });
            let activatedModifiedScenes = state.modifiedScenes.map(scene => {
                if (scene.id === action.scene_id) {
                    return {...scene, is_activated: true}
                }
                return {...scene, is_activated: false}
            });
            return {
                ...state,
                scenes: activatedScenes,
                modifiedScenes: activatedModifiedScenes
            }

        case SAVE_SCENE:
            let savedScenes = state.scenes.map((scene) => {
                if (scene.id === action.scene.id) return action.scene
                return scene
            })
            let savedModifiedScenes = state.modifiedScenes.map((scene) => {
                if (scene.id === action.scene.id) return action.scene
                return scene
            })
            return { ...state, scenes: savedScenes, modifiedScenes: savedModifiedScenes }

        case DELETE_SCENE:
            let newScenes = state.scenes.filter((scene) => scene.id !== action.scene_id)
            let newModifiedScenes = state.modifiedScenes.filter((scene) => scene.id !== action.scene_id)
            return { ...state, scenes: newScenes, modifiedScenes: newModifiedScenes }

        case MODIFY_SCENES_LIST:
            return { ...state, modifiedScenes: modifiedScenesFromAction(state.scenes, action) }

        default:
            return state;
    }
}

let modifiedScenesFromAction = (scenes, action) => {

    let modifiedScenes = JSON.parse(JSON.stringify(scenes));
    modifiedScenes = sortedScenes(modifiedScenes, action.sort_field);
    if (action.reverse_sort_activated) {
        modifiedScenes = modifiedScenes.reverse();
    }
    return modifiedScenes.filter(scene => scene.name.includes(action.search_text));
}

let sortedScenes = (scenes, field) => {

    if (parseInt(field) === 1) {
        scenes.sort((first, second) => first.name > second.name);
    }
    return scenes;
}