import React from 'react'
import Banner from "../components/decorations/banner";
import VlButton from '../components/buttons/vl_button'
import "../styles/pages/login.css"
import "../styles/general.css"
import {connect} from "react-redux";
import {signIn, cleanError} from "../../actions/auth";
import {Redirect} from "react-router-dom";
import loadingJson from "../animations/Loading.json";
import Lottie from "react-lottie-player";
import Shifter from "../components/layout/shifter";

class Login extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            username: "",
            password: "",
        }
    }

    handleLogIn = () => {

        this.props.signIn(
            this.state.username,
            this.state.password
        )
    }

    handleKeyDown = e => {
        if (e.key === 'Enter' && this.state.username.length > 0 && this.state.password.length > 0) {
            this.handleLogIn();
        } else if (this.props.error !== null ){
            this.props.cleanError();
        }
    }

    status = () => {

        if (this.props.authenticating) {
            return (
                <div className="vl_login_loading_container">
                    <Lottie
                        loop
                        goTo={0}
                        animationData={loadingJson}
                        play={true}
                        style={{
                            width: "400px",
                            height: "200px",
                            position: "absolute",
                            top: "-83px",
                            left: "-125px",

                        }}
                    />
                </div>
            )
        }

        return this.props.error !== null ? <p className="vl_login_error">{this.props.error}</p> : ""
    }

    loginForm = () => {

        return (
            <div className="vl_login_panel">
                <Banner VlWidth="100%" VlHeight="12vh"/>
                <Shifter Vertical Size="45px"/>
                <input className="vl_login_input"
                       placeholder="Name"
                       onChange={e => this.setState({...this.state, username: e.target.value})}
                       onKeyDown={this.handleKeyDown}
                       autoFocus
                />
                <input className="vl_login_input"
                       placeholder="Password"
                       type="password"
                       onChange={e => this.setState({...this.state, password: e.target.value})}
                       onKeyDown={this.handleKeyDown}
                />
                <VlButton Text="Log in"
                          VlStyle={{
                              position: "absolute",
                              right: "18%",
                              bottom: "13%"}}
                          Callback={this.handleLogIn}
                />
                { this.status() }
            </div>
        );
    }

    render() {

        if (this.props.authenticated) {
            if (this.props.verified) {
                return <Redirect to={{ pathname: "/"}}/>;
            } else {
                return <Redirect to={{ pathname: "/login/2fa"}}/>;
            }
        }
        return (
            <div className="vl_background">
                {this.loginForm()}
            </div>
        );
    }
}

const mapStateToLoginProps = state => ({
    error: state.auth.error,
    authenticating: state.auth.authenticating,
    authenticated: state.auth.authenticated,
    verified: state.auth.verified
})

export default connect(mapStateToLoginProps, { signIn, cleanError })(Login);