import {timestampToFullDateAndTime} from "./time";

export function selectedLogsToClipboardData(logs) {

    let result = "";
    if (Array.isArray(logs))
    logs.forEach(log => {
        if(log.selected) {
            result += log.service_name + " : " + timestampToFullDateAndTime(log.time) + " : " + log.text;
        }
    })
    return result;
}