import React from "react";
import VlButton from "../../buttons/vl_button";
import PropTypes from "prop-types";
import "../../../styles/panels/devices/device_parameters.css"
import ParametersList from "../../lists/parameters_list";

export default class DeviceParameters extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            parameters: JSON.parse(JSON.stringify(this.props.Device.parameters))
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (Object.keys(prevProps.Device.parameters).length !== Object.keys(this.props.Device.parameters).length) {
            this.setState({...this.state, parameters: this.props.Device.parameters});
        }
    }

    title = () => {

        return this.props.Device.name;
    }

    save = () => {

        this.props.SaveCallback(this.state.parameters);
    }

    change = (id, value) => {

        let newParameters = this.state.parameters;
        if (id.startsWith("use_")) {
            newParameters[id] = !this.state.parameters[id];
            this.setState({...this.state, parameters: newParameters});
        } else {
            newParameters[id] = value;
            this.setState({...this.state, parameters: newParameters});
        }
    }

    render() {

        return (
            <div className={this.props.VlClass || "vl_device_parameters"}
                 style={this.props.VlStyle || {}}>
                <div className="vl_device_parameters_header">
                    <h5 className="vl_device_parameters_header_text">{this.title()}</h5>
                </div>
                <div className="vl_device_parameters_main">
                    <div className="vl_device_parameters_list_container">
                        <ParametersList Parameters={this.state.parameters}
                                        Change={this.change}
                        />
                    </div>
                    <VlButton Text="Cancel"
                              Style="negative"
                              VlStyle={{position: "absolute", bottom: "2.5vh", right: "12vw", width: "8vw"}}
                              Callback={this.props.CancelCallback}/>
                    <VlButton Text="Save"
                              VlStyle={{position: "absolute", bottom: "2.5vh", right: "3vw", width: "8vw"}}
                              Callback={this.save}/>
                </div>
            </div>
        )
    }
}

DeviceParameters.propTypes = {
    Device: PropTypes.object.isRequired,

    SaveCallback: PropTypes.func.isRequired,
    CancelCallback: PropTypes.func.isRequired,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}