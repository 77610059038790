import React from 'react'
import "../../../../styles/panels/activation/edit/edit_by_date.css"
import PropTypes from "prop-types";
import Tabs from "../../../general/tabs";
import CheckBox from "../../../boxes/checkbox";
import {Calendar} from "react-multi-date-picker";
import {
    activationContainsWeekday,
    changeDayTimeInActivation,
    setSingleShotsOfActivation,
    singleShotsAsDates,
    singleShotsListFromActivation,
    triggerWeekdayInActivation,
    Weekdays
} from "../../../../../utils/activation";
import TimeEditBox from "../../../boxes/timeeditbox";
import {localToUTC, timeToHourMinute, UTCToLocal} from "../../../../../utils/time";

export default class EditByDateActivation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: "Repeat every",
        }
    }

    changeSelected = selected => {

        this.setState({...this.state, selectedTab: selected});
    }

    changeWeekday = day => {

        this.props.Changed(triggerWeekdayInActivation(this.props.Activation, day));
    }

    changeSingleShots = values => {

        this.props.Changed(setSingleShotsOfActivation(this.props.Activation, values))
    }

    componentFromTab = () => {

        switch (this.state.selectedTab) {
            case "Repeat every":
                return (
                    <div className="vl_activation_scene_by_date_repeat_every_tab">
                        {Weekdays.map((day, index) => {
                            return (
                                <div key={index} className="vl_activation_scene_by_date_repeat_every_tab_item">
                                    <CheckBox Callback={() => this.changeWeekday(day)}
                                              Checked={activationContainsWeekday(this.props.Activation, day)}
                                    />
                                    <p>{day}</p>
                                </div>
                            )
                        })}
                    </div>
                )
            case "One time use":
                return (
                    <div className="vl_activation_scene_by_date_one_time_tab">
                        <Calendar className="vl_activation_scene_by_date_one_time_tab_calendar"
                                  minDate={new Date()}
                                  onChange={this.changeSingleShots}
                                  value={singleShotsAsDates(this.props.Activation)}
                                  multiple/>
                        <div className="vl_activation_scene_by_date_one_time_dates_list">
                            {singleShotsListFromActivation(this.props.Activation)}
                        </div>
                    </div>
                );
            case "Scenes list":
                return (
                    <div className="vl_activation_scene_by_date_scenes_list_tab">
                        {
                            this.props.SelectedScenes.map(scene => {
                                return (
                                    <div key={scene.id} className="vl_activation_scene_by_date_scene">
                                        <p>{scene.name}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            default:
                return "";
        }
    }

    timeTitle = () => {

        return (
            <>
                <h5 className="vl_activation_scene_by_date_edit_time">
                    <TimeEditBox Callback={(value) => {
                        this.props.Changed(changeDayTimeInActivation(this.props.Activation, 0, localToUTC(value.hour), value.minute));
                    }} Value={
                        this.props.Activation.timing.day_times.length > 0 ?
                            {hour: UTCToLocal(this.props.Activation.timing.day_times[0].hour), minute: this.props.Activation.timing.day_times[0].minute}:
                            {hour: 0, minute: 0}
                    }
                    />
                </h5>
                <p className="vl_activation_scene_by_date_edit_time_gmt">
                    {timeToHourMinute(this.props.Activation.timing.day_times[0]) + " UTC"}
                </p>
            </>
        )
    }

    render() {

        return (
            <div className={
                this.state.selectedTab === "One time use" ?
                    "vl_activation_scene_by_date_edit_container_long" :
                    "vl_activation_scene_by_date_edit_container"
            }>
                {this.timeTitle()}
                <Tabs VlStyle={{height: "10%", marginTop: "16px", width: "100%", justifyContent: "center"}}
                      VlButtonStyle={{width: "30%", backgroundColor: (this.props.TabColor || "#EFEFEF")}}
                      VlSelectedButtonStyle={{width: "30%", backgroundColor: (this.props.TabColor || "#EFEFEF"), borderColor: "#8D8D8D"}}
                      Titles={["Repeat every", "One time use", "Scenes list"]}
                      Selected={this.state.selectedTab}
                      Callback={selected => this.changeSelected(selected)} />
                {this.componentFromTab()}
            </div>
        )
    }
}

EditByDateActivation.propTypes = {
    Changed: PropTypes.func.isRequired,
    Activation: PropTypes.object.isRequired,
    SelectedScenes: PropTypes.array.isRequired,

    TabColor: PropTypes.string,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
