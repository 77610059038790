import React from 'react'
import "../../../styles/panels/activation/activation_scene.css"
import "../../../styles/panels/activation/by_optional.css"
import PropTypes from "prop-types";

export default class ActivationSceneByOptional extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active : true,
            opened : false,
        }
    }

    triggerActive = () => {

        this.setState({...this.state, active: !this.state.active});
    }

    triggerOpened = () => {

        this.setState({...this.state, opened: !this.state.opened});
    }


    render() {
        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_activation_scene"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_activation_scene_container">
                    <div className="vl_activation_scene_by_optional_info_container">
                        <h5 className="vl_activation_scene_by_optional_name">{this.props.Scene.name}</h5>
                    </div>
                    <button className="vl_activation_scene_edit_button" />
                </div>
            </div>
        )
    }
}

ActivationSceneByOptional.propTypes = {
    Scene: PropTypes.object.isRequired,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
