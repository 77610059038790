import {ENQUEUE_ERROR, POP_ERROR, SIGN_IN} from "../actions/types";

const initialState = {
    errors: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {

    switch(action.type) {

        case SIGN_IN:
            return {...state, errors: []}
        case ENQUEUE_ERROR:
            return {...state, errors: [...state.errors, action.error]}
        case POP_ERROR:
            if (state.errors.length < 2) { return {...state, errors: []} }
            return {...state, errors: state.errors.splice(1, state.errors.length - 1)}
        default:
            return state;
    }
}