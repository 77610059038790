import React from 'react'
import "../../styles/lists/charts_list.css"
import PropTypes from "prop-types";
import SelectBox from "../boxes/selectbox";
import PowerChart from "../charts/power_chart";
import {
    formatIntoTwoDigitNumber,
    fullDateFromDateTimeString,
    hourFromDateTimeString,
    monthFromDateTimeString, months,
    yearFromDateTimeString
} from "../../../utils/time";
import {isArray} from "../../../utils/arrays";
import Histogram from "../charts/histogram";
import ErrorsChart from "../charts/errors_chart";
import EventsChart from "../charts/events_chart";
import IntervalsChart from "../charts/intervals_chart";

export default class ChartsList extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            intervalSelected: {},
        }
    }

    selectInterval = (index, interval) => {

        let newIntervalSelected = {...this.state.intervalSelected};
        newIntervalSelected[index] = interval;
        this.setState({...this.state, intervalSelected: newIntervalSelected});
    }

    intervalForIndex = (index, generatedReport) => {

        return this.state.intervalSelected[index] ?
            this.state.intervalSelected[index] :
            this.intervalCases(generatedReport, this.props.Reports[index])[0];
    }

    sensorIntervalForIndex = (index, generatedReport) => {

        return this.state.intervalSelected[index] ?
            this.state.intervalSelected[index] :
            this.sensorIntervalCases(generatedReport, this.props.Reports[index])[0];
    }

    stringFromInterval = (time, interval) => {

        if (interval === "hour") {
            return hourFromDateTimeString(time);
        } else if (interval === "day") {
            return fullDateFromDateTimeString(time);
        } else if (interval === "month") {
            return monthFromDateTimeString(time);
        } else if (interval === "year") {
            return yearFromDateTimeString(time);
        }
        return "";
    }

    stringFromIntervalShifted = (time, interval) => {

        if (interval === "hour") {
            return fullDateFromDateTimeString(time);
        } else if (interval === "day") {
            return monthFromDateTimeString(time);
        } else if (interval === "month") {
            return yearFromDateTimeString(time);
        }
        return "";
    }

    intervalCases = (generatedReport, report) => {

        if (!isArray(generatedReport.intervals)) return [];
        return generatedReport.intervals.reduce((result, interval) => {
            let time = this.stringFromIntervalShifted(interval.time, report.interval.Value);
            if (time.length > 0 && !result.find(t => t.Value === time)) {
                return [...result, {Name: time, Value: time, Data: {Name: time, Value: time}}];
            }
            return result;
        }, []);
    }

    sensorIntervalCases = (generatedReport, report) => {

        if (!isArray(generatedReport["events"])) return [];
        return generatedReport["events"].reduce((result, interval) => {
            let time = this.stringFromIntervalShifted(new Date(interval.timestamp*1000).toISOString(), report.interval.Value);
            if (time.length > 0 && !result.find(t => t.Value === time)) {
                return [...result, {Name: time, Value: time, Data: {Name: time, Value: time}}];
            }
            return result;
        }, []);
    }

    consumptionPoints = (reports, date, interval) => {

        if (!isArray(reports.intervals) || !date) return [];
        return reports.intervals.filter(i => i.time.startsWith(date.Value)).map(i => {
            return {label: this.stringFromInterval(i.time, interval), y: i.power};
        });
    }

    consumptionTotalForData = (reports, date) => {

        if (!isArray(reports.intervals) || !date) return [];
        return reports.intervals.reduce((result, i) => {
            if (i.time.startsWith(date.Value)) {
                return result + i.power;
            }
            return result;
        }, 0);
    }

    distributionInterval = (start, end) => {

        let startDate = new Date(start * 1000);
        let endDate = new Date(end * 1000);
        return formatIntoTwoDigitNumber(startDate.getDate()) + "-" + months[startDate.getMonth()] + "-" + startDate.getFullYear() +
            " / " +
            formatIntoTwoDigitNumber(endDate.getDate()) + "-" + months[endDate.getMonth()] + "-" + endDate.getFullYear();
    }

    triggerPoints = (reports, date, interval) => {

        if (!isArray(reports["events"]) || !date) return [];
        return reports["events"].filter(e => new Date(e.timestamp*1000).toISOString().startsWith(date.Value)).map(e => {
            return {label: this.stringFromInterval(new Date(e.timestamp*1000).toISOString(), interval), y: e.triggers};
        });
    }

    consumptionChart = (index, generatedReport) => {

        return (
            <div className="vl_charts_chart_container" key={index}>
                <div className="vl_charts_chart_info_container">
                    <p className="vl_charts_chart_info_device">
                        {
                            this.props.Reports[index].subject_type.Name + " - " +
                            this.props.Reports[index].subject.Name
                        }
                    </p>
                    <p className="vl_charts_chart_info_total">
                        {
                            generatedReport.total_power
                                ? "Total - " + generatedReport.total_power.toFixed(2) + " W ( " +
                                this.consumptionTotalForData(generatedReport, this.intervalForIndex(index, generatedReport)).toFixed(2) + " W )"
                                : ""
                        }
                    </p>
                    {
                        this.props.Reports[index].interval.Value !== "year" ?
                            <SelectBox Callback={(t, data) => { this.selectInterval(index, data); }}
                                       Cases={this.intervalCases(generatedReport, this.props.Reports[index])}
                            /> : ""
                    }
                </div>
                <div>
                    <PowerChart
                        Title="Consumption"
                        Data={this.consumptionPoints(
                            generatedReport,
                            this.intervalForIndex(index, generatedReport),
                            this.props.Reports[index].interval.Value)}
                        UseDataAsPoints={true}
                    />
                </div>
            </div>
        );
    }

    distributionChart = (index, generatedReport) => {

        return (
            <div className="vl_charts_chart_container" key={index}>
                <div className="vl_charts_chart_info_container">
                    <p className="vl_charts_chart_info_device">
                        {
                            this.props.Reports[index].subject_type.Name + " - " +
                            this.props.Reports[index].subject.Name
                        }
                    </p>
                    <p className="vl_charts_chart_info_total">
                        {this.distributionInterval(this.props.Reports[index].start, this.props.Reports[index].end)}
                    </p>
                </div>
                <div>
                    <Histogram Title="Distribution"
                               Data={generatedReport}
                    />
                </div>
            </div>
        );
    }

    errorsChart = (index, generatedReport) => {

        return (
            <div className="vl_charts_chart_container" key={index}>
                <div className="vl_charts_chart_info_container">
                    <p className="vl_charts_chart_info_device">
                        {
                            this.props.Reports[index].subject_type.Name + " - " +
                            this.props.Reports[index].subject.Name
                        }
                    </p>
                </div>
                <div>
                    <ErrorsChart VlWidth={"100%"}
                                 Errors={generatedReport}
                    />
                </div>
            </div>
        );
    }

    triggersChart = (index, generatedReport) => {

        let points = this.triggerPoints(generatedReport, this.sensorIntervalForIndex(index, generatedReport), this.props.Reports[index].interval.Value);
        return (
            <div className="vl_charts_chart_container" key={index}>
                <div className="vl_charts_chart_info_container">
                    <p className="vl_charts_chart_info_device">
                        {
                            this.props.Reports[index].subject_type.Name + " - " +
                            this.props.Reports[index].subject.Name
                        }
                    </p>
                    <p className="vl_charts_chart_info_total">{"Total - " + points.reduce((r, e) => {
                        return r + e.y;
                    }, 0) + " triggers"}</p>
                    {
                        this.props.Reports[index].interval.Value !== "year" ?
                            <SelectBox Callback={(t, data) => { this.selectInterval(index, data); }}
                                       Cases={this.sensorIntervalCases(generatedReport, this.props.Reports[index])}
                            /> : ""
                    }
                </div>
                <div>
                    <EventsChart
                        Points={points}
                        Title="Triggers"
                    />
                </div>
            </div>
        );
    }

    intervalsChart = (index, generatedReport) => {

        return (
            <div className="vl_charts_chart_container" key={index}>
                <div className="vl_charts_chart_info_container">
                    <p className="vl_charts_chart_info_device">
                        {
                            this.props.Reports[index].subject_type.Name + " - " +
                            this.props.Reports[index].subject.Name
                        }
                    </p>
                </div>
                <div>
                    <IntervalsChart Data={generatedReport}
                                    Title="Intervals"
                    />
                </div>
            </div>
        );
    }

    charts = () => {

        return this.props.Reports.map((report, i) => {
            let reportId = report.subject.Value+ "-" + report.id.toString();
            if (this.props.GeneratedReports[reportId]) {
                if (report.report_type.Value === "consumption") {
                    return this.consumptionChart(i, this.props.GeneratedReports[reportId]);
                } else if (report.report_type.Value === "distribution") {
                    return this.distributionChart(i, this.props.GeneratedReports[reportId]);
                } else if (report.report_type.Value === "errors") {
                    return this.errorsChart(i, this.props.GeneratedReports[reportId]);
                } else if (report.report_type.Value === "triggers") {
                    return this.triggersChart(i, this.props.GeneratedReports[reportId]);
                } else if (report.report_type.Value === "intervals") {
                    return this.intervalsChart(i, this.props.GeneratedReports[reportId]);
                }
            }
            return ""
        })
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_charts_list"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}
            >
                { this.charts() }
            </div>
        )}
}

ChartsList.propTypes = {
    Reports: PropTypes.array,
    GeneratedReports: PropTypes.object,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
