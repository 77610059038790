export function sensorsFromAreas(areas) {

    let result = [];
    areas.forEach(area => {
        if (area.sensors) result.push(...area.sensors);
        if (area.groups) {
            area.groups.forEach(group => {
                if (group.sensors) result.push(...group.sensors);
            });
        }
    });
    return result;
}

export function sensorFromAreas(sensorId, areas) {

    let result = null;
    areas.forEach(area => {
        if (!result && area.sensors) {
            result = area.sensors.find(sensor => sensor.id === sensorId);
        }
        if (!result && area.groups) {
            area.groups.forEach(group => {
                if (!result && group.sensors) {
                    result = group.sensors.find(sensor => sensor.id === sensorId);
                }
            });
        }
    });
    return result;
}


export function sensorsListToLabelValueFormat(sensors) {

    return sensors.map(sensor => {
        return {
            Name: sensor.mnemonic.length > 0 ?
                sensor.mnemonic :
                sensor.name + (!!sensor.details_list?.href ? " (" + sensor.details_list.href + ")" : ""),
            Value: sensor
        }
    });
}

export function frontEndSensor(sensor) {

    return {
        ...sensor,
        info1: sensorManufacturerFromID(sensor.id),
        info2: sensorServiceFromID(sensor.id),
    }
}

export function sensorManufacturerFromID(id) {

    let parts = id.split("-");
    if (parts.length > 1 && parts[1].length > 0) return parts[1];
    return "Unknown";
}

export function sensorServiceFromID(id) {

    let parts = id.split("-");
    if (parts.length > 4 && parts[4].length > 0) return parts[4];
    return "Unknown";
}