import {GET_DEVICES, REMOVE_DEVICE, SELECT_DEVICE, UPDATE_DEVICE} from "./types";
import {showError} from "./errors";

export const updateDevice = device => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/map/device/update?id=" + device.id,
        {method: 'PUT', credentials: 'include', mode: 'cors', body: JSON.stringify(device)})
        .then(response => {

            if (response.status === 200) {
                dispatch({
                    type: UPDATE_DEVICE,
                    device: device
                });
            } else {
                dispatch(showError("Updating device with id: " + device.id + " - Response status: " + response.status));
            }
        })
        .catch(function(error){

            dispatch(showError("Updating device with id: " + device.id + " - " + error));
        });
}

export const removeDevice = (id, permanent) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/map/device/remove?id=" + id + (permanent ? "&deleted" : ""),
        {method: 'DELETE', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                dispatch({
                    type: REMOVE_DEVICE,
                    device_id: id,
                    permanent: permanent
                });
            } else {
                dispatch(showError("Removing device with id: " + id + " - Response status: " + response.status));
            }
        })
        .catch(function(error){

            dispatch(showError("Removing device with id: " + id + " - " + error));
        });
}

export const getDevices = () => dispatch => {

    _getDevices(dispatch);
}

export const _getDevices = (dispatch) => {

    fetch("http://" + window.location.hostname + ":8080/map/device/get",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                response.json().then(devices => {

                    dispatch({
                        type: GET_DEVICES,
                        devices: devices
                    });
                })
            } else {
                dispatch(showError("Getting devices - Response status: " + response.status));
            }
        })
        .catch(function(error){

            dispatch(showError("Getting devices - " + error));
        });
}

export const setDeviceIntensity = (device, intensity) => dispatch => {

    let data = {
        id: device.id,
        intensity: intensity,
        service: device.service
    }
    fetch("http://" + window.location.hostname + ":8080/map/device/intensity",
        {method: 'POST', credentials: 'include', mode: 'cors', body: JSON.stringify(data)})
        .then(response => {

            if (response.status === 200) {
                device.intensity = intensity;
                dispatch({
                    type: UPDATE_DEVICE,
                    device: device
                });
            } else {
                dispatch(showError("Set device intensity - Response status: " + response.status));
            }
        })
        .catch(function(error){

            dispatch(showError("Set device intensity - " + error));
        });
}

export const testDevice = (id, network, is_group) => dispatch => {

    let device = {device_id: id.toString(), network: network, is_group: !!is_group}
    fetch("http://" + window.location.hostname + ":8080/map/device/test",
        {method: 'POST', credentials: 'include', mode: 'cors', body: JSON.stringify(device)})
        .then(response => {

        })
        .catch(function(error){

            alert("error: " + error)
        })
}

export const selectDevice = device => dispatch => {

    dispatch({
        type: SELECT_DEVICE,
        device: device,
    });
}
