export function devicesFromAreas(areas) {

    let result = [];
    areas.forEach(area => {
        if (area.devices) result.push(...area.devices);
        if (area.groups) {
            area.groups.forEach(group => {
                if (group.devices) result.push(...group.devices);
            });
        }
    });
    return result;
}

export function deviceFromAreas(deviceId, areas) {

    let result = null;
    areas.forEach(area => {
        if (!result && area.devices) {
            result = area.devices.find(device => device.id === deviceId);
        }
        if (!result && area.groups) {
            area.groups.forEach(group => {
                if (!result && group.devices) {
                    result = group.devices.find(device => device.id === deviceId);
                }
            });
        }
    });
    return result;
}

export function groupsFromAreas(areas) {

    let result = [];
    areas.forEach(area => {
        if (area.groups) {
            result.push(...area.groups.map(g => { return {...g, area_id: area.id} }));
        }
    });
    return result;
}

export function selectedGroupsFromAreas(areas) {

    let result = [];
    areas.forEach(area => {
        if (area.selected && area.groups) {
            area.groups.forEach(group => {
                if (group.selected) result.push({...group, area_id: area.id});
            });
        }
    });
    return result;
}

export function frontEndDevice(device) {

    return {
        ...device,
        status: status(device),
        info1: manufacturerFromID(device.id),
        info2: networkFromID(device.id),
    }
}

function status(device) {

    if (device.errors) {
        if (device.errors.availability === 2) {
            return "Device offline";
        }
        if (device.errors.led_load !== 1) {
            return "LED load error";
        }
        if (device.errors.temperature !== 1) {
            return "Temperature error";
        }
        if (device.errors.input_voltage !== 1 && device.errors.input_voltage !== 2) {
            return "Voltage error";
        }
    }
    return "OK";
}

export function manufacturerFromID(id) {

    let parts = id.split("-");
    if (parts.length > 0 && parts[0].length > 0) return parts[0];
    return "Unknown";
}

export function networkFromID(id) {

    let parts = id.split("-");
    if (parts.length > 1 && parts[1].length > 0) return parts[1];
    return "Unknown";
}

export function deviceIsUnused(device) {

    return (!device.area_id || device.area_id < 0) && (!device.group_id || device.group_id < 0);
}

export function nameFromDevice(device) {

    if (device.mnemonic && device.mnemonic.length > 0) return device.mnemonic;
    return device.name;
}