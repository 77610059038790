import React from "react";
import QRCode from 'qrcode.react';
import "../../../styles/pages/profile.css"
import "../../../styles/panels/profile/profile_2fa.css"
import CodeInput from "../../tools/code";
import VlButton from "../../buttons/vl_button";
import PropTypes from "prop-types";

export default class Profile2FA extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            code: "",
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps !== this.props && this.props.User.two_fa_active === true) {
            this.props.Navigate("2fa_approval");
        }
    }

    checkCode = () => {

        this.props.Activate(this.state.code);
    }

    copyKey = () => {

        navigator.clipboard.writeText(this.props.User.two_fa_key)
            .then(() => {})
            .catch(err => {alert(err);});
    }

    render() {

        return (
            <div className="vl_profile_2fa_panel">
                <h1 className="vl_header vl_profile_header">Two-factor authentication</h1>
                <p className="vl_profile_2fa_text">
                    1. Download app to your mobile (
                    <a className="vl_profile_2fa_link" rel="noreferrer" target="_blank" href={"https://apps.apple.com/us/app/google-authenticator/id388497605"}>iOS</a> or
                    <a className="vl_profile_2fa_link" rel="noreferrer" target="_blank" href={"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"}> Android</a>)
                </p>
                <p className="vl_profile_2fa_text">
                    2. Scan QR code (
                    or type <span className="vl_profile_2fa_link" onClick={this.copyKey}>this code</span> into your app manually)
                </p>
                <div className="vl_profile_2fa_qr">
                    <QRCode value={this.props.User.two_fa_key} size={200} renderAs="canvas" />
                </div>
                <p className="vl_profile_2fa_text">
                    3. Enter a code you get from the app
                </p>
                <CodeInput VlStyle={{marginTop: "5vh"}}
                           Black={true}
                           Count={6}
                           Callback={(code) => {this.setState({...this.state, code: code})}}/>
                <VlButton VlClass="vl_profile_2fa_cancel_button"
                          Style="negative"
                          Text="Cancel"
                          Callback={() => {this.props.Navigate("edit")}}
                />
                <VlButton VlClass="vl_profile_2fa_check_button"
                          Text="Check"
                          Callback={this.checkCode}
                />
            </div>
        )
    }
}

Profile2FA.propTypes = {
    User: PropTypes.object,
    Activate: PropTypes.func.isRequired,
    Navigate: PropTypes.func.isRequired
}