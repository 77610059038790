import React from 'react'
import "../../styles/lists/scenes_list.css"
import PropTypes from "prop-types";
import Scene from "../panels/scenes/scene";
import loadingJson from "../../animations/IntensityLoading.json";
import Lottie from "react-lottie-player";

export default class ScenesList extends React.Component {

    componentFromScene = scene => {

        if (this.props.Select) {
            return <Scene key={scene.id}
                          Scene={scene}
                          CheckCallback={() => this.props.Select(scene.id)}
                          Checked={!!scene.selected}
                          DeleteCallback={this.props.DeleteCallback}/>
        }
        return <Scene key={scene.id}
                      Scene={scene}
                      Areas={this.props.Areas}
                      DeleteCallback={this.props.DeleteCallback}
                      EditCallback={this.props.EditCallback}
                      ActivateCallback={this.props.ActivateCallback}
        />
    }

    loading = () => {

        if (!this.props.Loading) return <></>
        return (
            <div className="vl_scenes_list_loading_container">
                <Lottie
                    loop
                    goTo={0}
                    animationData={loadingJson}
                    speed={2}
                    play={true}
                    style={{
                        width: "800px",
                        height: "400px",
                        position: "absolute",
                        top: "45%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                />
            </div>
        )
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_scenes_list"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}
                 disabled={this.props.Loading}>
                { this.loading() }
                { this.props.Scenes.map(scene => this.componentFromScene(scene)) }
            </div>
        )}
}

ScenesList.propTypes = {
    Scenes: PropTypes.array.isRequired,
    Areas: PropTypes.array,
    Loading: PropTypes.bool,

    DeleteCallback: PropTypes.func,
    EditCallback: PropTypes.func,
    ActivateCallback: PropTypes.func,
    Select: PropTypes.func,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
