import {GET_SENSORS, GET_ALL_SENSOR_EVENTS, UPDATE_SENSOR, REMOVE_SENSOR} from "./types";
import {showError} from "./errors";

export const getSensors = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/sensor/list",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                response.json().then(sensors => {

                    dispatch({
                        type: GET_SENSORS,
                        sensors: sensors
                    });
                })
            } else {
                dispatch(showError("Getting sensors - Response status: " + response.status));
            }
        })
        .catch(function(error){

            dispatch(showError("Getting sensors - " + error));
        });
}

export const updateSensor = sensor => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/sensor/update?id=" + sensor.id,
        {method: 'PUT', credentials: 'include', mode: 'cors', body: JSON.stringify(sensor)})
        .then(response => {

            if (response.status === 200) {
                dispatch({
                    type: UPDATE_SENSOR,
                    sensor: sensor
                });
            } else {
                dispatch(showError("Updating sensor with id: " + sensor.id + " - Response status: " + response.status));
            }
        })
        .catch(function(error){

            dispatch(showError("Updating sensor with id: " + sensor.id + " - " + error));
        });
}

export const removeSensor = (id, permanent) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/sensor/remove?id=" + id + (permanent ? "&deleted" : ""),
        {method: 'DELETE', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                dispatch({
                    type: REMOVE_SENSOR,
                    sensor_id: id,
                    permanent: permanent
                });
            } else {
                dispatch(showError("Removing sensor with id: " + id + " - Response status: " + response.status));
            }
        })
        .catch(function(error){

            dispatch(showError("Removing sensor with id: " + id + " - " + error));
        });
}

export const getAllEvents = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/sensor/events",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                response.json().then(events => {

                    dispatch({
                        type: GET_ALL_SENSOR_EVENTS,
                        sensor_events: events.reduce((r, event) => {
                            if (event.id in r) {
                                r[event.id].push({timestamp: event.timestamp, data: event.data});
                            } else {
                                r[event.id] = [{timestamp: event.timestamp, data: event.data}];
                            }
                            return r;
                        }, {})
                    });
                })
            } else {
                dispatch(showError("Getting all sensor events - Response status: " + response.status));
            }
        })
        .catch(function(error){

            dispatch(showError("Getting all sensor events - " + error));
        });
}