import React from 'react'
import "../../../styles/panels/scenes/scene.css"
import PropTypes from "prop-types";
import Lottie from 'react-lottie-player'
import PowerButton from "../../buttons/power"
import editJson from '../../../animations/icons8-edit.json'
import RemoveButton from "../../buttons/remove";
import CheckBox from "../../boxes/checkbox";

export default class Scene extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            opened : false,
            hovered : false,
            showRealtime: false,
        }
    }

    setHovered = status => {

        this.setState({...this.state, hovered: status});
    }

    standardContent = () => {

        return (
            <>
                <div className="vl_scene_container">
                    <div className="vl_scene_info_container vl_scene_active">
                        <h5 className="vl_scene_name">{this.props.Scene.name}</h5>
                        <p className="vl_scene_info">{this.props.Scene.trigger}</p>
                    </div>
                    <RemoveButton VlStyle={{
                        marginTop: "2.6vh",
                        marginRight: "0.2vw",
                        marginLeft: "auto",
                    }} Callback={() => this.props.DeleteCallback(this.props.Scene)}/>
                    <button className="vl_scene_edit_button" onClick={() => this.props.EditCallback(this.props.Scene)}>
                        <Lottie
                            loop
                            goTo={0}
                            animationData={editJson}
                            play={this.state.hovered}
                            style={{ width: "22px", height: "22px" }}
                        />
                    </button>
                    <div className="vl_scene_trigger_container">
                        <PowerButton VlClass="vl_scene_power_button"
                                     Status={this.props.Scene.is_activated}
                                     Callback={() => this.props.ActivateCallback(this.props.Scene.id)}/>
                    </div>
                </div>
            </>
        )
    }

    selectContent = () => {

        return (
            <>
                <CheckBox Callback={this.props.CheckCallback} Checked={this.props.Checked} />
                <div className="vl_select_scene_container">
                    <h5 className="vl_select_scene_name">{this.props.Scene.name}</h5>
                </div>
            </>
        )
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : (this.props.CheckCallback ? "vl_select_scene" : "vl_scene")}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}
                 onMouseOver={() => this.setHovered(true)}
                 onMouseLeave={() => this.setHovered(false)}
            >
                {
                    this.props.CheckCallback ? this.selectContent() : this.standardContent()
                }
            </div>
        )
    }
}

Scene.propTypes = {
    Scene: PropTypes.object.isRequired,
    Areas: PropTypes.array,

    DeleteCallback: PropTypes.func,
    CheckCallback: PropTypes.func,
    EditCallback: PropTypes.func,
    ActivateCallback: PropTypes.func,
    Checked: PropTypes.bool,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
