import React from 'react'
import "../../../styles/panels/reports/add_custom_report.css"
import PropTypes from "prop-types";

export default class AddCustomReport extends React.Component {

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_add_custom_report"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}
                 onClick={this.props.AddCallback}
            >
                <p className="vl_add_custom_report_icon"/>
            </div>
        )
    }
}

AddCustomReport.propTypes = {
    AddCallback: PropTypes.func,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
