export function classForBrowser (className, prefix) {

    let userAgent = navigator.userAgent;
    let result = className + (prefix || "_");

    if(userAgent.match(/chrome|chromium|crios/i)){
        result += "chrome";
    } else if(userAgent.match(/safari/i)){
        result += "safari";
    } else if(userAgent.match(/opr\//i)){
        result += "opera";
    } else if(userAgent.match(/edg/i)){
        result += "edge";
    }else{
        result = className;
    }
    return result;
}
