import {
    GET_SCENES,
    ADD_SCENE,
    DELETE_SCENE,
    SAVE_SCENE,
    ACTIVATE_SCENE,
    SIGN_OUT,
    MODIFY_SCENES_LIST
} from "./types";

export const getScenes = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/scenes/data",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                response.json().then(scenes => {

                    dispatch({
                        type: GET_SCENES,
                        scenes: scenes
                    })
                })
            } else if (response.status === 401) {
                dispatch({type: SIGN_OUT})
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const addScene = (scene_name, intensityList) => dispatch => {

    let scene = {
        name: scene_name,
        description: scene_name,
        groups: intensityList,
        is_activated: false
    }

    fetch("http://" + window.location.hostname + ":8080/scenes/create",
        {method: 'POST', credentials: 'include', mode: 'cors', body: JSON.stringify(scene)})
        .then(response => response.json())
        .then(scene => {

            dispatch({
                type: ADD_SCENE,
                scene: scene
            })
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const activateScene = (scene_id) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/scenes/recall?id=" + scene_id,
        {method: 'POST', credentials: 'include', mode: 'cors'})
        .then(response => {

            dispatch({
                type: ACTIVATE_SCENE,
                success: response.status === 200,
                scene_id: scene_id
            })
        })
        .catch(function(error){

            alert("error: " + error)
        })
}

export const saveScene = (scene) => dispatch => {

    scene.is_new = false
    fetch("http://" + window.location.hostname + ":8080/scenes/update",
        {method: 'PUT', credentials: 'include', mode: 'cors', body: JSON.stringify(scene)})
        .then(response => {

            dispatch({
                type: SAVE_SCENE,
                success: response.status === 200,
                scene: scene
            })
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const deleteScene = (scene_id) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/scenes/remove?id=" + scene_id,
        {method: 'DELETE', credentials: 'include', mode: 'cors'})
        .then(response => {

            dispatch({
                type: DELETE_SCENE,
                success: response.status === 200,
                scene_id: scene_id
            })
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const modifyScenesList = (sort_field, sort_activated, search_text) => dispatch => {

    dispatch({
        type: MODIFY_SCENES_LIST,
        sort_field: sort_field,
        reverse_sort_activated: sort_activated,
        search_text: search_text,
    })
};

export const realtimeUpdate = (scene_group_intensities, finalize) => dispatch => {

    let parameters = finalize ? "?finalize=1" : "";
    fetch("http://" + window.location.hostname + ":8080/scenes/live_update" + parameters,
        {method: 'POST', credentials: 'include', mode: 'cors', body: JSON.stringify(scene_group_intensities)})
        .then(response => {

        })
        .catch(function(error){

            alert("error: " + error)
        })
};