import React from 'react'
import "../../styles/lists/custom_reports_list.css"
import PropTypes from "prop-types";
import AddCustomReport from "../panels/reports/add_custom_report";
import CustomReport from "../panels/reports/custom_report";

export default class CustomReportsList extends React.Component {

    reports = () => {

        return this.props.Reports.map(r => {
            return <CustomReport Report={r}
                                 key={r.id}
                                 Areas={this.props.Areas}
                                 Groups={this.props.Groups}
                                 Devices={this.props.Devices}
                                 Sensors={this.props.Sensors}
                                 DeleteCallback={this.removeReportCallback}
                                 ChangeCallback={this.changeReportCallback}
            />
        })
    }

    createReport = () => {

        let now = new Date();
        let time = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).getTime() / 1000;
        return {
            id: this.props.Reports.length > 0 ? this.props.Reports[this.props.Reports.length - 1].id + 1 : 1,
            subject_type: { Name: "Device", Value: "device" },
            subject: this.props.Devices.length > 0 ? {Name: this.props.Devices[0].id, Value: this.props.Devices[0].id} : null,
            report_type: { Name: "Consumption", Value: "consumption" },
            interval: { Name: "By Hour", Value: "hour" },
            start: time,
            end: time,
        }
    }

    addReportCallback = () => {

        this.props.Change([...this.props.Reports, this.createReport()]);
    }

    removeReportCallback = id => {

        this.props.Change(this.props.Reports.filter(r => r.id !== id));
    }

    changeReportCallback = report => {

        this.props.Change(this.props.Reports.map(r => {
            if (r.id === report.id)
                return report;
            return r;
        }));
    }

    addReport = () => {

        return <AddCustomReport AddCallback={this.addReportCallback} />
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_custom_reports_list"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}
            >
                { this.reports() }
                { this.addReport() }
            </div>
        )}
}

CustomReportsList.propTypes = {
    Change: PropTypes.func.isRequired,

    Reports: PropTypes.array,
    Areas: PropTypes.array,
    Groups: PropTypes.array,
    Devices: PropTypes.array,
    Sensors: PropTypes.array,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
