import React from 'react'
import "../../styles/panels/not_available.css"
import PropTypes from "prop-types";

export default class NotAvailablePanel extends React.Component {

    render() {

        return (
            <div className={"vl_not_available_panel_container " + (this.props.VlClass ? this.props.VlClass : "")}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_not_available_panel_icon"/>
                <p className="vl_not_available_panel_text">{this.props.Text || "The feature is not available for now"}</p>
            </div>
        )
    }
}

NotAvailablePanel.propTypes = {
    Text: PropTypes.string,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
