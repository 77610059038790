import React from "react";
import PropTypes from "prop-types";
import "../../../styles/pages/profile.css"
import "../../../styles/panels/profile/profile_edit.css"
import EditButton from "../../buttons/edit";
import ConfirmationDialog from "../../dialogs/confirmation";
import VlButton from "../../buttons/vl_button";
import loadingJson from "../../../animations/Loading.json";
import Lottie from "react-lottie-player";

export default class ProfileEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deactivationDialogOpened: false,
            inputData: {},
            error: null,
        }
    }

    setErrorMessage = (type, message) => {

        this.setState({...this.state, error: {type: type, message: message}});
    }

    errorMessage = name => {

        if (this.state.error?.type !== name) { return "" }
        return (
            <p className="vl_profile_edit_error_message">{this.state.error?.message || ""}</p>
        )
    }

    changeEntry = (name, data) => {

        let inputData = {...this.state.inputData};
        inputData[name] = data;
        this.setState({...this.state, inputData: inputData, error: null})
    }

    editEntry = (header, name, data) => {

        return (
            <div className="vl_profile_entry">
                <p className="vl_profile_title">{header}</p>
                <input className="vl_profile_edit"
                       defaultValue={data}
                       disabled={name === "username"}
                       type="text"
                       onChange={e => this.changeEntry(name, e.target.value)}
                />
                {this.errorMessage(name)}
            </div>
        )
    }

    editPassword = () => {

        return (
            <div className="vl_profile_entry vl_profile_edit_password_panel">
                <div>
                    <p>Password</p>
                    <input type={"password"}
                           onChange={e => this.changeEntry("password", e.target.value)}
                    />
                </div>
                <div>
                    <p className="vl_profile_edit_password_left_5">Confirm Password</p>
                    <input className="vl_profile_edit_password_left_5"
                           type={"password"}
                           onChange={e => this.changeEntry("repeat_password", e.target.value)}
                    />
                </div>
                {this.errorMessage("password")}
            </div>
        )
    }

    editContent = () => {

        return (
            <>
                {this.editEntry("Name", "username", this.props.User?.username || "")}
                {this.editEntry("Email", "email", this.props.User?.email || "")}
                {this.editPassword()}
                {this.editEntry("Phone", "phone_number", this.props.User?.phone_number || "")}
            </>
        )
    }

    subtext = () => {

        if (this.props.Saving) {
            return (
                <Lottie
                    loop
                    goTo={0}
                    animationData={loadingJson}
                    play={true}
                    className="vl_profile_edit_loading"
                />
            )
        }

        return (
            <button className="vl_profile_href vl_profile_2fa_href"
                    onClick={this.on2faClicked}>
                {this.props.User?.two_fa_active ? "Deactivate two-factor authenticator" : "Activate two-factor authenticator"}
            </button>
        )
    }

    on2faClicked = () => {

        if (this.props.User.two_fa_active)
            this.setState({...this.state, deactivationDialogOpened: true});
        else
            this.props.Navigate("2fa")
    }

    deactivate2fa = confirmed => {

        this.setState({...this.state, deactivationDialogOpened: false});
        if (confirmed) {
            this.props.Deactivate();
        }
    }

    saveUser = () => {

        if (this.state.inputData.password !== this.state.inputData.repeat_password) {
            this.setErrorMessage("password", "repeated password does not match");
            return;
        }
        let updatedUser = {...this.props.User, ...this.state.inputData};
        this.props.Save(updatedUser);
    }

    render() {

        return (
            <div className="vl_profile_edit_panel">
                <h1 className="vl_header vl_profile_header">Admin</h1>
                <EditButton VlStyle={{
                    position: "absolute",
                    top: "12%",
                    right: "6.6%",
                    display: this.props.Saving ? "none" : ""
                }} Callback={() => {this.props.Navigate("info")}}/>
                {this.editContent()}
                {this.subtext()}
                <VlButton VlClass="vl_profile_edit_save"
                          Text="Save"
                          Callback={this.saveUser}/>
                {
                    this.state.deactivationDialogOpened ?
                        <ConfirmationDialog Callback={this.deactivate2fa}
                                            Text="Are you sure you want to deactivate two factor authentication?"
                        /> : ""
                }
            </div>
        )
    }
}

ProfileEdit.propTypes = {
    User: PropTypes.object,
    Saving: PropTypes.bool.isRequired,
    Deactivate: PropTypes.func.isRequired,
    Save: PropTypes.func.isRequired,
    ChangePassword: PropTypes.func.isRequired,
    Navigate: PropTypes.func.isRequired
}