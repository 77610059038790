import React from 'react'
import "../../styles/boxes/edit.css"
import PropTypes from "prop-types";

export default class EditBox extends React.Component {

    render() {

        return (
            <div className={"vl_edit_container " + (this.props.VlClass ? this.props.VlClass : "")}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <input type="text"
                       defaultValue={this.props.Default}
                       className={this.props.VlEditClass ? this.props.VlEditClass : "vl_edit_input"}
                       style={this.props.VlEditStyle ? this.props.VlEditStyle : {}}
                       onChange={e => {this.props.Callback(e.target.value)}}
                       placeholder={this.props.Placeholder}
                       disabled={this.props.Disabled}
                />
            </div>
        )
    }
}

EditBox.propTypes = {
    Callback: PropTypes.func.isRequired,
    Placeholder: PropTypes.string,
    Default: PropTypes.string,
    Disabled: PropTypes.bool,

    VlEditClass: PropTypes.string,
    VlEditStyle: PropTypes.object,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
