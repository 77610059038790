import {GET_INFO, SAVE_INFO, ACTIVATE_2FA, RESET_2FA, CHANGE_PASSWORD, SIGN_OUT, SAVING_INFO} from "../actions/types";

const initialState = {
    currentUser: null,
    saving: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {

    switch(action.type) {

        case GET_INFO:
            return { ...state, currentUser: action.user }

        case SAVING_INFO:
            return { ...state, saving: true }

        case SAVE_INFO:
            if (action.success)
                return { ...state, currentUser: action.user, saving: false }
            return { ...state, saving: false }

        case SIGN_OUT:
            if (action.success)
                return { ...state, currentUser: null }
            return state

        case CHANGE_PASSWORD:
            if (action.success)
                return { ...state, currentUser: action.user }
            return state

        case ACTIVATE_2FA:
            if (action.success)
                return { ...state, currentUser: {...state.currentUser, two_fa_active: true} }
            return state

        case RESET_2FA:
            if (action.success)
                return { ...state, currentUser: {...state.currentUser, two_fa_active: false} }
            return state

        default:
            return state;
    }
}