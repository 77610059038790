import L from "leaflet";
import {pointIsInsidePolygon} from "./helpers";

let devices = [];
let selectedDeviceId = "";
let selectCallback = () => {};
let createCallback = () => {};
let moveCallback = () => {};
let dropCallback = () => {};
let removeCallback = () => {};

export function setupDevicesCallbacks (_selectCallback, _createCallback, _moveCallback, _dropCallback, _removeCallback) {

    if (typeof _selectCallback === 'function') selectCallback = _selectCallback;
    if (typeof _createCallback === 'function') createCallback = _createCallback;
    if (typeof _moveCallback === 'function') moveCallback = _moveCallback;
    if (typeof _dropCallback === 'function') dropCallback = _dropCallback;
    if (typeof _removeCallback === 'function') removeCallback = _removeCallback;
}

export function setDevices (_devices, place) {

    removeAllMarkers();
    _devices.filter(d => d.position?.place === place).forEach(d => {
        createCallback(d, d.position);
    });
}

export function selectDevice (id) {

    devices.forEach(device => {
        if (device.id === id) {
            device.marker.setIcon(iconFromDevice(device, id));
            selectedDeviceId = device.id;
            selectCallback(device);
        } else {
            device.marker.setIcon(iconFromDevice(device, id));
        }
    })
}

export function unselectAllDevices () {

    selectedDeviceId = "";
    selectCallback(null);
    devices.forEach(device => {
        device.marker.setIcon(iconFromDevice(device));
    })
}

export function createMarker (device, pos) {

    let latLng = pos ? { lat: pos.lat, lng: pos.lng } : { lat: 0, lng: 0 };
    let marker = L.marker(latLng, {
        icon: iconFromDevice(device, selectedDeviceId),
        draggable: true,
    });
    marker.on('click', () => selectDevice(device.id));
    marker.on('move', () => moveCallback(device.id, marker))
    marker.on("dragend", () => dropCallback(device.id, marker));
    devices.push({id: device.id, name: device.name, mnemonic: device.mnemonic, status: device.status, marker: marker});
    return marker;
}

function iconFromDevice(device, selectedId) {

    if (device.id.startsWith("sensor")) {
        if (device.id.includes("occupancy")) {
            return device.id === selectedId ? activeOccupancyIcon(device) : occupancyIcon(device);
        } else if (device.id.includes("button")) {
            return device.id === selectedId ? activeButtonIcon(device) : buttonIcon(device);
        }
        return device.id === selectedId ? activeUnknownIcon(device) : unknownIcon(device);
    }
    return device.id === selectedId ? activeLightIcon(device) : lightIcon(device);
}

export function removeMarker (device) {

    let d = devices.find(d => d.id === device.id);
    if (!d) return false;

    removeCallback(d.marker);
    devices = devices.filter(d => d.id !== device.id);
}

export function removeAllMarkers () {

    devices.forEach(d => removeCallback(d.marker));
    devices = [];
}

function nameFromDevice (device) {

    if (device.mnemonic && device.mnemonic.length > 0) return device.mnemonic;
    return device.name;
}

function classFromDevice (device) {

    if (device.id.startsWith("sensor")) return "vl_map_marker_sensor";
    if (device.status !== "OK") return "vl_map_marker_off";
    return "vl_map_marker";
}

export function lightIcon (device) {

    return L.divIcon({
        html: '<div class="' + classFromDevice(device) + ' vl_map_marker_light"><p>' + nameFromDevice(device) + '</p></div>',
        className: 'dummy',
    })
}

export function activeLightIcon (device) {

    return L.divIcon({
        html: '<div class="vl_map_marker_active vl_map_marker_active_light"><p>' + nameFromDevice(device) + '</p></div>',
        className: 'dummy',
    })
}

export function occupancyIcon (device) {

    return L.divIcon({
        html: '<div class="'+ classFromDevice(device) + ' vl_map_marker_occupancy"><p>' + nameFromDevice(device) + '</p></div>',
        className: 'dummy',
    })
}

export function activeOccupancyIcon (device) {

    return L.divIcon({
        html: '<div class="vl_map_marker_active vl_map_marker_active_occupancy"><p>' + nameFromDevice(device) + '</p></div>',
        className: 'dummy',
    })
}

export function buttonIcon (device) {

    return L.divIcon({
        html: '<div class="'+ classFromDevice(device) + ' vl_map_marker_button"><p>' + nameFromDevice(device) + '</p></div>',
        className: 'dummy',
    })
}

export function activeButtonIcon (device) {

    return L.divIcon({
        html: '<div class="vl_map_marker_active vl_map_marker_active_button"><p>' + nameFromDevice(device) + '</p></div>',
        className: 'dummy',
    })
}

export function unknownIcon (device) {

    return L.divIcon({
        html: '<div class="'+ classFromDevice(device) + ' vl_map_marker_unknown"><p>' + nameFromDevice(device) + '</p></div>',
        className: 'dummy',
    })
}

export function activeUnknownIcon (device) {

    return L.divIcon({
        html: '<div class="vl_map_marker_active vl_map_marker_active_unknown"><p>' + nameFromDevice(device) + '</p></div>',
        className: 'dummy',
    })
}

export function deviceIsUsed(device) {

    if (!device) return true;
    return device.position && (typeof device.position.place === "string") && device.position.place.length > 0;
}

export function devicesContainId(id) {

    return !!devices.find(d => d.id === id);
}

export function devicesInsideLayer(layer) {

    return devices.filter(
        device => pointIsInsidePolygon(device.marker.getLatLng(), layer._latlngs[0])
    ).map(device => { return device.id });
}

export function devicesInsideGeometry(geometry) {

    return devices.filter(
        device => pointIsInsidePolygon(device.marker.getLatLng(), geometry)
    ).map(device => { return device.id });
}