import React from 'react'
import "../../styles/buttons/power.css"
import PropTypes from "prop-types";

export default class PowerButton extends React.Component {

    render() {
        return (
            <button
                className={this.props.VlClass ? this.props.VlClass : "vl_power_button"}
                style={this.props.VlStyle ? this.props.VlStyle : {}}
                onClick={this.props.Callback}>
                <p className="vl_power_button_icon" />
            </button>
        )}
}

PowerButton.propTypes = {
    Callback: PropTypes.func,
    Status: PropTypes.bool,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
