import {
    GET_DETAILS,
    GET_HARDWARE_GROUPS,
    UPDATE_HARDWARE_GROUP,
    SIGN_OUT,
} from "./types";

export const getDetails = (id) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/hardware/details?id=" + id,
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                response.json().then(details => {

                    dispatch({
                        type: GET_DETAILS,
                        details: details
                    })
                })
            } else if (response.status === 401) {
                dispatch({type: SIGN_OUT})
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
}

export const getHardwareGroups = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/hardware/groups",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                response.json().then(groups => {

                    dispatch({
                        type: GET_HARDWARE_GROUPS,
                        groups: groups
                    })
                })
            } else if (response.status === 401) {
                dispatch({type: SIGN_OUT})
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const updateHardwareGroup = (group) => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/hardware/update_group",
        {method: 'POST', credentials: 'include', mode: 'cors', body: JSON.stringify(group)})
        .then(response => {

            dispatch({
                type: UPDATE_HARDWARE_GROUP,
                group_id: group.id,
                x: group.x,
                y: group.y,
                place: group.place,
            })
        })
        .catch(function(error){

            alert("error: " + error)
        })
}
