import React from 'react'
import "../../styles/buttons/arrow.css"
import PropTypes from "prop-types";

export default class ArrowButton extends React.Component {

    render() {
        return (
            <button
                className={this.props.VlClass ? this.props.VlClass : "vl_arrow_button"}
                style={this.props.VlStyle ? this.props.VlStyle : {}}
                onClick={this.props.Callback}>
                <p className={this.props.Status ? "vl_arrow_button_icon_reversed" : "vl_arrow_button_icon"} />
            </button>
        )}
}

ArrowButton.propTypes = {
    Callback: PropTypes.func,
    Status: PropTypes.bool,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
