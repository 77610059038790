import React from 'react'
import PropTypes from "prop-types";

export default class Shifter extends React.Component {

    render() {

        let Style = this.props.VlStyle || {}
        if (this.props.Vertical) {
            return <div style={{...Style, height: (this.props.Size ? this.props.Size : "1rem") }}/>
        }
        return (
            <div style={{...Style, width: (this.props.Size ? this.props.Size : "1rem") }}/>
        )}
}

Shifter.propTypes = {
    Size: PropTypes.string,
    Vertical: PropTypes.bool,
    VlStyle: PropTypes.object,
}
