import React from 'react'
import "../styles/pages/main.css"
import "../styles/general.css"
import Tabs from "../components/general/tabs";
import DevicesTab from "../components/tabs/devices_tab";
import ScenesTab from "../components/tabs/scenes_tab";
import ActivationTab from "../components/tabs/activation_tab"
import Map from "../components/map/map";
import {connect} from "react-redux";
import {getAreas, updateArea, modifyDevicesList, createGroup, updateGroup, removeGroup, setGroupIntensity} from "../../actions/areas";
import {getScenes, addScene, saveScene, deleteScene, activateScene, modifyScenesList, realtimeUpdate} from "../../actions/scenes";
import {getActivationsByDate, createActivationByDate, updateActivationByDate, removeActivationByDate, setActiveActivationByDate} from "../../actions/activation_by_date";
import {getPlaces} from "../../actions/places";
import {getDevices, removeDevice, setDeviceIntensity, updateDevice} from "../../actions/device";
import {getSensors, updateSensor, removeSensor} from "../../actions/sensors";

class Main extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            Selected: "Devices",
        }
    }

    componentDidMount() {

        this.props.getAreas();
        this.props.getPlaces();
        this.props.getSensors();
        this.props.realtimeUpdate([], true);
    }

    tabFromSelected = () => {

        switch (this.state.Selected) {
            case "Devices":
                return <DevicesTab Areas={this.props.modifiedAreas}
                                   OriginalAreas={this.props.areas}
                                   UnusedDevices={this.props.unused_devices}
                                   SelectedDevice={this.props.selectedDevice}
                                   Sensors={this.props.sensors}
                                   Remove={this.props.removeDevice}
                                   RemoveSensor={this.props.removeSensor}
                                   Modify={this.props.modifyDevicesList}
                                   UpdateArea={this.props.updateArea}
                                   CreateGroup={this.props.createGroup}
                                   UpdateGroup={this.props.updateGroup}
                                   RemoveGroup={this.props.removeGroup}
                                   ChangeDeviceIntensity={this.props.setDeviceIntensity}
                                   ChangeGroupIntensity={this.props.setGroupIntensity}
                                   GroupsIntensityProgress={this.props.groupsIntensityProgress}
                                   DevicesIntensityProgress={this.props.devicesIntensityChanging}
                                   UpdateDevice={this.props.updateDevice}
                                   UpdateSensor={this.props.updateSensor}
                />
            case "Scenes":
                return <ScenesTab Areas={this.props.areas}
                                  Scenes={this.props.modifiedScenes}
                                  SceneChanging={this.props.sceneChanging}
                                  GetScenes={this.props.getScenes}
                                  AddScene={this.props.addScene}
                                  SaveScene={this.props.saveScene}
                                  DeleteScene={this.props.deleteScene}
                                  ActivateScene={this.props.activateScene}
                                  RealtimeSceneUpdate={this.props.realtimeUpdate}
                                  Modify={this.props.modifyScenesList}
                />
            case "Activation":
                return <ActivationTab ActivationsByDate={this.props.activationsByDate}
                                      Scenes={this.props.scenes}
                                      GetScenes={this.props.getScenes}
                                      GetActivationByDate={this.props.getActivationsByDate}
                                      CreateActivationByDate={this.props.createActivationByDate}
                                      UpdateActivationByDate={this.props.updateActivationByDate}
                                      RemoveActivationByDate={this.props.removeActivationByDate}
                                      SetActiveActivationByDate={this.props.setActiveActivationByDate}
                />
            default:
                return <></>
        }
    }

    render() {

        return (
            <div className="vl_main_panel">
                <div className="vl_left_panel">
                    <Tabs VlStyle={{height: "10%", marginTop: "1.8rem", width: "80%"}}
                          Titles={["Devices", "Scenes", "Activation"]}
                          Selected={this.state.Selected}
                          Callback={(tab) => { this.setState({...this.state, Selected: tab}) }}/>
                    {this.tabFromSelected()}
                </div>
                <Map Areas={this.props.areas}
                     Sensors={this.props.sensors}
                     Places={this.props.places}
                     UnusedDevices={this.props.unused_devices}
                />
            </div>
        )
    }
}

const mapStateToMainProps = state => ({
    areas: state.areas.areas,
    modifiedAreas: state.areas.modifiedAreas,
    unused_devices: state.areas.unused_devices,
    selectedDevice: state.areas.selectedDevice,
    sensors: state.areas.sensors,
    updated: state.areas.updated,
    places: state.places.places,
    activationsByDate: state.activations.activations_by_date,
    scenes: state.scenes.scenes,
    modifiedScenes: state.scenes.modifiedScenes,
    groupsIntensityProgress: state.progress.groupsIntensityChanging,
    devicesIntensityChanging: state.progress.devicesIntensityChanging,
    sceneChanging: state.progress.sceneChanging,
})

export default connect(mapStateToMainProps, {
    getDevices, getAreas, getPlaces, getSensors, updateDevice, updateSensor,
    removeDevice, removeSensor, modifyDevicesList, setDeviceIntensity,
    updateArea, createGroup, updateGroup, removeGroup, setGroupIntensity,
    getScenes, addScene, saveScene, deleteScene, activateScene, modifyScenesList, realtimeUpdate,
    getActivationsByDate, createActivationByDate, updateActivationByDate, removeActivationByDate, setActiveActivationByDate
})(Main);