import React from 'react'
import "../../styles/boxes/nameeditbox.css"
import PropTypes from "prop-types";

export default class NameEditBox extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            value: this.props.Default || ""
        }
    }

    render() {

        return (
            <div className={"vl_name_edit_container " + (this.props.VlClass ? this.props.VlClass : "")}>
                <input type="text"
                       value={this.state.value}
                       className={"vl_name_edit_input " + (this.props.VlEditClass ? this.props.VlEditClass : "")}
                       onChange={e => {this.setState({...this.state, value: e.target.value})}}
                />
                <p className="vl_name_edit_accept" onClick={() => this.props.Change(this.state.value)}/>
                <p className="vl_name_edit_cancel" onClick={this.props.Cancel}/>
                <p className={"vl_name_edit_error " + (this.props.VlErrorClass ? this.props.VlErrorClass : "")}>{this.props.Error}</p>
            </div>
        )
    }
}

NameEditBox.propTypes = {
    Change: PropTypes.func.isRequired,
    Cancel: PropTypes.func.isRequired,
    Default: PropTypes.string,
    Error: PropTypes.string,

    VlEditClass: PropTypes.string,
    VlErrorClass: PropTypes.string,
    VlClass: PropTypes.string,
}
