import React from 'react'
import PropTypes from "prop-types";
import "../../../styles/tabs/reports/custom.css"
import CustomReportsList from "../../lists/custom_reports_list";
import VlButton from "../../buttons/vl_button";
import {devicesFromAreas, groupsFromAreas} from "../../../../utils/devices";
import ChartsList from "../../lists/charts_list";

export default class CustomReports extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            generated: false,
            reports: [],
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (Object.keys(this.props.GeneratedReports)?.length !== Object.keys(prevProps.GeneratedReports)?.length) {
            if (Object.keys(this.props.GeneratedReports).length > 0 && !this.state.generated) {
                this.setState({...this.state, generated: true});
            }
        }
    }

    componentWillUnmount() {

        this.closeCharts();
    }

    areas = () => {

        return this.props.Areas;
    }

    groups = () => {

        return groupsFromAreas(this.props.Areas);
    }

    devices = () => {

        return [...devicesFromAreas(this.props.Areas), ...this.props.UnusedDevices];
    }

    sensors = () => {

        return this.props.Sensors;
    }

    closeCharts = () => {

        this.setState({...this.state, generated: false}, () => {
            this.props.CleanReports();
        });
    }

    reportsList = () => {

        return <CustomReportsList Reports={this.state.reports}
                                  Areas={this.areas()}
                                  Groups={this.groups()}
                                  Devices={this.devices()}
                                  Sensors={this.sensors()}
                                  Change={this.reportsChanged}
        />
    }

    reportsCharts = () => {

        return <ChartsList Reports={this.state.reports}
                           GeneratedReports={this.props.GeneratedReports}
        />
    }

    reportsChanged = reports => {

        this.setState({...this.state, reports: reports});
    }

    generateReport = () => {

        this.state.reports.forEach(report => {
            this.props.GenerateReport(
                report.id,
                report.subject_type.Value,
                report.subject.Value,
                report.interval.Value,
                report.report_type.Value,
                report.start,
                report.end + 86400
            );
        });
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_custom_reports_tab"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                {
                    this.state.generated ?
                        <>
                            <div className="vl_custom_reports_charts_list_close_icon" onClick={this.closeCharts} />
                            {this.reportsCharts()}
                        </> :
                        this.reportsList() }
                <VlButton Text="Generate"
                          VlClass="vl_custom_reports_tab_generate"
                          VlStyle={this.state.generated ? {display: "none"} : {}}
                          Callback={() => this.generateReport()}
                />
            </div>
        )
    }
}

CustomReports.propTypes = {
    GenerateReport: PropTypes.func.isRequired,
    CleanReports: PropTypes.func.isRequired,

    GeneratedReports: PropTypes.object.isRequired,
    Areas: PropTypes.array.isRequired,
    UnusedDevices: PropTypes.array.isRequired,
    Sensors: PropTypes.array.isRequired,

    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}