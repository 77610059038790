//  EditListFromAreasAndScene function returns new list of areas which have intensities according to
// scene's areas and selection status of true if area is present in scene.
export function EditListFromAreasAndScene (areas, scene) {

    if (!areas) return [];
    return areas.map(area => {

        let areaUsed = null;
        if (scene && scene.groups) {
            if (scene.groups.find(g => g.area_id === area.id)) { areaUsed = true }
        }
        return {
            ...area,
            selected: areaUsed,
            groups: area.groups.map(group => {
                let groupUsed = null;
                if (areaUsed) {
                    groupUsed = scene.groups.find(g => g.area_id === area.id && g.group_id === group.id);
                }
                return {
                    id: group.id,
                    name: group.name,
                    devices: group.devices,
                    selected: !!groupUsed,
                    intensity: groupUsed ? groupUsed.intensity: 0
                }
            })
        }
    })
}

// TriggerAreaInEditList function triggers selection status of area with area_id
export function TriggerAreaInEditList (editList, area_id) {

    return editList.map(area => {
        if (area.id === area_id) {
            return {...area, selected: !area.selected};
        }
        return area;
    })
}

// TriggerAreaInEditList function triggers selection status of group with group_id in area with area_id
export function TriggerGroupInEditList (editList, area_id, group_id) {

    return editList.map(area => {
        if (area.id === area_id) {
            return {...area, groups: area.groups.map(group => {
                    if (group.id === group_id) {
                        return {...group, selected: !group.selected};
                    }
                    return group;
                })
            };
        }
        return area;
    })
}

// ChangeAreaIntensityInEditList function changes intensity of group with group_id in area with area_id
export function ChangeGroupIntensityInEditList (editList, intensity, area_id, group_id) {

    return editList.map(area => {
        if (area.id === area_id) {
            area.groups = area.groups.map(group => {
                if (group.id === group_id) {
                    group.intensity = intensity;
                }
                return group;
            })
        }
        return area;
    })
}

// SelectionSceneListFromScenes function returns list of selectable scenes from scenes list
export function SelectionSceneListFromScenes (scenes, selectedIds) {

    return scenes.map(scene => {
        return {...scene, selected: !!selectedIds.find(id => id === scene.id)}
    })
}

// IdsOfSelectedScenes function returns list of selected scenes' ids from scenes list
export function IdsOfSelectedScenes (scenes) {

    return scenes.filter(scene => {
        return !!scene.selected
    }).map(s => {return s.id})
}

// IdsAndNamesOfSelectedScenes function returns list of selected scenes' ids and names from scenes list
export function IdsAndNamesOfSelectedScenes (scenes) {

    return scenes.filter(scene => {
        return !!scene.selected
    }).map(s => {return {id: s.id, name: s.name}})
}

// TriggerSceneInList function triggers selection status of scene with scene_id
export function TriggerSceneInList (scenesList, scene_id) {

    return scenesList.map(scene => {
        if (scene.id === scene_id) {
            return {...scene, selected: !scene.selected};
        }
        return scene;
    })
}