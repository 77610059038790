import {CLEAR_GENERATED_REPORT, GENERATE_REPORT, GET_HISTOGRAM, GET_HISTOGRAM_META, GET_REPORTS} from "./types";

export const getReports = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/reports/data",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                response.json().then(reports => {

                    dispatch({
                        type: GET_REPORTS,
                        reports: reports
                    })
                })
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const getHistogramMeta = () => dispatch => {

    fetch("http://" + window.location.hostname + ":8080/reports/histogram_meta",
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => {

            if (response.status === 200) {
                response.json().then(meta => {

                    dispatch({
                        type: GET_HISTOGRAM_META,
                        meta: meta,
                    })
                })
            }
        })
        .catch(function(error){

            alert("error: " + error)
        })
}

export const getHistogram = (area_id, group_id, period) => dispatch => {

    if (area_id === -1 || group_id === -1 || period === -1) return

    fetch("http://" + window.location.hostname + ":8080/reports/histogram?area_id=" + area_id +
        "&group_id=" + group_id + "&period=" + period,
        {method: 'GET', credentials: 'include', mode: 'cors'})
        .then(response => response.json())
        .then(histogram => {

            dispatch({
                type: GET_HISTOGRAM,
                histogram: histogram,
                area_id: area_id,
                group_id: group_id,
                period: period
            })
        })
        .catch(function(error){

            alert("error: " + error)
        })
};

export const generateReport = (id, subject, subject_id, division, type, start, end) => dispatch => {

    let data = {
        subject_type: subject,
        subject: subject_id.toString(),
        report_type: type,
        interval: division,
        start: start,
        end: end,
    }
    fetch("http://" + window.location.hostname + ":8080/reports/generate",
        {method: 'POST', credentials: 'include', mode: 'cors', body: JSON.stringify(data)})
        .then(response => response.json())
        .then(report => {

            dispatch({
                type: GENERATE_REPORT,
                id: id,
                subject: subject,
                subject_id: subject_id,
                division: division,
                report_type: type,
                start: start,
                end: end,
                report: report
            })
        })
        .catch(function(error){

            dispatch({
                type: GENERATE_REPORT,
                subject: subject,
                subject_id: subject_id,
                division: division,
                report_type: type,
                start: start,
                end: end,
                report: {id: subject_id}
            })
        })
}

export const clearGeneratedReport = () => dispatch => {

    dispatch({
        type: CLEAR_GENERATED_REPORT,
    })
}