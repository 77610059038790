import React from "react";
import PropTypes from "prop-types";
import "../../../styles/pages/profile.css"
import "../../../styles/panels/profile/profile_info.css"
import EditButton from "../../buttons/edit";
import ConfirmationDialog from "../../dialogs/confirmation";

export default class ProfileInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteAccountDialogOpened: false,
        }
    }

    infoEntry = (header, data) => {

        return (
            <div className="vl_profile_entry">
                <p className="vl_profile_title">{header}</p>
                <input className="vl_profile_data"
                       value={data.length > 0 ? data : "-"}
                       type={header === "Password" ? "password" : "text"}
                       disabled
                />
            </div>
        )
    }

    infoContent = () => {

        return (
            <>
                {this.infoEntry("Name", this.props.User ? this.props.User.username : "")}
                {this.infoEntry("Email", this.props.User ? this.props.User.email : "")}
                {this.infoEntry("Password", this.props.User ? "fdsagsdg" : "")}
                {this.infoEntry("Phone", this.props.User ? this.props.User.phone_number : "")}
            </>
        )
    }

    deleteAccount = confirmed => {

        this.setState({...this.state, deleteAccountDialogOpened: false});
        if (confirmed) {
            this.props.DeleteAccount();
        }
    }

    render() {

        return (
            <div className="vl_profile_info_panel">
                <h1 className="vl_header vl_profile_header">Admin</h1>
                <EditButton VlStyle={{position: "absolute", top: "12%", right: "6.6%"}}
                            Callback={() => {this.props.Navigate("edit")}}/>
                <div className="vl_profile_info_panel_content">
                    {this.infoContent()}
                </div>
                <button className="vl_profile_href vl_profile_delete_href"
                        onClick={() => {this.setState({...this.state, deleteAccountDialogOpened: true})}}>
                    Delete account
                </button>
                {
                    this.state.deleteAccountDialogOpened ?
                        <ConfirmationDialog Text="Are you sure you want to delete this account?"
                                            Callback={this.deleteAccount} /> : ""
                }
            </div>
        )
    }
}

ProfileInfo.propTypes = {
    User: PropTypes.object,
    DeleteAccount: PropTypes.func.isRequired,
    Navigate: PropTypes.func.isRequired
}