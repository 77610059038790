import React from 'react'
import "../../styles/boxes/checkbox.css"
import PropTypes from "prop-types";

export default class CheckBox extends React.Component {

    statusClass = () => {

        return this.props.Checked ? "vl_checkbox_button_active" : "vl_checkbox_button_inactive";
    }

    render() {
        return (
            <div className={"vl_checkbox_container " + (this.props.VlClass || "")}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <button className={"vl_checkbox_button " + this.statusClass()} onClick={this.props.Callback}/>
            </div>
        )
    }
}

CheckBox.propTypes = {
    Callback: PropTypes.func.isRequired,
    Checked: PropTypes.bool.isRequired,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
