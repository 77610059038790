export function isArray (arr) {

    return Array.isArray(arr);
}

export function isNoneEmptyArray (arr) {

    return Array.isArray(arr) && arr.length > 0;
}

export function removeItemOnce(arr, value) {
    let index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}