import React from 'react'
import "../../styles/lists/areas_list.css"
import PropTypes from "prop-types";
import Area from "../panels/devices/area";

export default class AreasList extends React.Component {

    unusedDevicesComponent = () => {

        if ((!this.props.UnusedDevices || this.props.UnusedDevices.length === 0) &&
            (!this.props.Sensors || this.props.Sensors.length === 0)) return <></>

        let unusedDevices = this.props.UnusedDevices.filter(device => !device.deleted);
        let unusedSensors = this.props.Sensors.filter(sensor => (!sensor.area_id || sensor.area_id < 0) && !sensor.deleted);
        if (unusedDevices.length === 0 && unusedSensors.length === 0)  return <></>

        return (
            <Area key={-1}
                  VlStyle={{...this.props.VlAreaStyle, border: "3px dotted lightcoral"}}
                  Area={{name: "Devices without area"}}
                  UnusedDevices={unusedDevices}
                  UnusedSensors={unusedSensors}
                  DeleteCallback={this.props.DeleteCallback}
            />
        )
    }

    updateAreaName = (area, newName) => {

        if (newName.length < 3) {
            return "Name is too short";
        }
        if (this.props.Areas.find(area => area.name === newName)) {
            return "Name is already in use";
        }
        area.name = newName;
        this.props.UpdateArea(area, area.devices.map(device => device.id), area.sensors.map(sensor => sensor.id), true);
        return "";
    }

    componentFromArea = area => {

        if (this.props.Select) {
            return <Area key={area.id}
                         VlStyle={this.props.VlAreaStyle}
                         Area={area}
                         ChangeIntensity={this.props.ChangeIntensity}
                         Check={() => this.props.Select(area.id)}
                         Checked={!!area.selected}
                         CheckGroup={group_id => this.props.Select(area.id, group_id)}/>
        }
        return <Area key={area.id}
                     VlStyle={this.props.VlAreaStyle}
                     Area={area}
                     GroupsIntensityProgress={this.props.GroupsIntensityProgress.filter(g => g.area_id === String(area.id))}
                     DevicesIntensityProgress={this.props.DevicesIntensityProgress}
                     ChangeIntensity={this.props.ChangeIntensity}
                     SelectedDevice={this.props.SelectedDevice}
                     DeleteCallback={this.props.DeleteCallback}
                     AddGroup={this.props.AddGroup}
                     EditGroup={this.props.EditGroup}
                     EditDevice={this.props.EditDevice}
                     UpdateGroup={this.props.UpdateGroup}
                     UpdateAreaName={this.updateAreaName}
                     UpdateDevice={this.props.UpdateDevice}
        />
    }

    render() {

        return (
            <div className={this.props.VlClass ? this.props.VlClass : "vl_areas_list"}
                 style={this.props.VlStyle ? this.props.VlStyle : {}}>
                { this.unusedDevicesComponent() }
                { this.props.Areas ? this.props.Areas.map(a => this.componentFromArea(a)) : "" }
            </div>
        )}
}

AreasList.propTypes = {
    Areas: PropTypes.array,
    UnusedDevices: PropTypes.array,
    SelectedDevice: PropTypes.object,
    Sensors: PropTypes.array,
    GroupsIntensityProgress: PropTypes.array,
    DevicesIntensityProgress: PropTypes.array,

    Select: PropTypes.func,
    ChangeIntensity: PropTypes.func,
    DeleteCallback: PropTypes.func,
    UpdateArea: PropTypes.func,
    AddGroup: PropTypes.func,
    UpdateGroup: PropTypes.func,
    EditGroup: PropTypes.func,
    EditDevice: PropTypes.func,
    UpdateDevice: PropTypes.func,

    VlAreaStyle: PropTypes.object,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
