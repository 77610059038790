import React from 'react'
import "../styles/pages/twoFA.css"
import {connect} from "react-redux";
import {cleanError, verifyCode} from "../../actions/auth";
import {Redirect} from "react-router-dom";
import Banner from "../components/decorations/banner";
import VlButton from "../components/buttons/vl_button";
import CodeInput from "../components/tools/code";
import Shifter from "../components/layout/shifter";
import Lottie from "react-lottie-player";
import loadingJson from "../animations/Loading.json";

class TwoFA extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            code: "",
        }
    }

    codeChanged = code => {

        this.setState({...this.state, code: code}, () => {
            if (this.state.code.length === 6) this.handleVerify();
        });
    }

    handleVerify = () => {

        this.props.verifyCode(this.state.code);
    }

    status = () => {

        if (this.props.authenticating) {
            return (
                <div className="vl_verify_loading_container">
                    <Lottie
                        loop
                        goTo={0}
                        animationData={loadingJson}
                        play={true}
                        style={{
                            width: "400px",
                            height: "200px",
                            position: "absolute",
                            top: "-83px",
                            left: "-125px",

                        }}
                    />
                </div>
            )
        }

        return this.props.error !== null ? <p className="vl_verify_error">{this.props.error}</p> : ""
    }

    verifyForm = () => {

        return (
            <div className="vl_verify_panel">
                <Banner VlWidth="100%" VlHeight="12vh"/>
                <Shifter Vertical Size="50px"/>
                <p className="vl_verify_subtitle">Enter two - factor authentication code</p>
                <CodeInput VlStyle={{marginTop: "8.5vh"}}
                           Count={6}
                           Callback={this.codeChanged}/>
                <VlButton Text="Verify"
                          VlStyle={{
                              position: "absolute",
                              right: "18%",
                              bottom: "13%"}}
                          Callback={this.handleVerify}
                />
                {this.status()}
            </div>
        )
    }

    render() {
        return (this.props.authenticated && this.props.verified) ? (
            <Redirect to={{ pathname: "/"}}/>
        ) : (
            <div className="vl_background">
                {this.verifyForm()}
            </div>
        )
    }
}

const mapStateToLoginProps = state => ({
    error: state.auth.error,
    authenticating: state.auth.authenticating,
    authenticated: state.auth.authenticated,
    verified: state.auth.verified
})

export default connect(mapStateToLoginProps, { verifyCode, cleanError })(TwoFA);
