import {
    GET_PLACES,
    ADD_PLACE,
    REMOVE_PLACE,
    GET_PLAN,
    UPLOAD_PLAN, SET_DRAGGING_DEVICE, PLAN_LOADING
} from "../actions/types";

const initialState = {
    places: [],
    plans: {},
    plansLoading: {},
    draggingDevice: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {

    switch(action.type) {

        case GET_PLACES:
            return { ...state, places: action.places }

        case ADD_PLACE:
            if (state.places.filter(place => place.name === action.place.name).length > 0) {
                let newPlaces = state.places.map(place => {
                    if (place.name === action.place.name) return action.place;
                    return place;
                })
                return { ...state, places: newPlaces }
            } else {
                let newPlaces = [...state.places]
                newPlaces.push(action.place)
                return { ...state, places: newPlaces }
            }

        case REMOVE_PLACE:
            let newPlans = {...state.plans};
            delete newPlans[action.name];
            return {
                ...state,
                places: state.places.filter(place => place.name !== action.name),
                plans: newPlans,
            }

        case UPLOAD_PLAN:
            let uploadedPlans = {...state.plans};
            uploadedPlans[action.name] = action.url;
            return {...state, plans: uploadedPlans};

        case GET_PLAN:
            let plans = {...state.plans};
            plans[action.name] = action.url;
            let plansLoadingWithoutPlan = {...state.plansLoading};
            delete plansLoadingWithoutPlan[action.name];
            return {...state, plans: plans, plansLoading: plansLoadingWithoutPlan};

        case PLAN_LOADING:
            let newPlansLoading = {...state.plansLoading};
            newPlansLoading[action.name] = true;
            return {...state, plansLoading: newPlansLoading};

        case SET_DRAGGING_DEVICE:
            return {...state, draggingDevice: action.device};

        default:
            return state;
    }
}
