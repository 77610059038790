import {CREATE_USER, DELETE_USER, EDIT_USER, GET_USERS} from "../actions/types";

const initialState = {
    users: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {

    switch(action.type) {

        case GET_USERS:
            return { ...state, users: action.users }

        case CREATE_USER:
            return { ...state, users: [...state.users, action.user] }

        case EDIT_USER:
            return { ...state, users: state.users.map((user) => {
                    if (user.username === action.user.username) return action.user;
                    return user
                }) }

        case DELETE_USER:
            return { ...state, users: state.users.filter((user) => {
                    return user.username !== action.username
                }) }

        default:
            return state;
    }
}