import React from 'react'
import "../../styles/menus/navigation_menu.css"
import { Link } from "react-router-dom"
import PropTypes from "prop-types";

export default class NavigationMenu extends React.Component {

    render() {
        return (
            <div
                className={this.props.VlClass ? this.props.VlClass : "vl_navigation_menu"}
                style={this.props.VlStyle ? this.props.VlStyle : {}}>
                <div className="vl_navigation_menu_header_container">
                    <div className="vl_navigation_menu_header"/>
                </div>
                <Link className="vl_navigation_menu_entry" to="/"
                      onMouseUp={this.props.Finished}
                      style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <div className="vl_navigation_menu_map"/>
                    <p>Map</p>
                </Link>
                <Link className="vl_navigation_menu_entry" to="/reports"
                      onMouseUp={this.props.Finished}
                      style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <div className="vl_navigation_menu_reports"/>
                    <p>Reports</p>
                </Link>
                <Link className="vl_navigation_menu_entry" to="/monitoring"
                      onMouseUp={this.props.Finished}
                      style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <div className="vl_navigation_menu_monitoring"/>
                    <p>Monitoring</p>
                </Link>
            </div>
        )}
}

NavigationMenu.propTypes = {
    Finished: PropTypes.func,
    VlClass: PropTypes.string,
    VlStyle: PropTypes.object,
}
